import _ from "lodash";
import { Image as SImage, Icon as SIcon } from "semantic-ui-react";
import { StyledDiv, StyledButton } from "@config/styles/app.styles";

export function Div(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            priText
            {...props}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        />
    );
}
export function Button(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledButton
            {...props}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        />
    );
}
export function Container(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            fullht
            fluid
            rounded
            centered
            layer="1"
            priText
            {...props}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        />
    );
}
export function AbsWrapper(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            absolute
            fullht
            fluid
            priText
            {...props}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
            style={{ top: 0, left: 0, ...props.style }}
        />
    );
}
export function CenteredContent(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            absolute
            priText
            {...props}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
            style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                ...props.style,
            }}
        />
    );
}
export function Overlay(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            absolute
            fullht
            fluid
            priText
            layer={1000}
            {...props}
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
            style={{ top: 0, left: 0, ...props.style }}
        />
    );
}
export function Image(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            {..._.omit(props, "src")}
            nopad
            noOverflow
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        >
            <SImage
                src={
                    props.src
                        ? props.src
                        : "/assets/images/configurable/missing.jpg"
                }
                alt={props.alt}
                style={{
                    ...props.styles,
                    width: props.fluid ? "100%" : "auto",
                }}
                className={props.className}
            />
        </StyledDiv>
    );
}
export function Icon(props) {
    // ========================= Render Function =================================
    const animation = getAnimation(props);
    return (
        <StyledDiv
            {..._.omit(props, "name")}
            noOverflow
            {...(animation
                ? { initial: animation.from, animate: animation.to }
                : {})}
        >
            <SIcon
                name={props.name}
                styles={props.styles}
                className={props.className}
            />
        </StyledDiv>
    );
}

function getAnimation(props) {
    let animObj = {};
    if (props.zoom) animObj.animation = "zoom";
    else if (props.fadeAnim) animObj.animation = "fade";
    else if (props.slideDown) animObj.animation = "slideDown";
    else if (props.slideUp) animObj.animation = "slideUp";
    else if (props.slideLeft) animObj.animation = "slideLeft";
    else if (props.slideRight) animObj.animation = "slideRight";
    else if (props.collapse) animObj.animation = "collapse";
    else if (props.popAnimation) animObj.animation = props.popAnimation;

    if (props.slowAnim) {
        animObj.duration = 0.5;
        animObj.delay = 0.3;
    }
    if (props.delayAnim) {
        animObj.duration = 0.5;
        animObj.delay = 0.6;
    }

    let transitionObj = {
        duration: animObj.duration || 0.25,
        delay: animObj.delay || 0,
        staggerChildren: 0.5,
    };

    if (animObj?.animation) {
        const initial = {
            zoom: {
                scale: 0,
            },
            fade: {
                opacity: 0,
            },
            slideRight: {
                opacity: 0,
                x: -80,
            },
            slideDown: {
                opacity: 0,
                y: -40,
            },
            slideUp: {
                opacity: 0,
                y: 40,
            },
            slideLeft: {
                opacity: 0,
                x: 80,
            },
            collapse: {
                scale: 5,
                opacity: 0,
            },
        };
        const final = {
            zoom: {
                scale: 1,
                transition: transitionObj,
            },
            fade: {
                opacity: 1,
                transition: transitionObj,
            },
            slideRight: {
                x: 0,
                opacity: 1,
                transition: transitionObj,
            },
            slideDown: {
                y: 0,
                opacity: 1,
                transition: transitionObj,
            },
            slideUp: {
                y: 0,
                opacity: 1,
                transition: transitionObj,
            },
            slideLeft: {
                x: 0,
                opacity: 1,
                transition: transitionObj,
            },
            collapse: {
                scale: 1,
                opacity: 1,
                transition: transitionObj,
            },
        };
        return {
            from: initial[animObj.animation],
            to: final[animObj.animation],
            transition: animObj.transition,
        };
    }
}
