import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useWindowFocus from "use-window-focus";

import "@styles/readers.scss";
import { Div } from "@components/Generics.react";
import Accordion from "@components/Accordion.react";
import { Image } from "semantic-ui-react";
import { getTimeSpent } from "@utilities/helpers";

export default function ProductFAQ(props) {
    const { deck, attempt, recordAttempt } = props;
    const { t } = useTranslation("common");

    //---------------------------------------------------------------------------
    // Time tracking
    //---------------------------------------------------------------------------
    const [timeStart, setTimeStart] = useState(null);
    const [timeSpent, setTimeSpent] = useState(0);
    const windowFocused = useWindowFocus();
    useEffect(() => {
        if (windowFocused) {
            setTimeStart(Date.now());
        } else {
            setTimeSpent(timeSpent + getTimeSpent(timeStart));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowFocused]);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        const thisAttempt = attempt;
        return () => {
            recordAttempt(thisAttempt, {
                deckId: deck._id,
                viewedPercentage: 100,
                completion: true,
                timeSpent: timeSpent + getTimeSpent(timeStart),
                pointsDelta: 0,
                date: Date.now(),
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let panels = deck?.content?.questions.map((item) => {
        return {
            title: <Div small>{`${t("deck.faq.qAbbr")} ${item.title}`}</Div>,
            content: <Div>{item.description}</Div>,
        };
    });

    return (
        <Div
            fluid
            flex
            maxWd="480px"
            fullht
            priText
            autoOverflowY
            className="product-faq-reader"
        >
            <Image rounded fluid src={deck?.content?.image} />
            <Div
                midnight
                centered
                big
                bold
                topRounded
                relative
                className="product-name"
            >
                {deck?.content?.name}
            </Div>
            <Div fluid relative layer={2}>
                <Accordion panels={panels}></Accordion>
            </Div>
            
        </Div>
    );
}
