import React from "react";
import _ from "lodash";
import { Div, Image } from "@components/Generics.react";
import Carousel from "@components/Carousel.react";
import { clientAssetsPath } from "@api/apiV6";

export default function Diptych(props) {
    const { diptych } = props.data;

    return (
        <Div fluid zoom>
            <Carousel slidesToShow={1} enableButtons>
                {_.map(diptych, (card, idx) => {
                    return (
                        <Div
                            key={`list-item-${idx}`}
                            slightShadow
                            left-aligned
                            rounded
                            noOverflow
                        >
                            <Image
                                fluid
                                src={`${clientAssetsPath}/uploads/qdf/slides/${card.image.id}${card.image.extension}`}
                            />
                            <Div superpad ash big>
                                {card.text}
                            </Div>
                        </Div>
                    );
                })}
            </Carousel>
        </Div>
    );
}