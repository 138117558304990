import React, { useState } from "react";
import _ from "lodash";
import { Div, Image, Button } from "@components/Generics.react";
import "@styles/readers.scss";
import { clientAssetsPath } from "@api/apiV6";

export default function Grid(props) {
    const { grid } = props.data;
    const [showImage, setShowImage] = useState(null);
    return (
        <Div fluid>
            {showImage == null && (
                <Div fluid zoom className="grid-container">
                    {_.map(grid, (card, idx) => {
                        return (
                            <Image
                                fluid
                                clickable
                                key={`list-item-${idx}`}
                                className="grid-item"
                                src={`${clientAssetsPath}/uploads/qdf/slides/${card.image.id}${card.image.extension}`}
                                onClick={() => setShowImage(idx)}
                            />
                        );
                    })}
                </Div>
            )}
            {showImage != null && (
                <Div fluid zoom>
                    <Image
                        fluid
                        src={`${clientAssetsPath}/uploads/qdf/slides/${grid[showImage].image.id}${grid[showImage].image.extension}`}
                    />
                    <Div superpad ash big>
                        {grid[showImage].text}
                    </Div>
                    <Div fit-content centered padTop>
                        <Button
                            content={"Close"}
                            primary
                            onClick={() => setShowImage(null)}
                        />
                    </Div>
                </Div>
            )}
        </Div>
    );
}