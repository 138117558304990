import React, { useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { clientAssetsPath } from "@api/apiV6";
import { Div, Image } from "@components/Generics.react";
import ResponseOverlay from "@components/ResponseOverlay.react";

export default function PickPicture(props) {
    const { data, slideSeq, trackData, deckId, pageNumber, setPageNumber } =
        props;
    const [correct, setCorrect] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");

    function trackInteraction(selected) {
        const isCorrect = data.imageOpts[selected]["correct"] === "checked";
        setCorrect(isCorrect);
        if (data.feedback != null && data.feedback.length > 0 && data.purpose !== "Provide Insight")
            setShowOverlay(true);
        else setPageNumber(pageNumber + 1);

        if (trackData) {
            trackData({
                correct: isCorrect,
                response: selected,
            });
        }
        dispatch(
            trackQuestion({
                deckId: deckId,
                slideId: slideSeq,
                userId: userId,
                response: selected,
            })
        );
    }

    function closeOverlay() {
        setPageNumber(pageNumber + 1);
    }

    const getOptions = _.map(data.imageOpts, (card, idx) => {
        return (
            <Image
                fluid
                clickable
                key={`list-item-${idx}`}
                className="grid-item"
                src={`${clientAssetsPath}/uploads/qdf/slides/${card.image.id}${card.image.extension}`}
                onClick={() => trackInteraction(idx)}
            />
        );
    });

    // ========================= Render Function =================================
    return (
        <Div fluid fullht zoom relative superpad>
            <Div big gutter>
                {data.question}
            </Div>
            <Div small italics gutter>
                {t("deck.qdf.singleSelect")}
            </Div>
            <Div fluid zoom className="grid-container">
                {getOptions}
            </Div>
            {showOverlay && (
                <ResponseOverlay
                    feedback={data.feedback}
                    correct={correct}
                    closeResponse={closeOverlay}
                />
            )}
        </Div>
    );
}