import React from "react";
import { Div } from "@components/Generics.react";
export default function Blurb(props) {
    const { blurb } = props.data;
    return (
        <Div fluid flex superpad big>
            {blurb}
        </Div>
    );
}
