const GameSchema = [
    {
        name: "Quiz Spin",
        folder: "carnival_slots",
        restricted: true,
        premium: false,
        description:
            "A Slot Machine game where you answer questions to win spins and earn money. ",
        instructions: [],
    },
    {
        name: "Balloon Burst",
        folder: "balloon_burst",
        restricted: false,
        premium: false,
        description:
            "Pop those balloons to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "balloon_burst1.jpg",
                text: "Collect stars by popping the balloons that are flying up. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "balloon_burst2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time balloon to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "balloon_burst3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Bottle Breaker",
        folder: "bottle_breaker",
        restricted: false,
        premium: false,
        description:
            "Shoot down bottles to collect points and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "bottle_breaker1.jpg",
                text: "Collect stars by shooting down bottles on the shelves. Different balloons give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "bottle_breaker2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time bottle to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "bottle_breaker3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "CorpoLadder",
        folder: "corpoladder",
        restricted: false,
        premium: false,
        description:
            "Climb the corporate ladder, collect that money and answer questions to get a boost.",
        instructions: [
            {
                header: "Climb Upwards and Onwards",
                image: "corpoladder1.jpg",
                text: "Click or tap to jump between the two buildings and collect the money. Crash into a protrusion and it is game over!",
            },
            {
                header: "Get a Promotion",
                image: "corpoladder2.jpg",
                text: "Jump onto a question mark to get a question. Answer it correctly to get immunity and a speed boost for a short while.",
            },
            {
                header: "Levelling Up",
                image: "corpoladder3.jpg",
                text: "As you collect points, you will level up. With each level you go up, your speed increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "FieldIt",
        folder: "fieldit",
        restricted: true,
        premium: false,
        description:
            "A Cricket Game where you answer questions to take those wickets and win match after match.",
        instructions: [],
    },
    {
        name: "Jet Setter",
        folder: "jet_setter",
        restricted: true,
        premium: false,
        description:
            "Fly through space, dodge asteroids, collect money and powerups and answer questions to gain life.",
        instructions: [],
    },
    {
        name: "Fruit Graffiti",
        folder: "fruit_graffiti",
        restricted: true,
        premium: false,
        description:
            "Slice those fruits, avoid the bombs and if you can't, answer questions to save your lives.",
        instructions: [],
    },
    {
        name: "Taxi Driver",
        folder: "taxi_driver",
        restricted: false,
        premium: false,
        description:
            "Drive down the road, avoid traffic and answer questions to top up your fuel regularly.",
        instructions: [
            {
                header: "Drive through Traffic",
                image: "taxi_driver1.jpg",
                text: "Click or tap to change lanes as you drive. Crash into a car and it is game over!",
            },
            {
                header: "Watch the Fuel",
                image: "taxi_driver2.jpg",
                text: "When the fuel reaches zero, your game is over. Stop in the left lane next to a gas station to get a question. Answer it correctly to top up your fuel.",
            },
            {
                header: "Levelling Up",
                image: "taxi_driver3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the traffic on the road increases and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "StarFall",
        folder: "starfall",
        restricted: false,
        premium: false,
        description:
            "Connect the stars to destroy them, and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Connect the Stars",
                image: "starfall1.jpg",
                text: "Connect the stars to destroy them. The longer the chain, the more points you get.",
            },
            {
                header: "Watch the Timer",
                image: "starfall2.jpg",
                text: "When the timer reaches zero, your game is over. Connect the question marks to get a question. Answer it correctly to get more time. The longer the chain, the more time you get.",
            },
            {
                header: "Levelling Up",
                image: "starfall3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the stars fall a little slower and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Freedom Rocks",
        folder: "freedom_rocks",
        restricted: true,
        premium: false,
        description:
            "Connect the rocks to build chains, and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Connect the Rocks",
                image: "freedom_rocks1.jpg",
                text: "Connect similar symbols to build chains. The longer the chain, the more points you get.",
            },
            {
                header: "Watch the Timer",
                image: "freedom_rocks2.jpg",
                text: "When the timer reaches zero, your game is over. Connect the question marks to get a question. Answer it correctly to get more time. The longer the chain, the more time you get.",
            },
            {
                header: "Levelling Up",
                image: "freedom_rocks3.jpg",
                text: "As you collect points, you will level up. With each level you go up, the rocks fall a little slower and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Ghost Dusters",
        folder: "ghost_dusters",
        restricted: true,
        premium: false,
        description:
            "Capture ghosts to exorcise the haunted house and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Capture Ghosts",
                image: "ghost_dusters1.jpg",
                text: "Capture ghosts by tapping on them, before your courage runs out. Your courage goes down faster if more ghosts are visible",
            },
            {
                header: "Control your Fear",
                image: "ghost_dusters2.jpg",
                text: "When your courage reaches zero, your game is over. Click the Question Flask to get a question. Answer it correctly and your courage gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "ghost_dusters3.jpg",
                text: "As you capture ghosts, you will level up. With each level you go up, the courage drops a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Fireworks",
        folder: "fireworks",
        restricted: true,
        premium: false,
        description:
            "Burst those fireworks to collect stars and answer questions to gain more time to do it in.",
        instructions: [
            {
                header: "Collect Stars",
                image: "fireworks1.jpg",
                text: "Collect stars by bursting the rockets that are flying up. Different rockets give different amounts of stars.",
            },
            {
                header: "Watch the Timer",
                image: "fireworks2.jpg",
                text: "When the timer reaches zero, your game is over. Pop the Time Lantern to get a question. Answer it correctly and your timer gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "fireworks3.jpg",
                text: "As you collect stars, you will level up. With each level you go up, the timer gets a little faster and the game gets a little tougher...",
            },
        ],
    },
    {
        name: "Energy Saver",
        folder: "energy_saver",
        restricted: true,
        premium: false,
        description:
            "Save Energy by tapping on windows who's light in on and answer questions to gain more time to do it in. ",
        instructions: [
            {
                header: "Save Energy",
                image: "energy_saver1.jpg",
                text: "Turn off the lights by tapping on them to save light energy, before your battery runs out. Your battery goes down faster if  more light energy are visible.",
            },
            {
                header: "Watch the Battery",
                image: "energy_saver2.jpg",
                text: "When your battery reaches zero, you game is over. Click the question icon to get quiz. Answer it correctly and your battery gets topped up.",
            },
            {
                header: "Levelling Up",
                image: "energy_saver3.jpg",
                text: "As you save light energy,  you will level up. With each level you go up, the battery drops a little faster and the game gets a little tougher.",
            },
        ],
    },
];

export { GameSchema };
