import React, { useEffect } from "react";
import _ from "lodash";

import "@styles/readers.scss";
import { Div, Button } from "@components/Generics.react";
import Accordion from "@components/Accordion.react";
import * as DOMPurify from "dompurify";

export default function FAQReader(props) {
    const { deck, recordAttempt } = props;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let panels =
        typeof deck?.content === "string" || deck?.content instanceof String
            ? [
                  {
                      title: <Div small>Answer:</Div>,
                      content: (
                          <div
                              dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(deck?.content),
                              }}
                          />
                      ),
                  },
              ]
            : _.values(_.omit(deck, "currentState")).map((item) => {
                  return {
                      title: <Div small>{`${item.title}`}</Div>,
                      content: (
                          <Div padded>
                              <div
                                  dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                          item.description
                                      ),
                                  }}
                              />
                              <br />
                              {item.link != null && item.link !== "" && (
                                  <a href={item.link}>
                                      <Button
                                          primary
                                          fluid
                                          content={item.linkName}
                                      />
                                  </a>
                              )}
                          </Div>
                      ),
                  };
              });

    return (
        <Div
            fluid
            fullht
            noOverflow
            rounded
            left-align
            priText
            slightShadow
            className="faq-reader"
        >
            <Accordion panels={panels}></Accordion>
        </Div>
    );
}
