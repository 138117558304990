// Import from NPM
// -------------------------------------
import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Dropdown, Image } from "semantic-ui-react";
import * as DOMPurify from "dompurify";
import _ from "lodash";

// Import from Config
// -------------------------------------
import { LayoutContext } from "@layouts/MasterLayout.react";
import { snaked, text_truncate } from "@utilities/helpers";

// Import Components
// -------------------------------------
import { Div, Icon } from "@components/Generics.react";
import ShareWidget from "@components/ShareWidget.react";
import Deck from "@player/decks/Deck.react";
import CommentsSection from "@components/CommentsSection.react";

// -------------------------------------
// Import APIs
// -------------------------------------
import {
    useSendArticlesLikesMutation,
    useSendArticlesDislikesMutation,
} from "@api/apiV6";

export default function ArticleViewer(props) {
    const { articles, article, readerType, inPlayer, onAddComment } = props;
    const user = useSelector((state) => state.auth.user);
    const { fullScreen } = useContext(LayoutContext);
    const scrollRef = useRef(null);
    const navigate = useNavigate();
    const { t } = useTranslation("common");

    const isPortrait = window.innerHeight > window.innerWidth;

    function handleScroll(e) {
        const { scrollTop } = e.target;
        if (
            inPlayer &&
            scrollTop > scrollRef.current.offsetTop - 30 &&
            scrollTop < scrollRef.current.offsetTop + 60
        )
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const articleHeader = (
        <Div fluid clearfix gutter>
            <Icon headline clickable txtPrimary name="bookmark" float-right />
            <Div wd={"calc(100% - 64px)"} float-left>
                <Div huge altText>
                    {article.name}
                </Div>
                <Div gapTop clickable>
                    {_.map(article?.tags, (tag, idx) => {
                        return (
                            <Dropdown
                                key={`article-tag-${article.id}-${idx}`}
                                text={
                                    !_.startsWith(
                                        t(`tags.${snaked(tag.name)}`),
                                        "tags"
                                    )
                                        ? t(`tags.${snaked(tag.name)}`) + " "
                                        : tag.name + " "
                                }
                                icon="chevron down"
                                floating
                                button
                                className="icon"
                                style={{
                                    fontSize: "0.7em",
                                    marginBottom: "5px",
                                }}
                            >
                                <Dropdown.Menu>
                                    {_.map(
                                        articles.taggedList[
                                            snaked(tag.name)
                                        ].articles.slice(0, 7),
                                        (thisArticle, idy) => {
                                            return (
                                                <Dropdown.Item
                                                    key={`subarticles-${idx}-${idy}`}
                                                    onClick={() =>
                                                        navigate(
                                                            `/content/${thisArticle.category}/${thisArticle.identifier}`
                                                        )
                                                    }
                                                    title={thisArticle.name}
                                                >
                                                    {text_truncate(
                                                        thisArticle.name,
                                                        25
                                                    )}
                                                </Dropdown.Item>
                                            );
                                        }
                                    )}
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        key={`subarticles-${idx}-all`}
                                        onClick={() =>
                                            navigate(
                                                `/tags/${snaked(tag.name)}`
                                            )
                                        }
                                    >
                                        VIEW ALL
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        );
                    })}
                </Div>
                <Div>
                    {_.map(article.breadcrumbs, (crumb, idx) => {
                        return (
                            <Div inline key={`breadcrumbs-${idx}`}>
                                {idx !== 0 && " > "}
                                <NavLink to={`/tags/${snaked(crumb)}`}>
                                    {crumb}
                                </NavLink>
                            </Div>
                        );
                    })}
                </Div>
            </Div>

            <Div clear gapTop small>
                <Div gapTop gutter fit-content float-left txtHalf>
                    By {article.owner.first_name} {article.owner.last_name}
                </Div>
                <Div gapTop gutter fit-content float-right txtHalf>
                    Published:{" "}
                    {new Date(article.createdAt).toLocaleDateString()}
                </Div>
            </Div>
            {article.image && (
                <Div
                    clear
                    fluid
                    rounded
                    noOverflow
                    // className="article-image-container"
                >
                    <Image src={article.image} fluid />
                </Div>
            )}
        </Div>
    );
    const deckView = (
        <div ref={scrollRef}>
            <Deck
                deckHash={{
                    id: article.decks[0],
                    readerType: readerType,
                    inPlayer: inPlayer,
                }}
            />
        </div>
    );
    const articleSummary = (
        <Div txtHalf padTop clearfix trench>
            <Div big bold gutter>
                {t("article.summary")}
            </Div>
            <Div
                gutter
                normal
                small
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(article.summary),
                }}
            />
        </Div>
    );
    const isLiked = article.likes.includes(user._id);
    const disLiked = article.dislikes.includes(user._id);

    const articleFooter = (
        <Div padTop clearfix trench txtGrey>
            <Div float={isPortrait ? "right" : "left"}>
                <br />
                <Div inline txtCharcoal>
                    {article.currentState?.views} Views
                </Div>
                <Div inline padLeft txtMidnight={isLiked}>
                    <Icon
                        inline
                        clickable
                        nudgeLeft
                        name={`thumbs up${isLiked ? "" : " outline"}`}
                        onClick={() => {
                            sendArticlesLikesMutation({ id: article.id });
                        }}
                    />
                    {article.currentState?.likes}
                </Div>
                <Div inline txtMidnight={disLiked}>
                    <Icon
                        inline
                        clickable
                        nudgeLeft
                        name={`thumbs down${disLiked ? "" : " outline"}`}
                        onClick={() => {
                            sendArticlesDislikesMutation({ id: article.id });
                        }}
                    />
                    {article.currentState?.dislikes}
                </Div>
                <Div inline padLeft txtCharcoal>
                    <Icon inline nudgeLeft name="comments outline" />
                    {article.currentState?.comments}
                </Div>
            </Div>
            {article.shareable && (
                <Div float-right padTop>
                    <ShareWidget
                        url={window.location.href}
                        subject={article.name}
                    />
                </Div>
            )}
        </Div>
    );

    const articleComments = (
        <CommentsSection
            comments={article.comments}
            onAddComment={onAddComment}
        />
    );

    const [sendArticlesLikesMutation] = useSendArticlesLikesMutation();
    const [sendArticlesDislikesMutation] = useSendArticlesDislikesMutation();

    // ========================= Render Function =================================

    return (
        <Div
            padded
            fullht
            autoOverflowY
            className="article-viewer"
            onScroll={handleScroll}
        >
            {!fullScreen && articleHeader}
            {deckView}
            {!fullScreen &&
                article.summary &&
                article.summary !== "" &&
                articleSummary}
            {!fullScreen && articleFooter}
            <hr />
            {!fullScreen && articleComments}
        </Div>
    );
}
