// Import from NPM
// -------------------------------------
import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Grid } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { primaryStyle } from "@utilities/helpers";
import { GameSchema } from "@schemas/gameSchema.react";

// Import Components
// -------------------------------------
import { Div, Image, Button } from "@components/Generics.react";

export default function GameLaunch(props) {
    const { deck, showHelp, showLeaderboard, startGame } = props;
    const { t } = useTranslation("common");
    const gameTemplate = _.find(GameSchema, { folder: deck.quo_game });
    return (
        <Div
            fluid
            fullht
            white
            maxWd="400px"
            zoom
            centered
            superpad
            style={{
                background: `url(
                    /assets/images/games/backgrounds/${deck.quo_game}.jpg
                )`,
                backgroundSize: "cover",
            }}
        >
            <Div
                fit-content
                large
                uppercase
                centered
                relaxed
                circled
                clickable
                gutter
                style={primaryStyle(deck?.colors)}
            >
                {deck.name}
            </Div>
            <Div midnight superpad topRounded center-align opacity={0.95}>
                {gameTemplate.description}
            </Div>
            <Div gapTop>
                <Div superpad charcoal bottomRounded opacity={0.95}>
                    <Div style={{padding:"0 30px"}}>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Div
                                        onClick={showHelp}
                                        centered
                                        clickable
                                        center-align
                                    >
                                        <Image src="/assets/images/configurable/help.png" />
                                        <Div uppercase centered padTop>
                                            {t("deck.game.help")}
                                        </Div>
                                    </Div>
                                </Grid.Column>
                                <Grid.Column>
                                    <Div
                                        onClick={showLeaderboard}
                                        centered
                                        clickable
                                    >
                                        <Image src="/assets/images/configurable/showlb.png" />
                                        <Div
                                            uppercase
                                            centered
                                            padTop
                                            center-align
                                        >
                                            {t("deck.game.leaderboard")}
                                        </Div>
                                    </Div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Div>
                </Div>
                <Div basepad>
                    <Div
                        onClick={startGame}
                        basepad
                        centered
                        clickable
                        dropShadow
                        rounded
                    >
                        <Button primary fluid content="START" />
                    </Div>
                </Div>
            </Div>
        </Div>
    );
}
