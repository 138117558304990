import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as DOMPurify from "dompurify";
import "@styles/readers.scss";

import { Div, Button } from "@components/Generics.react";

export default function EventReader(props) {
    const { deck, currentState, recordAttempt } = props;
    const { t } = useTranslation("common");

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    const event_date = new Date(deck.date); // Replace this with your event_date
    const localeDate = event_date.toLocaleDateString();
    const localeTime = event_date.toLocaleTimeString();

    // ========================= Render Function =================================
    return (
        <Div white rounded fluid fullht centered center-align className="event">
            <Div
                className="event-content-container"
                absolute
                rounded
                fluid
                flex
                left-aligned
                center-aligned
                column
                fullht
                superpad
            >
                <Div
                    txtColor="#333333"
                    gutter
                    big
                    altText
                    uppercase
                    headline
                    padTop
                >
                    {deck.name}
                </Div>
                <Div
                    gutter
                    normal
                    small
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(deck.description),
                    }}
                />
                <Div gutter bold big>
                    {localeDate} at {localeTime}
                </Div>
                <a href={deck.link}>
                    <Button primary content={t("deck.event.buttonText")} />
                </a>
            </Div>
        </Div>
    );
}
