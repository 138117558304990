import React, { useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { trackQuestion } from "@reducers/trackerSlice";
import { Div, Button } from "@components/Generics.react";
import ResponseOverlay from "@components/ResponseOverlay.react";

export default function SingleSelect(props) {
    const { data, slideSeq, trackData, deckId, pageNumber, setPageNumber } =
        props;
    const [correct, setCorrect] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const userId = useSelector((state) => state.auth.user._id);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");

    function trackInteraction(selected) {
        const isCorrect = data.options[selected]["correct"] === "checked";
        setCorrect(isCorrect);
        if (data.feedback!=null && data.feedback.length > 0 && data.purpose !== "Provide Insight")
            setShowOverlay(true);
        else setPageNumber(pageNumber + 1);

        if (trackData) {
            trackData({
                correct: isCorrect,
                response: selected,
            });
        }
        dispatch(
            trackQuestion({
                deckId: deckId,
                slideId: slideSeq,
                userId: userId,
                response: selected,
            })
        );
    }

    function closeOverlay() {
        setPageNumber(pageNumber + 1);
    }

    const getOptions = _.map(data.options, (option, index) => {
        return (
            <Button
                content={option.text}
                fluid
                primary
                key={"button-" + index}
                onClick={() => trackInteraction(index)}
                style={{ marginBottom: "3px", textAlign: "left" }}
            />
        );
    });

    // ========================= Render Function =================================
    return (
        <Div fluid fullht zoom superpad relative>
            <Div big={data.question.length < 120} gutter>
                {data.question}
            </Div>
            <Div small italics>
                {t("deck.qdf.singleSelect")}
            </Div>
            <Div padTop>{getOptions}</Div>
            {showOverlay && (
                <ResponseOverlay
                    feedback={data.feedback}
                    correct={correct}
                    closeResponse={closeOverlay}
                />
            )}
        </Div>
    );
}
