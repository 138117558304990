// Import from NPM
// -------------------------------------
import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Loader } from "semantic-ui-react";
import _ from "lodash";

// Import from Config
// -------------------------------------
import {
    useGetArticleQuery,
    useSendArticlesCommentsMutation,
    useGetSessionsQuery,
} from "@api/apiV6";
import { startArticleAttempt, startSession } from "@reducers/attemptSlice";
import { deckFormats } from "@schemas/deckFormats";
import { snaked } from "@utilities/helpers";

// Import Components
// -------------------------------------
import { Div } from "@components/Generics.react";
import MasterLayout from "@layouts/MasterLayout.react";
import ArticleCard from "@components/ArticleCard.react";
import ArticleViewer from "./ArticleViewer.react";
import RecommendedArticles from "@components/RecommendedArticles.react";

export default function Article(props) {
    const { articleIdentifier, readerType } = useParams();
    const attempt = useSelector((state) => state.attempt);
    const articles = useSelector((state) => state.articles);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const [sendArticlesComments] = useSendArticlesCommentsMutation();

    const articleId = articles.list[articleIdentifier]?._id;

    const {
        data: article,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetArticleQuery(
        {
            id: articleId,
            user_id: user._id,
            readerType: deckFormats[readerType].readerType,
        },
        {
            enabled: false,
        }
    );
    const { data: sessions } = useGetSessionsQuery(user._id);

    useEffect(() => {
        if (isSuccess) {
            if (attempt.article !== articleId) {
                dispatch(
                    startArticleAttempt({
                        id: articleId,
                        deckId: article.decks[0],
                        currentState: article.currentState,
                        readerType: deckFormats[readerType].readerType,
                        history: article.history,
                    })
                );
                dispatch(startSession(sessions));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, attempt.article]);

    const handleAddComment = async (commentText) => {
        try {
            await sendArticlesComments({
                id: article.id,
                comment: commentText,
            });
        } catch (error) {}
    };
    // ------------------------------------------
    // Select Main Panel Component
    // ------------------------------------------
    function mainPanel() {
        return (
            isSuccess && (
                <ArticleViewer
                    articles={articles}
                    article={article}
                    readerType={deckFormats[readerType].readerType}
                    inPlayer={deckFormats[readerType].inPlayer}
                    onAddComment={handleAddComment}
                />
            )
        );
    }

    const associatedArticles = useMemo(() => {
        if (article?.tags) {
            const articleTags = _.map(article.tags, (tag) => snaked(tag.name));
            return _.filter(
                _.uniqBy(
                    _.flatten(
                        _.map(
                            articleTags,
                            (tag) => articles.taggedList[tag].articles
                        )
                    ),
                    "_id"
                ),
                (art) => art._id !== article._id
            );
        }
    }, [article, articles]);

    const rightPanelName = t("article.rightHeader");
    const rightContent = isSuccess && (
        <Div nopad fullht>
            <Div big padTop gutter bold uppercase>
                {t("article.rightHeader")}
            </Div>
            <Div autoOverflow padBottom ht="calc(100% - 45px)">
                {_.map(article.suggestReading, (article) => {
                    return (
                        <ArticleCard
                            key={`article-right-suggested-${article._id}`}
                            article={article}
                            feedStyle
                        />
                    );
                })}
                {_.map(associatedArticles, (article) => {
                    return (
                        <ArticleCard
                            key={`article-right-associated-${article._id}`}
                            article={article}
                            feedStyle
                        />
                    );
                })}
            </Div>
        </Div>
    );

    // ========================= Render Function =================================

    if (isLoading) return <Loader active />;
    if (isError) return <div>{error.error}</div>;
    if (isSuccess) {
        return (
            <MasterLayout
                title={article.name}
                mainPanel={mainPanel()}
                rightPanelName={rightPanelName}
                rightPanel={
                    (article.suggestReading?.length || 0) +
                        (associatedArticles?.length || 0) ===
                    0 ? (
                        <RecommendedArticles />
                    ) : (
                        rightContent
                    )
                }
            />
        );
    } else {
        return <Loader active />;
    }
}
