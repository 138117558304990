import "@styles/components.scss";
import "@styles/icomoon.css";

import { useState } from "react";
import { Div, Icon } from "@components/Generics.react";

export default function Accordion(props) {
    const { panels } = props;
    const [activeIndex, setActiveIndex] = useState(props.activeIndex || 0);

    // ========================= Render Function =================================
    return (
        <Div className="accordion">
            {panels.map((panel, index) => {
                const isActive = index === activeIndex;
                return (
                    <Div
                        rounded
                        basepad
                        relative
                        vapor
                        className="panel-container"
                        key={`accordion-panel-${index}`}
                    >
                        <Div
                            gutter
                            white
                            rounded
                            basepad
                            priText
                            left-aligned
                            clickable
                            onClick={() =>
                                setActiveIndex(isActive ? -1 : index)
                            }
                        >
                            <Div clearfix gapBottom>
                                <Div
                                    wd="calc(100% - 60px)"
                                    bold
                                    compact
                                    float-left
                                >
                                    {panel.title}
                                </Div>
                                <Icon
                                    nopad
                                    big
                                    float-right
                                    name={
                                        isActive
                                            ? "minus circle"
                                            : "plus circle"
                                    }
                                    txtCharcoal
                                />
                            </Div>
                            {isActive && (
                                <Div clear>
                                    <Div gutter wd="40%" primary fitted />
                                    <Div priText small>
                                        {panel.content}
                                    </Div>
                                </Div>
                            )}
                        </Div>
                    </Div>
                );
            })}
        </Div>
    );
}
