import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams, useParams } from "react-router-dom";

import _ from "lodash";
import { Div } from "@components/Generics.react";

import MasterLayout from "@layouts/MasterLayout.react";
import RecommendedArticles from "@components/RecommendedArticles.react";
import ArticleCarousel from "@components/ArticleCarousel.react";
import ArticleCard from "@components/ArticleCard.react";

export default function ArticleListing(props) {
    const { name } = useParams();
    const taggedList = useSelector((state) => state.articles.taggedList);
    const { t } = useTranslation("common");
    const sortedArticles = useMemo(
        () => _.orderBy(taggedList[name]?.articles, ["priority"], ["desc"]),
        [taggedList, name]
    );
    const isPortrait = window.innerHeight > window.innerWidth;
    const categoryGroup = _.groupBy(sortedArticles, "category");
    const categories = _.keys(categoryGroup);

    // ------------------------------------------
    // Search Results
    // ------------------------------------------
    const [searchParams] = useSearchParams();
    let selectedWord = searchParams.get("text")?.toLowerCase();
    const searchResults = useMemo(() => {
        if (
            categories.length <= 1 &&
            selectedWord != null &&
            selectedWord !== ""
        ) {
            return _.filter(categoryGroup[categories[0]], (article) => {
                const tagNames = _.map(article.tags, (tag) =>
                    tag.name.toLowerCase()
                );
                return (
                    article.name.toLowerCase().includes(selectedWord) ||
                    tagNames.includes(selectedWord) ||
                    article.category.includes(selectedWord)
                );
            });
        } else return categoryGroup[categories[0]];
    }, [selectedWord, categoryGroup, categories]);

    // ========================= Render Function =================================
    return (
        <MasterLayout
            title={"Tag Listing"}
            mainPanel={
                <Div padded fluid>
                    <Div large padTop gutter uppercase>
                        {name.replace(/_/g, " ")}{" "}
                        {selectedWord != null &&
                            selectedWord !== "" &&
                            `> ${selectedWord}`}
                    </Div>
                    {categories.length > 1
                        ? _.map(categories, (category, carouselIndex) => {
                              return (
                                  <Div
                                      key={`carousel-index-${carouselIndex}`}
                                      compact
                                      padTop
                                      rounded
                                      smoke={!isPortrait}
                                      gutter={!isPortrait}
                                      small
                                  >
                                      <ArticleCarousel
                                          header={category}
                                          libLink={`/tags/${category}`}
                                          articles={categoryGroup[category]}
                                      />
                                  </Div>
                              );
                          })
                        : _.map(searchResults, (article, articleIndex) => {
                              return (
                                  <Div
                                      float-left
                                      wd={
                                          window.innerWidth > 1280
                                              ? "33%"
                                              : "50%"
                                      }
                                      gapBottom
                                      key={`article-${articleIndex}`}
                                  >
                                      <ArticleCard
                                          article={article}
                                          feedStyle
                                      />
                                  </Div>
                              );
                          })}
                </Div>
            }
            rightPanelName={t(`rightPanel.recommended`)}
            rightPanel={<RecommendedArticles />}
        />
    );
}
