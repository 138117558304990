import React from "react";
import _ from "lodash";
import { clientAssetsPath } from "@api/apiV6";
import { Div, Image } from "@components/Generics.react";
import Carousel from "@components/Carousel.react";

export default function Triptych(props) {
    const { triptych } = props.data;

    return (
        <Div fluid zoom>
            <Carousel slidesToShow={1} enableButtons>
                {_.map(triptych, (card, idx) => {
                    return (
                        <Div
                            key={`list-item-${idx}`}
                            slightShadow
                            left-aligned
                            rounded
                            noOverflow
                        >
                            <Image fluid src={`${clientAssetsPath}/uploads/qdf/slides/${card.image.id}${card.image.extension}`} />
                            <Div superpad ash big>
                                {card.text}
                            </Div>
                        </Div>
                    );
                })}
            </Carousel>
        </Div>
    );
}
