// Import from NPM
// -------------------------------------
import React, { useState, createContext, useEffect } from "react";
import { Helmet } from "react-helmet";

// Import from Config
// -------------------------------------
import "@styles/layouts.scss";
import { Div } from "@components/Generics.react";
import gcss from "@styles/variables.module.scss";
import { parsePx } from "@utilities/helpers";

import Navbar from "@components/Navbar.react";
import IsolatedPortrait from "./panels/IsolatedPortrait.react";
import IsolatedLandscape from "./panels/IsolatedLandscape.react";
import CommonPanel from "./panels/CommonPanel.react";
import { settings } from "@config/settings/app.settings";

export const LayoutContext = createContext({});

export default function MasterLayout(props) {
    const {
        title,
        course,
        mainPanel,
        rightPanel,
        rightPanelName,
        navLogo,
        navName,
        closeDeck,
        rootPage = false,
        isolated = false,
        hidePanelToggle = false,
        bottomBuffer = true,
    } = props;
    const [fullScreen, setFullScreen] = useState(false);
    const isPortrait = window.innerHeight > window.innerWidth;

    // ------------------------------------------
    // Page Sizing
    // ------------------------------------------
    const pgHt = Math.min(
        window.innerHeight - parsePx(gcss.navbarHt),
        parsePx(gcss.maxHt) - parsePx(gcss.navbarHt)
    );

    useEffect(() => {
        if (isPortrait) {
            if (fullScreen) {
                const targetDiv = document.querySelector(".fullscreen-target");
                targetDiv.requestFullscreen();
                window.screen.orientation.lock("landscape-primary");
            } else {
                if (document.fullscreenElement) document.exitFullscreen();
            }
        }
    }, [fullScreen, isPortrait]);

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            className={`${isolated ? "isolated" : ""} master-container`}
            autoOverflowY={isPortrait}
        >
            <Helmet>
                <title>{title}</title>
                <meta name="theme-color" content={settings.colors.main} />
            </Helmet>
            <Navbar
                logo={navLogo}
                name={navName}
                rootPage={rootPage}
                isolated={isolated}
                closeDeck={closeDeck}
            />
            <LayoutContext.Provider
                value={{ fullScreen, setFullScreen, height: pgHt }}
            >
                {isolated ? (
                    isPortrait ? (
                        <IsolatedPortrait
                            course={course}
                            mainPanel={mainPanel}
                            rightPanel={rightPanel}
                            hidePanelToggle={hidePanelToggle}
                        />
                    ) : (
                        <IsolatedLandscape
                            course={course}
                            mainPanel={mainPanel}
                            rightPanel={rightPanel}
                            fullScreen={fullScreen}
                        />
                    )
                ) : (
                    <CommonPanel
                        mainPanel={mainPanel}
                        rightPanel={rightPanel}
                        rightPanelName={rightPanelName}
                        hidePanelToggle={hidePanelToggle}
                        fullScreen={fullScreen}
                        bottomBuffer={bottomBuffer}
                    />
                )}
            </LayoutContext.Provider>
        </Div>
    );
}
