// Import from NPM
// -------------------------------------
import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import useWindowFocus from "use-window-focus";
import { Loader } from "semantic-ui-react";
import { Div } from "@components/Generics.react";

// Import from Config
// -------------------------------------
import { useIsMounted } from "@hooks/useIsMounted";
import { useGetDeckQuery } from "@api/apiV6";
import {
    abandonDeck,
    completeDeck,
    getAttemptState,
    startDeckAttempt,
} from "@reducers/attemptSlice";
import { LayoutContext } from "@layouts/MasterLayout.react";
import { darkStyle, getTimeSpent, isStringifiedJSON } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";
import { deckFormats } from "@schemas/deckFormats";
import DeckEnding from "./panels/DeckEnding.react";

// Import Components
// -------------------------------------

export default function Deck(props) {
    const { deckHash, inCourse, closeDeck, openDeckView, setIsSaving } = props;
    const { setFullScreen } = useContext(LayoutContext);
    const mounted = useIsMounted();

    const userId = useSelector((state) => state.auth.user._id);
    const attempt = useSelector((state) => state.attempt);
    const dispatch = useDispatch();
    const isPortrait = window.innerHeight > window.innerWidth;

    const {
        data: deck,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetDeckQuery({
        id: deckHash.id,
        user_id: userId,
        readerType: deckHash.readerType,
    });

    useEffect(() => {
        dispatch(
            startDeckAttempt({
                id: deckHash.id,
                currentState: deck?.currentState,
                readerType: deckHash.readerType,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    //---------------------------------------------------------------------------
    // Time tracking
    //---------------------------------------------------------------------------
    const [timeStart, setTimeStart] = useState(Date.now());
    const [timeSpent, setTimeSpent] = useState(0);
    const windowFocused = useWindowFocus();
    useEffect(() => {
        if (windowFocused) {
            setTimeStart(Date.now());
        } else {
            setTimeSpent(timeSpent + getTimeSpent(timeStart));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowFocused]);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    const [attemptData, setAttemptData] = useState(null);
    const [showEnding, setShowEnding] = useState(false);

    function recordAttempt(data) {
        let adjData = data;
        if (data.pointsDelta == null) adjData.pointsDelta = 0; // Used to catch points reset error
        if (data.sessionTime && data.sessionTime !== 'NA') adjData.timeSpent = data.sessionTime;
        console.log("adjData: ", adjData);
        setAttemptData(adjData);
        if (data.showEnding) {
            setShowEnding(true);
        }
    }

    useEffect(() => {
        return () => {
            if (
                (getTimeSpent(timeStart) > 0 || timeSpent > 0) &&
                attempt.deck &&
                !mounted()
            ) {
                const updatedData = _.merge(_.omit(attemptData, "showEnding"), {
                    deckId: deck._id,
                    date: Date.now(),
                });
                if (!attemptData.timeSpent) {
                    updatedData.timeSpent = timeSpent + getTimeSpent(timeStart);
                }
                if (updatedData.completion)
                    dispatch(
                        completeDeck(getAttemptState(attempt, updatedData))
                    );
                else
                    dispatch(
                        abandonDeck(getAttemptState(attempt, updatedData))
                    );
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted, attempt, attemptData]);

    //---------------------------------------------------------------------------
    // When Deck is closed, close fullscreen and proceed
    //---------------------------------------------------------------------------
    function exitDeck() {
        setFullScreen(false);
        closeDeck();
    }

    //---------------------------------------------------------------------------
    // Deck height management
    //---------------------------------------------------------------------------
    const deckHeight = deckHash.inPlayer
        ? isPortrait
            ? `calc(100vh - ${
                  /iPad|iPhone|iPod/.test(navigator.userAgent) ? 230 : 190
              }px)`
            : `calc(100vh - 120px)`
        : "auto";
    // ========================= Render Function =================================
    const DeckReader = _.find(deckFormats, {
        readerType: deck?.readerType,
    })?.reader;

    if (isLoading)
        return (
            <Loader active>
                <Div txtMidnight>Loading Deck...</Div>
            </Loader>
        );
    if (isError) return <div>{error.error}</div>;
    if (isSuccess) {
        let adjDeck = deck;
        if (isStringifiedJSON(deck.content)) {
            adjDeck = {
                ...JSON.parse(deck.content),
                ...{ currentState: deck.currentState },
            };
        }
        if (adjDeck.quo_game?.folder) {
            adjDeck.quo_game = adjDeck.quo_game.folder;
        }
        if (showEnding)
            return (
                <DeckEnding
                    deckHeight={deckHeight}
                    deckHash={deckHash}
                    inCourse={inCourse}
                    name={adjDeck.name}
                    passmark={adjDeck.passingPercentage}
                    colors={adjDeck.colors}
                    points={adjDeck.points}
                    attemptData={attemptData}
                    closeDeck={closeDeck}
                />
            );
        else
            return (
                <Div
                    fluid
                    ht={deckHeight}
                    maxHt={deckHeight}
                    rounded
                    padTop={
                        isPortrait &&
                        deckHash.inPlayer &&
                        adjDeck.readerType !== "game"
                    }
                    relative
                    flex
                    style={
                        deckHash.inPlayer && !isPortrait
                            ? darkStyle("#999999")
                            : {}
                    }
                    className={`deck-viewer ${inCourse && "in-course"}`}
                >
                    {deck && attempt.deck != null && (
                        <DeckReader
                            key={`deck-reader-${adjDeck._id}`}
                            deck={adjDeck}
                            currentState={attempt.deckState.currentState}
                            colors={{ primary: settings.colors.main }}
                            recordAttempt={recordAttempt}
                            inCourse={inCourse}
                            closeDeck={exitDeck}
                            openDeckView={openDeckView}
                            setIsSaving={setIsSaving}
                        />
                    )}
                </Div>
            );
    }
    return <Div />;
}
