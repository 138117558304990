import { useEffect } from "react";

import "@styles/readers.scss";
import { Div, Image } from "@components/Generics.react";

export default function ProductCatalog(props) {
    let dummyData = {
        name: "Healthy Product Range",
        blob: {
            content: [
                {
                    image: "https://picsum.photos/335/200",
                    title: "Organic Edmame",
                    description: "With Peanut Sauce",
                    meta: "$4.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Chicken Satay",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                    meta: "$6.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Non-Fried Chicken Curry Puff",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
                    meta: "$5.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Shrimp Satay",
                    description: "With Peanut Sauce",
                    meta: "$5.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Organic Edmame",
                    description: "With Peanut Sauce",
                    meta: "$4.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Chicken Satay",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                    meta: "$6.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Non-Fried Chicken Curry Puff",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
                    meta: "$5.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Shrimp Satay",
                    description: "With Peanut Sauce",
                    meta: "$5.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Organic Edmame",
                    description: "With Peanut Sauce",
                    meta: "$4.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Chicken Satay",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
                    meta: "$6.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Non-Fried Chicken Curry Puff",
                    description:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
                    meta: "$5.95",
                },
                {
                    image: "https://picsum.photos/335/200",
                    title: "Shrimp Satay",
                    description: "With Peanut Sauce",
                    meta: "$5.95",
                },
            ],
        },
    };

    const { name, blob } = dummyData;
    const { currentState, recordAttempt } = props;

    let products = blob?.content || [];

    useEffect(() => {
        window.addEventListener("beforeunload", trackData);

        return () => {
            trackData();
            window.removeEventListener("beforeunload", trackData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function trackData() {
        recordAttempt({
            completion: currentState.completion === 100 ? 100 : 0,
        });
    }

    return (
        <Div
            fluid
            fullht
            ash
            scrollOverflow
            priText
            normal
            className="product-catalog-reader"
        >
            <Div midnight small bold big altText className="product-name">
                {name}
            </Div>

            <Div className="main-section">
                {products.map((product) => {
                    return (
                        <Div white rounded className="product-card">
                            <Image snubbed gutter src={product.image} />
                            <Div className="details-section">
                                <Div bold normal>
                                    {product.title}
                                </Div>
                                <Div small normal>
                                    {product.description}
                                </Div>
                                <Div small normal bold txtColor="#FFA500">
                                    {product.meta}
                                </Div>
                            </Div>
                        </Div>
                    );
                })}
            </Div>
        </Div>
    );
}
