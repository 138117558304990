import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import "@styles/readers.scss";
import CircularButton from "@components/CircularButton.react";
import { Image } from "semantic-ui-react";
import { Div, Button } from "@components/Generics.react";
import Header from "@components/Header.react";

export default function ProductCard(props) {
    const { deck, colors, currentState, recordAttempt } = props;
    const imageRef = useRef(null);

    const [activeBtn, setActiveBtn] = useState(deck?.buttons[0]);

    useEffect(() => {
        if (deck?.buttons && !activeBtn) {
            setActiveBtn(deck?.buttons[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deck, activeBtn]);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    const buttons = deck?.buttons;

    return (
        <Div maxWd="400px" ht="80%" medpad centered smoke rounded center-align>
            <Div fluid fullht ash priText className="product-card-reader">
                <Div relative topRounded noOverflow>
                    <div ref={imageRef}>
                        <Image fluid src={deck?.image} />
                        <Div
                            midnight
                            centered
                            bold
                            topRounded
                            className="product-card-name"
                            uppercase
                        >
                            {deck?.name}
                        </Div>
                    </div>
                </Div>

                {buttons && (
                    <Div
                        fluid
                        ht={`calc(100% - ${imageRef.current?.clientHeight}px)`}
                        bottomRounded
                        noOverflow
                    >
                        <Div fullht autoOverflowY relative>
                            <Div className="button-container">
                                {buttons.map((button, index) => (
                                    <CircularButton
                                        key={`product-card-btn-${index}`}
                                        active={
                                            index ===
                                            _.findIndex(
                                                buttons,
                                                (obj) =>
                                                    obj["title"] ===
                                                    activeBtn.title
                                            )
                                        }
                                        colors={colors}
                                        color={button.color}
                                        icon={button.icon}
                                        onClick={() =>
                                            setActiveBtn(deck?.buttons[index])
                                        }
                                    />
                                ))}
                            </Div>
                            {activeBtn && (
                                <Div
                                    className="product-card-content"
                                    fullht
                                    float-right
                                    white
                                    flex
                                    left-align
                                >
                                    <Header title={activeBtn.content.title} />
                                    {activeBtn.content.description && (
                                        <Div>
                                            {activeBtn.content.description}
                                        </Div>
                                    )}
                                    {activeBtn.button && (
                                        <>
                                            {activeBtn.button.map(
                                                (btn, index) => {
                                                    if (!btn.link)
                                                        return (
                                                            <Div>
                                                                {btn.option}
                                                            </Div>
                                                        );
                                                    else if (
                                                        btn.link.includes(
                                                            "http"
                                                        )
                                                    )
                                                        return (
                                                            <a
                                                                href={btn.link}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                key={`prod-card-link-${index}`}
                                                            >
                                                                <Div snug fluid>
                                                                    <Button
                                                                        primary
                                                                        fluid
                                                                    >
                                                                        {
                                                                            btn.option
                                                                        }
                                                                    </Button>
                                                                </Div>
                                                            </a>
                                                        );
                                                    else
                                                        return (
                                                            <NavLink
                                                                to={btn.link}
                                                                key={`prod-card-link-${index}`}
                                                            >
                                                                <Div snug fluid>
                                                                    <Button
                                                                        primary
                                                                        fluid
                                                                    >
                                                                        {
                                                                            btn.option
                                                                        }
                                                                    </Button>
                                                                </Div>
                                                            </NavLink>
                                                        );
                                                }
                                            )}
                                        </>
                                    )}
                                </Div>
                            )}
                        </Div>
                    </Div>
                )}
            </Div>
        </Div>
    );
}
