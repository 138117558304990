import React, { useEffect, useState } from "react";
import useWindowFocus from "use-window-focus";
import { Image } from "semantic-ui-react";
import { lightOrDark, getTimeSpent } from "@utilities/helpers";

import "@styles/readers.scss";
import { Div } from "@components/Generics.react";

export default function MemeReader(props) {
    const { deck, attempt, recordAttempt } = props;

    //---------------------------------------------------------------------------
    // Time tracking
    //---------------------------------------------------------------------------
    const [timeStart, setTimeStart] = useState(null);
    const [timeSpent, setTimeSpent] = useState(0);
    const windowFocused = useWindowFocus();
    useEffect(() => {
        if (windowFocused) {
            setTimeStart(Date.now());
        } else {
            setTimeSpent(timeSpent + getTimeSpent(timeStart));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowFocused]);

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        const thisAttempt = attempt;

        return () => {
            recordAttempt(thisAttempt, {
                deckId: deck._id,
                viewedPercentage: 100,
                completion: true,
                timeSpent: timeSpent + getTimeSpent(timeStart),
                pointsDelta: 0,
                date: Date.now(),
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Div fluid fullht className="meme-reader" centered center-align>
            {deck.content.map((meme, index) => {
                return (
                    <Div
                        key={`meme=${index}`}
                        gutter
                        maxWd="400px"
                        fluid
                        centered
                        huge
                        className="meme-container"
                    >
                        <Image fluid src={meme.image} />
                        <Div
                            bold
                            basepad
                            altText
                            className="meme-text-top"
                            style={{
                                color: meme.colors?.topText || "#ffffff",
                                textShadow: `0 0 5px ${
                                    lightOrDark(meme.colors?.topText) === "dark"
                                        ? "#ffffff"
                                        : "#000000"
                                }`,
                            }}
                        >
                            {meme.topText}
                        </Div>
                        <Div
                            bold
                            basepad
                            altText
                            className="meme-text-bottom"
                            style={{
                                color: meme.colors?.bottomText || "#ffffff",
                                textShadow: `0 0 5px ${
                                    lightOrDark(meme.colors?.bottomText) ===
                                    "dark"
                                        ? "#ffffff"
                                        : "#000000"
                                }`,
                            }}
                        >
                            {meme.bottomText}
                        </Div>
                    </Div>
                );
            })}
            
        </Div>
    );
}
