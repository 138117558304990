import React, { useState, useEffect } from "react";
import _ from "lodash";
import $ from "jquery";

import { TemplateList } from "@schemas/templateSchema.react";
import { SlideLayout } from "@layouts/SlideLayout.react";
import "@styles/readers.scss";
import { includeScript } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";

import { Div, AbsWrapper } from "@components/Generics.react";
import DeckLeaderboard from "../panels/DeckLeaderboard.react";
import GameInstructions from "../panels/GameInstructions.react";
import GameOver from "../panels/GameOver.react";
import GameLaunch from "../panels/GameLaunch.react";

export default function GameReader(props) {
    const { deck, recordAttempt, inCourse, setIsSaving } = props;
    const isPortrait = window.innerHeight > window.innerWidth;

    //---------------------------------------------------------------------------
    // Game Configuration
    //---------------------------------------------------------------------------

    const [scriptsLoaded, setScriptsLoaded] = useState(false);

    const [launchView, setLaunchView] = useState(true);
    const [endingView, setEndingView] = useState(false);
    const [quizView, setQuizView] = useState(false);
    const [leaderboardView, setLeaderboardView] = useState(false);
    const [helpView, setHelpView] = useState(false);
    const [resultReturned, setResultReturned] = useState(false);
    const [points, setPoints] = useState(0);
    const [score, setScore] = useState(deck?.currentState.score);
    const [level, setLevel] = useState(1);
    const [win, setWin] = useState(!inCourse);

    async function setGameData() {
        try {
            const data = {
                firstrun: "true",
                standalone: "0",
                playerName: "Player",
                quoresponse: "0",
                gameId: deck.gameId,
                passmark: settings.gamification.gameDifficulty,
                // passmark: deck.passingPercentage,
                param1: deck.param1,
                param2: deck.param2,
                param3: deck.param3,
                gamedata: deck.gamedata,
                targetingMode: inCourse ? 1 : 0,
                maxPoints: deck.points,
            };
            Object.assign(window.localStorage, data);
            window.showQuiz = showQuiz;
            window.showLeaderboard = showLeaderboard;
            window.showHelp = showHelp;
            window.returnResult = returnResult;

            const gameJSON = require.context("../gameTemplates");
            window.dataFile = gameJSON("./" + deck.quo_game + "/data.json");
        } catch (err) {
            console.log(err);
        }

        // window.localStorage.setItem("brandlogo", getAppConfig().ui.iconlogo);
        // window.localStorage.setItem(
        //     "quoreturn",
        //     "'courses/" +
        //         _.last(
        //             _.split(hashHistory.getCurrentLocation().pathname, "/")
        //         ) +
        //         "'"
        // );
    }

    async function loadScripts() {
        try {
            includeScript("/assets/javascripts/jquery-2.1.1.min.js", () => {
                includeScript("/assets/javascripts/c2runtime.js", () => {
                    includeScript(
                        `/assets/games/images/${deck.quo_game}/objectRefs.js`,
                        () => {
                            setScriptsLoaded(true);
                        }
                    );
                });
            });
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        var doubleTouchStartTimestamp = 0;
        function handleDoubleTap(e) {
            console.log("doubleTap");
            var now = +new Date();
            if (doubleTouchStartTimestamp + 500 > now) {
                e.preventDefault();
            }
            doubleTouchStartTimestamp = now;
        }
        document.addEventListener("touchstart", handleDoubleTap);
        return () => {
            document.removeEventListener("touchstart", handleDoubleTap);
            if (setIsSaving) setIsSaving(true);
            setTimeout(() => window.location.reload(), 2000);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async () => {
            if (!launchView) {
                await setGameData();
                await loadScripts();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [launchView]);

    useEffect(() => {
        if (scriptsLoaded) {
            window.cr_createRuntime("c2canvas");
            document.addEventListener(
                "visibilitychange",
                () => window.cr_setSuspended(document.hidden),
                false
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scriptsLoaded]);

    function replay() {
        $("#replaybtn").click();
        setResultReturned(false);
        setEndingView(false);
    }
    function showLeaderboard() {
        setLeaderboardView(true);
    }
    function showHelp() {
        setHelpView(true);
    }

    function closeScreens() {
        $("#closemain").click();
        setHelpView(false);
        setLeaderboardView(false);
    }

    //---------------------------------------------------------------------------
    // Quiz View Management
    //---------------------------------------------------------------------------

    const [questionArray, setQuestionArray] = useState(deck.content);
    const [pageNumber, setPageNumber] = useState(0);
    const [slideData, setSlideData] = useState(null);
    const [allViewed, setAllViewed] = useState(false);

    useEffect(() => {
        const localBank = window.localStorage.getItem(`qArray-${deck._id}`);
        const localPointer = window.localStorage.getItem(
            `qPointer-${deck._id}`
        );
        if (localBank == null || localPointer == null) {
            const qBank = _.map(_.shuffle(deck.content), "slideSeq"); //Shuffle the question bank and get the shuffled slideSequence array
            setQuestionArray(qBank);
            window.localStorage.setItem(
                `qArray-${deck._id}`,
                JSON.stringify(qBank)
            ); // Store the slideSequenceArray in localstorage
            window.localStorage.setItem(`qPointer-${deck._id}`, 0);
        } else {
            setQuestionArray(JSON.parse(localBank));
            setPageNumber(parseInt(localPointer, 0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const currentSlide = _.find(deck.content, {
            slideSeq: questionArray[pageNumber],
        });
        window.localStorage.setItem(`qPointer-${deck._id}`, pageNumber);
        setSlideData(currentSlide);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, questionArray, deck.content]);

    function showQuiz() {
        window.localStorage.setItem("quoresponse", "0");
        setQuizView(true);
    }
    function trackData(hashObj) {
        window.localStorage.setItem(
            "quoresponse",
            hashObj.correct ? "100" : "0"
        );
    }
    function updateAttempt(pageNumber) {
        setQuizView(false);
        if (pageNumber > deck.content.length - 1) {
            setAllViewed(true);
            setPageNumber(0);
        } else setPageNumber(pageNumber);
        $("#responder").click();
    }

    //---------------------------------------------------------------------------
    // Slide Content
    //---------------------------------------------------------------------------
    const templateSchema = TemplateList[slideData?.template];
    const SlideComponent = templateSchema?.template;
    const title = templateSchema?.showHeader ? slideData.data.title : "";
    const subtitle = templateSchema?.showHeader ? slideData.data.subtitle : "";

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    function returnResult(gameId, result, quizScore, points, level) {
        if (!resultReturned) {
            setResultReturned(true);
            setScore(quizScore);
            setPoints(points);
            setLevel(level);
            setWin(result === "win");
            setEndingView(true);
            recordAttempt({
                viewedPercentage: allViewed
                    ? 100
                    : parseInt((pageNumber * 100) / deck.content.length, 0),
                completion: result === "win",
                pointsDelta:
                    points &&
                    Math.max(points - (deck.currentState.points || 0), 0),
                score: quizScore,
            });
        }
    }

    //---------------------------------------------------------------------------
    // Overlay Display
    //---------------------------------------------------------------------------

    function overlay() {
        if (quizView) {
            return (
                <AbsWrapper fluid fullht charcoal>
                    <Div fluid fullht centered center-align>
                        <SlideLayout
                            title={title}
                            subtitle={subtitle}
                            image={slideData?.data?.image}
                            sequence={slideData?.slideSeq}
                            colors={deck?.colors}
                        >
                            {SlideComponent && (
                                <SlideComponent
                                    {...slideData}
                                    deckId={deck._id}
                                    setPageNumber={updateAttempt}
                                    pageNumber={pageNumber}
                                    trackData={trackData}
                                />
                            )}
                        </SlideLayout>
                    </Div>
                </AbsWrapper>
            );
        } else if (leaderboardView) {
            return (
                <AbsWrapper fluid fullht charcoal>
                    <Div fluid fullht centered center-align>
                        <DeckLeaderboard
                            deck={deck}
                            closeScreens={closeScreens}
                            score={score}
                        />
                    </Div>
                </AbsWrapper>
            );
        } else if (helpView) {
            return (
                <AbsWrapper fluid fullht charcoal>
                    <Div fluid fullht centered center-align>
                        <GameInstructions
                            deck={deck}
                            closeScreens={closeScreens}
                        />
                    </Div>
                </AbsWrapper>
            );
        } else if (launchView) {
            return (
                <Div fluid fullht charcoal>
                    <GameLaunch
                        deck={deck}
                        inCourse={inCourse}
                        points={points}
                        showLeaderboard={showLeaderboard}
                        showHelp={showHelp}
                        startGame={() => setLaunchView(false)}
                    />
                </Div>
            );
        } else if (endingView) {
            return (
                <AbsWrapper fluid fullht charcoal>
                    <Div fluid fullht centered center-align>
                        <GameOver
                            deck={deck}
                            win={win}
                            inCourse={inCourse}
                            replay={replay}
                            points={points}
                            score={score}
                            level={level}
                            showLeaderboard={showLeaderboard}
                        />
                    </Div>
                </AbsWrapper>
            );
        }
    }

    const canvasHeight = window.innerHeight - 130;
    // ========================= Render Function =================================
    return (
        <Div
            ht={
                isPortrait
                    ? `calc(100vh - ${
                          /iPad|iPhone|iPod/.test(navigator.userAgent)
                              ? 225
                              : 170
                      }px)`
                    : "100%"
            }
            relative
            charcoal
            className="game-player"
        >
            <Div
                fluid
                ht="calc(100% - 40px)"
                flex
                rounded={!isPortrait}
                style={{
                    marginTop: isPortrait ? "0" : "25px",
                }}
            >
                <Div fluid noOverflow id="c2canvasdiv">
                    <canvas id="c2canvas" width={480} height={canvasHeight} />
                </Div>
            </Div>
            <AbsWrapper fluid fullht>
                {overlay()}
            </AbsWrapper>
        </Div>
    );
}
