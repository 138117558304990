import BadgeReader from "@player/decks/readers/BadgeReader.react";
import CertificateReader from "@player/decks/readers/CertificateReader.react";
import RewardReader from "@player/decks/readers/RewardReader.react";
import DocumentReader from "@player/decks/readers/DocumentReader.react";
import VideoReader from "@player/decks/readers/VideoReader.react";
import QdfReader from "@player/decks/readers/QdfReader.react";
import AssessmentReader from "@player/decks/readers/AssessmentReader.react";
import GameReader from "@player/decks/readers/GameReader.react";
import HTMLReader from "@player/decks/readers/HTMLReader.react";
import CourseArticleReader from "@player/decks/readers/CourseArticleReader.react";
import PodcastReader from "@player/decks/readers/PodcastReader.react";
import MemeReader from "@player/decks/readers/MemeReader.react";
import CheckListReader from "@player/decks/readers/CheckListReader.react";
import FAQReader from "@player/decks/readers/FAQReader.react";
import ProductCard from "@player/decks/readers/ProductCard.react";
import ProductFAQ from "@player/decks/readers/ProductFAQ.react";
import ProductCatalog from "@player/decks/readers/ProductCatalog.react";
import PlaylistReader from "@player/decks/readers/PlaylistReader.react";
import IframeReader from "@player/decks/readers/IframeReader.react";
import SurveyReader from "@player/decks/readers/SurveyReader.react";
import SCORMReader from "@player/decks/readers/SCORMReader.react";
import GalleryReader from "@player/decks/readers/GalleryReader.react";
import NewsReader from "@player/decks/readers/NewsReader.react";
import EventReader from "@player/decks/readers/EventReader.react";
import WebinarReader from "@player/decks/readers/WebinarReader.react";

export const deckFormats = {
    course: {
        name: "Course",
        readerType: "coursedeck",
        inPlayer: false,
        icons: ["books", "graduation", "reading", "library2"],
        reader: CourseArticleReader,
    },
    document: {
        name: "Document",
        readerType: "docdeck",
        inPlayer: true,
        icons: ["file-text2", "page-break", "share3", "magazine"],
        reader: DocumentReader,
    },
    article: {
        name: "Blog Post",
        readerType: "htmldeck",
        inPlayer: false,
        icons: ["bullhorn", "magazine", "newspaper", "typewriter"],
        reader: HTMLReader,
    },
    video: {
        name: "Video",
        readerType: "videck",
        inPlayer: false,
        icons: ["movie", "clapboard-play", "video-camera", "play3"],
        reader: VideoReader,
    },
    webinar: {
        name: "Webinar",
        readerType: "webinardeck",
        inPlayer: true,
        icons: ["bubble-video", "book-play", "collaboration", "earth"],
        reader: WebinarReader,
    },
    badge: {
        name: "Badge",
        readerType: "badgedeck",
        inPlayer: true,
        icons: ["star", "medal2", "rocket3", "chess-king"],
        reader: BadgeReader,
    },
    certificate: {
        name: "Certificate",
        readerType: "certdeck",
        inPlayer: true,
        icons: ["certificate", "stars", "user-tie", "sun3"],
        reader: CertificateReader,
    },
    reward: {
        name: "Reward",
        readerType: "rewarddeck",
        inPlayer: true,
        icons: ["gift2", "percent", "bubble-heart", "trophy4"],
        reader: RewardReader,
    },
    gallery: {
        name: "Gallery",
        readerType: "gallerydeck",
        inPlayer: false,
        icons: ["images", "pinter4", "stackoverflow", "finder"],
        reader: GalleryReader,
    },
    event: {
        name: "Event",
        readerType: "eventdeck",
        inPlayer: true,
        icons: ["calender4", "bullhorn", "camera3", "mic5"],
        reader: EventReader,
    },
    news: {
        name: "News",
        readerType: "newsdeck",
        inPlayer: false,
        icons: ["newspaper", "antenna2", "archive", "display4"],
        reader: NewsReader,
    },
    assessment: {
        name: "Assessment",
        readerType: "assessment",
        inPlayer: true,
        icons: ["brain", "question4", "reading", "stack-text"],
        reader: AssessmentReader,
    },
    survey: {
        name: "Survey",
        readerType: "survey",
        inPlayer: true,
        icons: ["file-text", "clipboard5", "file-stats2", "layers"],
        reader: SurveyReader,
    },
    story: {
        name: "story",
        readerType: "qdf",
        inPlayer: true,
        icons: ["quill2", "theater", "ticket2", "weather-windy"],
        reader: QdfReader,
    },
    game: {
        name: "Game",
        readerType: "game",
        inPlayer: true,
        icons: ["gamepad", "dice", "pacman", "puzzle"],
        reader: GameReader,
    },
    productcard: {
        name: "Product Card",
        readerType: "productcarddeck",
        inPlayer: true,
        icons: ["android", "at-sign", "bag", "basket"],
        reader: ProductCard,
    },
    scorm: {
        name: "SCORM",
        readerType: "scormdeck",
        inPlayer: true,
        icons: ["file-zip2", "file-play2", "film3", "dropbox"],
        reader: SCORMReader,
    },
    faq: {
        name: "FAQ",
        readerType: "faqdeck",
        inPlayer: false,
        icons: ["question3", "cancel-circle2", "menu8", "checkmark-circle"],
        reader: FAQReader,
    },

    meme: {
        name: "Meme",
        readerType: "memedeck",
        inPlayer: false,
        icons: ["wink2", "archery", "grin2", "bubble-star"],
        reader: MemeReader,
    },
    scratchcards: {
        name: "scratchcards",
        readerType: "scratchcarddeck",
        inPlayer: true,
        icons: ["cash", "credit-card", "price-tags", "stack4"],
        reader: null,
    },
    rating: {
        name: "Rating",
        readerType: "ratingdeck",
        inPlayer: true,
        icons: ["star-empty2", "stairs-up", "power2", "arrow-up16"],
        reader: null,
    },
    poll: {
        name: "Poll",
        readerType: "polldeck",
        inPlayer: true,
        icons: ["equalizer", "stats-growth2", "rating3", "transmission2"],
        reader: null,
    },
    podcast: {
        name: "podcast",
        readerType: "podcastdeck",
        inPlayer: true,
        icons: ["podcast", "headset", "media", "mic"],
        reader: PodcastReader,
    },
    playlist: {
        name: "playlist",
        readerType: "playlistdeck",
        inPlayer: true,
        icons: ["playlist", "file-music", "speakers", "next"],
        reader: PlaylistReader,
    },
    guidelines: {
        name: "Guidelines",
        readerType: "guidelinesdeck",
        inPlayer: true,
        icons: ["clipboard2", "bubble-lines3", "checkmark", "bubble-lock"],
        reader: null,
    },
    reel: {
        name: "Reel",
        readerType: "reeldeck",
        inPlayer: true,
        icons: ["camera3", "heart3", "cool", "video2"],
        reader: null,
    },
    discussion: {
        name: "Discussion",
        readerType: "discussiondeck",
        inPlayer: false,
        icons: ["bubbles2", "lan3", "users2", "thumbs-up3"],
        reader: null,
    },
    iframe: {
        name: "Iframe",
        readerType: "iframedeck",
        inPlayer: true,
        icons: ["presentation", "contract", "exclude", "checkbox-partial2"],
        reader: IframeReader,
    },
    photosubmission: {
        name: "Photo Submission",
        readerType: "photosubmissiondeck",
        inPlayer: true,
        icons: ["enlarge5", "enter2", "file-picture2", "happy2"],
        reader: null,
    },
    checklist: {
        name: "Checklist",
        readerType: "checklistdeck",
        inPlayer: true,
        icons: ["clipboard5", "menu3", "list", "file-check"],
        reader: CheckListReader,
    },
    profiler: {
        name: "Profiler",
        readerType: "profilerdeck",
        inPlayer: true,
        icons: ["profile", "user5", "bubble-user", "safe"],
        reader: null,
    },
    recommender: {
        name: "recommender",
        readerType: "recommenderdeck",
        inPlayer: true,
        icons: ["map", "mail4", "puzzle3", "reminder"],
        reader: null,
    },
    quiz: {
        name: "Quiz",
        readerType: "assessment",
        inPlayer: true,
        icons: ["brain", "question4", "reading", "stack-text"],
        reader: null,
    },
    calculator: {
        name: "Calculator",
        readerType: "calcdeck",
        inPlayer: true,
        icons: ["calculator2", "calculator3", "rulers", "angle"],
        reader: null,
    },
    productgrid: {
        name: "Product Grid",
        readerType: "productgriddeck",
        inPlayer: true,
        icons: ["grid5", "books", "cube3", "dropbox"],
        reader: null,
    },
    productfaq: {
        name: "Product FAQ",
        readerType: "productfaqdeck",
        inPlayer: true,
        icons: ["question", "cancel-circle2", "menu8", "checkmark-circle"],
        reader: ProductFAQ,
    },
    responsivetable: {
        name: "Responsive Table",
        readerType: "responsetabledeck",
        inPlayer: true,
        icons: ["chart", "pie-chart8", "table2", "equalizer3"],
        reader: null,
    },
    productcatalog: {
        name: "Product Catalog",
        readerType: "productcatalogdeck",
        inPlayer: false,
        icons: ["notebook", "database-menu", "cart5", "benu2"],
        reader: ProductCatalog,
    },
    flashcards: {
        name: "Flash cards",
        readerType: "flashcardsdeck",
        inPlayer: true,
        icons: [
            "stack-pictures",
            "stack-overflow",
            "attachment",
            "bubble-lines3",
        ],
        reader: null,
    },
    videosubmission: {
        name: "Video Submission",
        readerType: "visubmissiondeck",
        inPlayer: true,
        icons: ["file-video2", "stack-plus", "video-camera5", "upload5"],
        reader: null,
    },
    documentsubmission: {
        name: "Document Submission",
        readerType: "docsubmisiondeck",
        inPlayer: true,
        icons: ["stack-up", "file-zip2", "file-upload2", "file-check"],
        reader: null,
    },
    leaderboard: {
        name: "Leaderboard",
        readerType: "leaderboarddeck",
        inPlayer: true,
        icons: ["podium", "medal-star", "trophy", "archery"],
        reader: null,
    },
    dialogue: {
        name: "Dialogue",
        readerType: "dialoguedeck",
        inPlayer: true,
        icons: ["bubble", "megaphone", "bubble-dots3", "finder"],
        reader: null,
    },
    spotlight: {
        name: "Spotlight",
        readerType: "spotlightdeck",
        inPlayer: true,
        icons: ["spotlight2", "spotlight", "fire", "power2"],
        reader: null,
    },
    infographic: {
        name: "Infographic",
        readerType: "infodeck",
        inPlayer: true,
        icons: ["chart-bars", "align-bottom", "angle", "dots"],
        reader: null,
    },
    interactivevideo: {
        name: "Interactive Video",
        readerType: "interactivevideck",
        inPlayer: true,
        icons: ["file-play2", "film4", "book-play", "pause"],
        reader: null,
    },
    listicle: {
        name: "Listicle",
        readerType: "listicledeck",
        inPlayer: false,
        icons: ["list", "menu2", "file-text", "lan2"],
        reader: null,
    },
    offer: {
        name: "Offer",
        readerType: "offerdeck",
        inPlayer: true,
        icons: ["sun3", "price-tags", "gift2", "percent"],
        reader: null,
    },
    interactivecharts: {
        name: "Interactive Charts",
        readerType: "interactivechartsdeck",
        inPlayer: true,
        icons: ["pie-chart", "factory", "equalizer3", "file-stats2"],
        reader: null,
    },
    jobpost: {
        name: "Job Post",
        readerType: "jobpostdeck",
        inPlayer: false,
        icons: ["magazine", "user-tie", "reading", "vcard"],
        reader: null,
    },
    downloadable: {
        name: "Downloadable",
        readerType: "downloadabledeck",
        inPlayer: true,
        icons: ["download", "stack-down", "copy3", "envelop5"],
        reader: null,
    },
    default: {
        inPlayer: true,
        icons: ["bubble-quote", "bubble5", "bubble-smiley", "bubble-lines4"],
        reader: HTMLReader,
    },
};
