export const updateDeck = (readerType, currentState, attempt) => {
    const presentState = {
        viewedPercentage: Math.min(100, currentState.viewedPercentage ?? 0),
        viewAttempts: currentState.viewAttempts ?? 0,
        timeSpent: currentState.timeSpent ?? 0,
        score: currentState.score,
        points: currentState.points ?? 0,
        completion: currentState.completion ?? false,
        completionDate: currentState.completionDate,
    };
    switch (readerType) {
        case "htmldeck":
        case "article":
            return updateHTMLDeck(presentState, attempt);
        case "badgedeck":
        case "badge":
            return updateBadge(presentState, attempt);
        case "certdeck":
        case "certificate":
            return updateCertificate(presentState, attempt);
        case "rewarddeck":
        case "reward":
            return updateReward(presentState, attempt);
        case "docdeck":
        case "document":
            return updateDocument(presentState, attempt);
        case "videck":
        case "video":
            return updateVideo(presentState, attempt);
        case "assessment":
            return updateAssessment(presentState, attempt);
        case "survey":
            return updateSurvey(presentState, attempt);
        case "game":
            return updateGame(presentState, attempt);
        case "scorm":
        case "scormdeck":
            return updateSCORM(presentState, attempt);
        case "qdf":
        case "story":
            return updateQdf(presentState, attempt);
        case "gallery":
        case "gallerydeck":
            return updateGallery(presentState, attempt);
        case "productcard":
        case "productcarddeck":
            return updateProductCard(presentState, attempt);
        case "news":
        case "newsdeck":
            return updateNews(presentState, attempt);
        case "faqdeck":
        case "faq":
            return updateFAQ(presentState, attempt);
        case "eventdeck":
        case "event":
            return updateEvent(presentState, attempt);
        case "course":
            return updateCourse(presentState, attempt);
        case "webinar":
        case "webinardeck":
            return updateWebinar(presentState, attempt);

        case "memedeck":
            return updateMeme(presentState, attempt);
        case "productfaq":
            return updateProductFAQ(presentState, attempt);
        case "productgrid":
            return updateProductGrid(presentState, attempt);
        case "productcatalog":
            return updateProductCatalog(presentState, attempt);
        case "playlist":
            return updatePlaylist(presentState, attempt);
        case "podcast":
            return updatePodcast(presentState, attempt);
        case "checklist":
            return updateChecklist(presentState, attempt);

        case "downloadabledeck":
            return updateDownloadable(presentState, attempt);
        case "scratchcarddeck":
            return updateScratchcard(presentState, attempt);
        case "guidelinesdeck":
            return updateGuidelines(presentState, attempt);

        case "iframedeck":
            return updateIframe(presentState, attempt);
        case "poll":
            return updatePoll(presentState, attempt);
        case "rating":
            return updateRating(presentState, attempt);
        case "discussion":
            return updateDiscussion(presentState, attempt);
        case "recommendation":
            return updateRecommendation(presentState, attempt);
        case "profiler":
            return updateProfiler(presentState, attempt);
        case "reel":
            return updateReel(presentState, attempt);
        default:
            return { error: true };
    }
};

const updateBadge = (currentState, attempt) => {
    const { completion, timeSpent, pointsDelta, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateCertificate = (currentState, attempt) => {
    const { completion, timeSpent, pointsDelta, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateReward = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateDocument = (currentState, attempt) => {
    const { viewedPercentage, completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: Math.min(
            100,
            Math.max(currentState.viewedPercentage || 0, viewedPercentage)
        ),
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateVideo = (currentState, attempt) => {
    const { viewedPercentage, completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: Math.min(
            100,
            Math.max(currentState.viewedPercentage || 0, viewedPercentage)
        ),
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateSCORM = (currentState, attempt) => {
    const {
        viewedPercentage,
        completion,
        timeSpent,
        date,
        score,
        pointsDelta,
    } = attempt;
    return {
        viewedPercentage: Math.min(
            100,
            Math.max(currentState.viewedPercentage || 0, viewedPercentage)
        ),
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: Math.max(currentState.score || 0, score),
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateQdf = (currentState, attempt) => {
    const { viewedPercentage, completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: Math.min(
            100,
            Math.max(currentState.viewedPercentage || 0, viewedPercentage)
        ),
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateAssessment = (currentState, attempt) => {
    const {
        viewedPercentage,
        completion,
        timeSpent,
        date,
        score,
        pointsDelta,
    } = attempt;
    return {
        viewedPercentage: Math.min(
            100,
            Math.max(currentState.viewedPercentage || 0, viewedPercentage)
        ),
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: Math.max(currentState.score || 0, score || 0),
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateGame = (currentState, attempt) => {
    const {
        viewedPercentage,
        completion,
        timeSpent,
        date,
        pointsDelta,
        score,
    } = attempt;
    return {
        viewedPercentage: Math.min(
            100,
            Math.max(currentState.viewedPercentage || 0, viewedPercentage)
        ),
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: Math.max(currentState.score || 0, score || 0),
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateSurvey = (currentState, attempt) => {
    const { viewedPercentage, completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: Math.min(
            100,
            Math.max(currentState.viewedPercentage || 0, viewedPercentage)
        ),
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateHTMLDeck = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateGallery = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateChecklist = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateCourse = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateFAQ = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateMeme = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updatePlaylist = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updatePodcast = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateProductCatalog = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateProductCard = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateProductFAQ = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateWebinar = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateDownloadable = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateScratchcard = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateGuidelines = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateEvent = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateNews = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateIframe = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updatePoll = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateRating = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateDiscussion = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateRecommendation = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateProfiler = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateReel = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateProductGrid = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: completion ? 100 : 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
