import React from "react";
import { Div } from "@components/Generics.react";

export default function Paragraph(props) {
    const { paragraph } = props.data;

    // ========================= Render Function =================================
    return (
        <Div fluid fullht zoom superpad big>
            {paragraph}
        </Div>
    );
}
