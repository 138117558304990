import React, { useRef } from "react";

export default function FileBase64(props) {
    const {
        multiple = false,
        accept,
        capture,
        limit,
        onDone,
        onReject,
        inputWidth,
    } = props;

    const inputRef = useRef(null);

    const handleChange = (e) => {
        let files = e.target.files;
        let allFiles = [];
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let size = Math.round(file.size / 1000);
                if (limit == null || size < limit) {
                    let fileInfo = {
                        name: file.name,
                        type: file.type,
                        size: size + " kB",
                        base64: reader.result,
                        file: file,
                    };
                    allFiles.push(fileInfo);
                    if (allFiles.length === files.length) {
                        if (multiple) onDone(allFiles);
                        else onDone(allFiles[0]);
                    }
                } else {
                    e.target.value = null;
                    onReject(
                        `The upload size of ${size}kB is higher than the limit of ${limit}kB. Please choose a smaller file.`
                    );
                }
            };
        }
    };

    let imageUploadStyle = {
        display: "flex",
        wordWrap: "break-word",
    };

    return (
        <div style={imageUploadStyle}>
            <input
                type="file"
                ref={inputRef}
                onChange={handleChange}
                multiple={multiple}
                accept={accept}
                capture={capture}
                style={{
                    width: inputWidth ? inputWidth : "none",
                }}
            />
            {limit && (
                <p
                    style={{
                        fontSize: "0.75em",
                        margin: "5px",
                        textAlign: "left",
                        fontStyle: "italic",
                    }}
                >
                    Maximum File Size: {limit}kB
                </p>
            )}
        </div>
    );
}
