import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "@styles/readers.scss";

import { getPointsDelta, darkStyle } from "@utilities/helpers";

import { Div, Icon } from "@components/Generics.react";
import Certificate from "@components/Certificate.react";

export default function CertificateReader(props) {
    const { deck, currentState, recordAttempt, closeDeck } = props;
    const { t } = useTranslation("common");
    const isPortrait = window.innerHeight > window.innerWidth;
    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta:
                currentState && deck.points
                    ? getPointsDelta(currentState, parseInt(deck.points, 0))
                    : 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    // ========================= Render Function =================================
    return (
        <Div fluid superpad={!isPortrait} centered flex>
            <Div fluid centered gutter txtMidnight rounded dropShadow>
                <Certificate
                    deck={deck}
                    logo={"/assets/images/configurable/logo.png"}
                />
            </Div>
            {deck.points > 0 && (
                <Div
                    fit-content
                    large
                    bold
                    centered
                    relaxed
                    rounded
                    trench
                    style={darkStyle("#666666")}
                >
                    {deck.points} {t("deck.points")}
                </Div>
            )}
            <Div className="close-icon" onClick={closeDeck}>
                {t("deck.close")} <Icon name="close" inline fitted />
            </Div>
        </Div>
    );
}
