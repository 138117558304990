import React from "react";
import _ from "lodash";
import { Div, Image } from "@components/Generics.react";
import { clientAssetsPath } from "@api/apiV6";

export default function IconList(props) {
    const { iconlist } = props.data;

    const getOptions = _.map(iconlist, (item, index) => {
        return (
            <li
                key={`list-item-${index}`}
                style={{
                    listStyle: "none",
                    clear: "both",
                }}
            >
                <Div wd="20%" float-left gutter>
                <Image fluid src={`${clientAssetsPath}/uploads/qdf/slides/${item.image.id}${item.image.extension}`} />
                </Div>
                <Div wd="75%" float-left nudgeLeft gutter big>
                    {item.text}
                </Div>
            </li>
        );
    });

    return (
        <Div fluid fullht zoom padTop>
            <ul style={{ marginLeft: "-15px" }}>{getOptions}</ul>
        </Div>
    );
}
