import React, { Suspense, useLayoutEffect, useState, useEffect } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    Outlet,
    useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "./config/store/app.store";

import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next from "i18next";

import common_de from "./config/translations/de/common.json";
import common_en from "./config/translations/en/common.json";
import common_hi from "./config/translations/hi/common.json";

import "semantic-ui-css/semantic.min.css";
import "./index.css";
import "@styles/overrule.scss";
import { devMode } from "./utilities/helpers";
import { settings } from "./config/settings/app.settings";
import { apiV6, targetRoute } from "./config/api/apiV6";

//-----------------------------------------------------------------------------
// Player Modules
//-----------------------------------------------------------------------------
import Register from "./pages/authentication/Registration.react";
import Login from "./pages/authentication/Login.react";
import Logout from "./pages/authentication/Logout.react";
import ForgotPassword from "./pages/authentication/ForgotPassword.react";
import ChangePassword from "./pages/authentication/ChangePassword.react";
import Home from "./pages/player/home/Home.react";
import Library from "./pages/player/courses/Library.react";
import Course from "./pages/player/courses/Course.react";
import SingleCourse from "./pages/player/courses/SingleCourse.react";
import Program from "./pages/player/courses/Program.react";
import Article from "./pages/player/articles/Article.react";
import ArticleListing from "./pages/player/articles/ArticleListing.react";
import Social from "./pages/player/social/Social.react";
import HotSpotEditor from "./utilities/HotSpotEditor.react";
import AppReports from "./pages/player/reports/AppReports.react";
import AppLeaderboard from "./pages/player/reports/AppLeaderboard.react";
import AppCollectibles from "./pages/player/reports/AppCollectibles.react";
import HelpDesk from "./pages/player/help/HelpDesk.react";
import Contest from "./pages/player/contests/Contest.react";
import EditProfile from "./pages/player/user/EditProfile.react";
import AppHome from "./pages/player/home/AppHome.react";
import SearchResults from "./pages/player/home/panels/SearchResults.react";
import GamifixPerformance from "./pages/player/client/ekart/GamifixPerformance.react";
import CleanAppLeaderboard from "./pages/player/reports/CleanAppLeaderboard.react";
import CleanAppCollectibles from "./pages/player/reports/CleanAppCollectibles.react";
import ComingSoon from "./pages/player/home/ComingSoon.react";

// Notes for route specifications
// - If the id is course id, please use :courseId
// - If the id is topic id, please use :topicId
// - If the id is deck id, please use :deckId

function App() {
    const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
    const userLang = useSelector((state) => state.auth.user?.lang);
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    const isPortrait = window.innerHeight > window.innerWidth;

    i18next.use(initReactI18next).init({
        interpolation: { escapeValue: false }, // React already does escaping
        lng: userLang, // language to use
        fallbackLng: "en",
        resources: {
            en: {
                common: common_en, // 'common' is our custom namespace
            },
            de: {
                common: common_de,
            },
            hi: {
                common: common_hi,
            },
        },
    });

    function loadLibraries() {
        store.dispatch(apiV6.endpoints.getArticles.initiate());
        store.dispatch(apiV6.endpoints.getCourseLibrary.initiate());
    }

    useEffect(() => {
        if (isUserLoggedIn) loadLibraries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserLoggedIn]);

    function RequireAuth() {
        const location = useLocation();
        const searchParams = new URLSearchParams(document.location.search);
        const fromBlacklist = ["/logout"];
        if (!isUserLoggedIn) {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            let loginPath = `/login`;
            if (searchParams.get("token") != null) {
                loginPath += `?token=${searchParams.get("token")}`;
            }
            return (
                <Navigate
                    to={loginPath}
                    state={{
                        from: fromBlacklist.includes(location.pathname)
                            ? null
                            : location,
                    }}
                    replace
                />
            );
        } else {
            return <Outlet />;
        }
    }
    function DoNotRequireAuth() {
        if (!isUserLoggedIn) {
            return <Outlet />;
        }
        return false;
    }

    // Handle Resizing in the app
    useLayoutEffect(() => {
        setTimeout(function () {
            let viewheight = window.innerHeight;
            let viewwidth = window.innerWidth;
            let viewport = document.querySelector("meta[name=viewport]");
            viewport.setAttribute(
                "content",
                `height=${viewheight}px, width=${viewwidth}px, initial-scale=1`
            );
        }, 300);
    }, []);

    // Handle Resizing in the app
    useLayoutEffect(() => {
        function updateSize() {
            if (!window.doNotResize && devMode)
                setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        if (window.innerWidth !== size[0] || window.innerHeight !== size[1])
            updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, [size]);

    return (
        <I18nextProvider i18n={i18next}>
            <Suspense fallback="loading" size={size}>
                <BrowserRouter basename={targetRoute}>
                    <Routes>
                        <Route element={<DoNotRequireAuth />}>
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route
                                path="/forgot"
                                element={<ForgotPassword />}
                            />
                        </Route>
                        <Route element={<RequireAuth />}>
                            <Route
                                path="/"
                                element={
                                    settings.functionality.hasHome &&
                                    isPortrait ? (
                                        <AppHome />
                                    ) : (
                                        <Home />
                                    )
                                }
                            />
                            <Route path="/learningcenter" element={<Home />} />
                            <Route
                                path="/tags/:name"
                                element={<ArticleListing />}
                            />
                            <Route
                                path="/content/:readerType/:articleIdentifier"
                                element={<Article />}
                            />

                            <Route path="/library" element={<Library />} />
                            <Route
                                path="/programs/:programId"
                                element={<Program />}
                            />
                            <Route path="/library" element={<Library />} />
                            <Route path="/social" element={<Social />} />
                            <Route
                                path="/courses/:courseId"
                                element={<Course />}
                            />
                            <Route
                                path="/explore/:courseId"
                                element={<SingleCourse />}
                            />
                            <Route path="/logout" element={<Logout />} />
                            <Route path="/search" element={<SearchResults />} />
                            <Route
                                path="/hotspotter"
                                element={<HotSpotEditor />}
                            />
                            <Route
                                path="/app/leaderboard"
                                element={<AppLeaderboard />}
                            />
                            <Route
                                path="/view/leaderboard"
                                element={<CleanAppLeaderboard />}
                            />
                            <Route
                                path="/app/rewards"
                                element={<AppCollectibles />}
                            />
                            <Route
                                path="/view/rewards"
                                element={<CleanAppCollectibles />}
                            />
                            <Route
                                path="/app/reports"
                                element={<AppReports />}
                            />
                            <Route
                                path="/app/helpdesk"
                                element={<HelpDesk />}
                            />
                            <Route
                                path="/editProfile/:isolated"
                                element={<EditProfile />}
                            />
                            <Route path="/contests" element={<Contest />} />
                            <Route
                                path="/social/:postId"
                                element={<Social />}
                            />
                            <Route
                                path="/setpass"
                                element={<ChangePassword />}
                            />
                            <Route
                                path="/comingsoon"
                                element={<ComingSoon />}
                            />

                            <Route
                                path="/clients/ekart/dashboard"
                                element={<GamifixPerformance />}
                            />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </I18nextProvider>
    );
}

export default App;
