import Title from "@player/decks/slideTemplates/Title.react";
import TitleSubtitle from "@player/decks/slideTemplates/TitleSubtitle.react";
import Paragraph from "@player/decks/slideTemplates/Paragraph.react";
import BackgroundImage from "@player/decks/slideTemplates/BackgroundImage.react";
import SingleSelect from "@player/decks/slideTemplates/SingleSelect.react";
import TrueFalse from "@player/decks/slideTemplates/TrueFalse.react";
import MultipleSelect from "@player/decks/slideTemplates/MultipleSelect.react";
import Splash from "@player/decks/slideTemplates/Splash.react";
import Brancher from "@player/decks/slideTemplates/Brancher.react";
import BulletList from "@player/decks/slideTemplates/BulletList.react";
import LineBulletList from "@player/decks/slideTemplates/LineBulletList.react";
import IconList from "@player/decks/slideTemplates/IconList.react";
import Blurb from "@player/decks/slideTemplates/Blurb.react";
import ExternalLink from "@player/decks/slideTemplates/ExternalLink.react";
import InteralLink from "@player/decks/slideTemplates/InternalLink.react";
import OpenInternalLink from "@player/decks/slideTemplates/OpenInternalLink.react";
import AnimatedBulletList from "@player/decks/slideTemplates/AnimatedBulletList.react";
import Conversation from "@player/decks/slideTemplates/Conversation.react";
import ParagraphLine from "@player/decks/slideTemplates/ParagraphLine.react";
import VideoFullParagraph from "@player/decks/slideTemplates/VideoFullParagraph.react";
import Video from "@player/decks/slideTemplates/Video.react";
import DoDontList from "@player/decks/slideTemplates/DoDontList.react";
import ImageCaption from "@player/decks/slideTemplates/ImageCaption.react";
import IconListGallery from "@player/decks/slideTemplates/IconListGallery.react";
import Diptych from "@player/decks/slideTemplates/Diptych.react";
import Triptych from "@player/decks/slideTemplates/Triptych.react";
import Grid from "@player/decks/slideTemplates/Grid.react";
import Cards from "@player/decks/slideTemplates/Cards.react";
import Gallery from "@player/decks/slideTemplates/Gallery.react";
import PopupList from "@player/decks/slideTemplates/PopupList.react";
import PDFViewer from "@player/decks/slideTemplates/PDFViewer.react";
import FITB from "@player/decks/slideTemplates/FITB.react";
import OpenAnswer from "@player/decks/slideTemplates/OpenAnswer.react";
import Anagram from "@player/decks/slideTemplates/Anagram.react";
import PictureFuddle from "@player/decks/slideTemplates/PictureFuddle.react";
import PickPicture from "@player/decks/slideTemplates/PickPicture.react";
import RankThemRight from "@player/decks/slideTemplates/RankThemRight.react";

export const TemplateList = {
    title: {
        key: "title",
        name: "Title",
        template: Title,
        showHeader: false,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: false },
        components: ["icon", "title", "presenter"],
    },
    title_subtitle: {
        key: "title_subtitle",
        name: "Title Subtitle",
        template: TitleSubtitle,
        showHeader: false,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: false },
        components: ["icon", "title", "subtitle", "presenter"],
    },
    paragraph: {
        key: "paragraph",
        name: "Paragraph",
        template: Paragraph,
        showHeader: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["icon", "title", "subtitle", "paragraph", "presenter"],
    },
    background_image: {
        key: "background_image",
        name: "Background Image",
        template: BackgroundImage,
        showHeader: false,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "paragraph", "position", "background"],
    },
    splash: {
        key: "splash",
        name: "Splash",
        template: Splash,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["splash"],
    },
    bulletlist: {
        key: "bulletlist",
        name: "Bullet List",
        template: BulletList,
        overlay: null,
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "bullets"],
    },
    iconlist: {
        key: "iconlist",
        name: "Icon List",
        template: IconList,
        overlay: null,
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "bullets"],
    },
    iconlist_timeline: {
        key: "iconlist_timeline",
        name: "Icon List",
        template: IconList,
        overlay: null,
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "bullets"],
    },
    iconlist_gallery: {
        key: "iconlist_gallery",
        name: "Icon List Gallery",
        template: IconListGallery,
        overlay: null,
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "cards"],
    },
    iconlist_captions: {
        key: "iconlist_captions",
        name: "Icon List Gallery",
        template: IconListGallery,
        overlay: null,
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "cards"],
    },
    brancher_template: {
        key: "brancher_template",
        name: "Brancher",
        template: Brancher,
        showHeader: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["image", "title", "paragraph", "brancher"],
    },
    line_bulletlist: {
        key: "line_bulletlist",
        name: "Captioned Bullet List",
        template: LineBulletList,
        showHeader: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["icon", "title", "subtitle", "paragraph", "presenter"],
    },
    blurb: {
        key: "blurb",
        name: "Blurb",
        template: Blurb,
        showHeader: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "paragraph", "presenter"],
    },
    external_link: {
        key: "external_link",
        name: "External Link",
        template: ExternalLink,
        showHeader: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "paragraph", "link"],
    },
    internal_link: {
        key: "internal_link",
        name: "Link to Slide",
        template: InteralLink,
        overlay: null,
        questionable: false,
        scorable: false,
        showHeader: true,
        intracation: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "paragraph", "link"],
    },
    open_internal_link: {
        key: "open_internal_link",
        name: "Internal Link",
        template: OpenInternalLink,
        overlay: null,
        questionable: false,
        scorable: false,
        showHeader: true,
        intracation: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["image", "title", "subtitle", "paragraph", "link"],
    },
    animated_bulletlist: {
        key: "animated_bulletlist",
        name: "Carousel List",
        template: AnimatedBulletList,
        overlay: null,
        questionable: false,
        scorable: false,
        showHeader: true,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: true },
        components: [
            "title",
            "subtitle",
            "bullets",
            "pattern",
            "theme",
            "image",
        ],
    },
    conversation: {
        key: "conversation",
        name: "Conversation",
        template: Conversation,
        overlay: null,
        questionable: false,
        showHeader: true,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["icon", "image", "title", "subtitle", "conversation"],
    },
    video: {
        key: "video",
        name: "Video",
        template: Video,
        overlay: null,
        questionable: false,
        showHeader: true,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["title", "paragraph", "video"],
    },
    video_caption: {
        key: "video",
        name: "Video",
        template: Video,
        overlay: null,
        questionable: false,
        showHeader: true,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["title", "paragraph", "video", "caption"],
    },
    video_full_title: {
        key: "video_full_title",
        name: "Video Background",
        template: VideoFullParagraph,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["title", "video"],
    },
    video_full_paragraph: {
        key: "video_full_paragraph",
        name: "Video Background",
        template: VideoFullParagraph,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["paragraph", "video"],
    },
    dodontlist: {
        key: "dodontlist",
        name: "Dos and Donts List",
        template: DoDontList,
        showHeader: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: true },
        components: ["title", "subtitle", "bullets", "rebullets"],
    },
    paragraph_line: {
        key: "paragraph_line",
        name: "Captioned Paragraph",
        template: ParagraphLine,
        showHeader: true,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["icon", "title", "subtitle", "paragraph", "caption"],
    },
    image: {
        key: "image",
        name: "Image",
        template: ImageCaption,
        overlay: "",
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "image"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    image_caption: {
        key: "image_caption",
        name: "Captioned Image",
        template: ImageCaption,
        overlay: "",
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "image", "caption"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    image_zoom: {
        key: "image_zoom",
        name: "Zoomable Image",
        template: ImageCaption,
        overlay: "",
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "image", "caption"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    diptych: {
        key: "diptych",
        name: "Diptych",
        template: Diptych,
        overlay: "",
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "diptych"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    triptych: {
        key: "triptych",
        name: "Triptych",
        template: Triptych,
        overlay: "",
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "triptych"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    grid: {
        key: "grid",
        name: "Grid",
        template: Grid,
        overlay: "",
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "grid"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    cards: {
        key: "cards",
        name: "Cards",
        template: Cards,
        overlay: "",
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "cards"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    gallery: {
        key: "gallery",
        name: "Gallery",
        template: Gallery,
        overlay: "",
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "cards"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    popuplist: {
        key: "popuplist",
        name: "Popup List",
        template: PopupList,
        overlay: "",
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "popuplist"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    pdfviewer: {
        key: "pdfviewer",
        name: "PDF Viewer",
        template: PDFViewer,
        overlay: "",
        showHeader: true,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "pdfviewer"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
    single_select: {
        key: "single_select",
        name: "Single Select",
        template: SingleSelect,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "options",
            "feedback",
            "purpose",
        ],
    },
    pick_picture: {
        key: "pick_picture",
        name: "Pick Picture",
        template: PickPicture,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "options",
            "feedback",
            "purpose",
        ],
    },
    true_false: {
        key: "true_false",
        name: "True or False",
        template: TrueFalse,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "choice",
            "feedback",
            "purpose",
        ],
    },
    odd_one_out: {
        key: "odd_one_out",
        name: "Odd One Out",
        template: SingleSelect,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "options",
            "feedback",
            "purpose",
        ],
    },
    multiple_select: {
        key: "multiple_select",
        name: "Multiple Select",
        template: MultipleSelect,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "multipleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "multiselect",
            "feedback",
            "purpose",
        ],
    },
    fill_in_the_blanks: {
        key: "fill_in_the_blanks",
        name: "Fill In The Blanks",
        template: FITB,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "fitb",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "answer",
            "feedback",
            "purpose",
        ],
    },
    detailer: {
        key: "detailer",
        name: "Detailer",
        template: FITB,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "fitb",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "answer",
            "feedback",
            "purpose",
        ],
    },
    anagram: {
        key: "anagram",
        name: "Anagram",
        template: Anagram,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: true,
        interaction: "fitb",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "answer",
            "feedback",
            "purpose",
        ],
    },
    open_answer: {
        key: "open_answer",
        name: "Open Answer",
        template: OpenAnswer,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "answer",
            "feedback",
            "purpose",
        ],
    },
    picture_fuddle: {
        key: "picture_fuddle",
        name: "Picture Fuddle",
        template: PictureFuddle,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "image",
            "answer",
            "feedback",
            "purpose",
        ],
    },
    feedback_paragraph: {
        key: "feedback_paragraph",
        name: "Feedback Paragraph",
        template: PictureFuddle,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "image",
            "answer",
            "feedback",
            "purpose",
        ],
    },
    rank_them_right: {
        key: "rank_them_right",
        name: "Rank Them Right",
        template: RankThemRight,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "bullets",
            "feedback",
            "purpose",
        ],
    },
    timeliner: {
        key: "timeliner",
        name: "Timeliner",
        template: RankThemRight,
        showHeader: false,
        overlay: null,
        questionable: true,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "bullets",
            "feedback",
            "purpose",
        ],
    },
    icon_caption: {
        key: "icon_caption",
        name: "Captioned Icon",
        template: ImageCaption,
        overlay: "",
        showHeader: false,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "image", "caption"],
        target: {
            qdf: true,
            assessment: false,
            survey: false,
            game: false,
        },
    },
};
