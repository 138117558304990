import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    queue: [],
    lastPushed: null,
    lastUpdated: null,
    pushable: false,
};

export const trackerSlice = createSlice({
    name: "tracker",
    initialState,
    reducers: {
        trackClear: (state) => {
            const herenow = new Date();
            return {
                ...initialState,
                lastPushed: herenow.toLocaleString("en-IN"),
                lastUpdated: state.lastUpdated,
            };
        },
        trackQuestion: (state, action) => {
            createTracker("QUESTION_RESPONSE", state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase("auth/login", (state) => createTracker("LOGIN", state, {}))
            .addCase("auth/logout", (state) =>
                createTracker("LOGOUT", state, {})
            )
            .addCase("attempt/startSession", (state, action) => {
                const todayDate = new Date();
                const lastSessionDate = new Date(action.payload.last_session);
                const addSession =
                    todayDate.setHours(0, 0, 0, 0) >
                    lastSessionDate.setHours(0, 0, 0, 0); // If it is a new day, add Session
                if (addSession) createTracker("SESSION_STARTED", state, {});
            })
            .addCase("attempt/startAttempt", (state, action) => {
                if (action.payload.deck)
                    createTracker("DECK_STARTED", state, {
                        deck: action.payload.deck,
                    });
            })
            .addCase("attempt/startArticleAttempt", (state, action) => {
                if (action.payload.id)
                    createTracker("ARTICLE_STARTED", state, {
                        article: action.payload.id,
                        deck: action.payload.deckId,
                    });
            })
            .addCase("attempt/completeDeck", (state, action) => {
                createTracker("DECK_COMPLETED", state, {
                    deck: action.payload.deck,
                });
                if (action.payload.courseState?.currentState?.completion)
                    createTracker("COURSE_COMPLETED", state, {
                        course: action.payload.course,
                    });
                const payload = createPayload(action.payload);
                createTracker("PUSH_STATE", state, payload);
                state.pushable = true;
                return state;
            })
            .addCase("attempt/abandonDeck", (state, action) => {
                createTracker("DECK_ABANDONED", state, {
                    deck: action.payload.deck,
                });
                const payload = createPayload(action.payload);
                createTracker("PUSH_STATE", state, payload);
                state.pushable = true;
                return state;
            })

            .addDefaultCase(() => {});
    },
});

const createPayload = (payload) => {
    const pushState = {
        deck: payload.deck,
        readerType: payload.readerType,
        deckState: payload.deckState,
        learnerState: payload.learnerState,
    };
    if (payload.article) {
        pushState.article = payload.article;
        pushState.articleState = payload.articleState;
    }
    if (payload.course) {
        pushState.topic = payload.topic;
        pushState.course = payload.course;
        pushState.program = payload.program;
        pushState.topicState = payload.topicState;
        pushState.courseState = payload.courseState;
        pushState.programState = payload.programState;
    }
    return pushState;
};

const createTracker = (name, state, payload, time) => {
    state.queue.push({
        name: name,
        payload: payload,
        time: time || Date.now(),
    });
    const herenow = new Date();
    state.lastUpdated = herenow.toLocaleString("en-IN");
    return state;
};

// Action creators are generated for each case reducer function
export const {
    trackClear,
    trackLogin,
    trackLogout,
    trackQuestion,
    trackPushState,
} = trackerSlice.actions;

export default trackerSlice.reducer;
