// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dimmer, Loader } from "semantic-ui-react";
import _ from "lodash";

// Import from Config
// -------------------------------------
import { apiV6, useGetCourseQuery, useGetSessionsQuery } from "@api/apiV6";
import {
    startCourseAttempt,
    startTopicAttempt,
    startSession,
} from "@reducers/attemptSlice";
import { WrapperList, PickedLocations } from "@schemas/wrapperSchema";
import { deckFormats } from "@schemas/deckFormats";
import { darkStyle } from "@utilities/helpers";

// Import Components
// -------------------------------------
import { Div } from "@components/Generics.react";
import MasterLayout from "@layouts/MasterLayout.react";
import VisualGame from "./panels/VisualGame.react";
import Deck from "../decks/Deck.react";
import FormalWrapper from "./panels/FormalWrapper.react";
import CourseLeaderboard from "./panels/CourseLeaderboard.react";
import CourseRewards from "./panels/CourseRewards.react";

export default function SingleCourse(props) {
    const { courseId } = useParams();
    const [locData, setLocData] = useState([]);
    const [activeLoc, setActiveLoc] = useState(null);
    const [locMenuOpen, setLocMenuOpen] = useState(false);
    const [activeDeck, setActiveDeck] = useState(null);
    const [singleDeckCourse, setSingleDeckCourse] = useState(true);
    const attempt = useSelector((state) => state.attempt);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();

    const { t } = useTranslation("common");

    const isPortrait = window.innerHeight > window.innerWidth;
    const {
        data: course,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetCourseQuery({ id: courseId, user_id: user._id });
    const { data: sessions } = useGetSessionsQuery(user._id);

    useEffect(() => {
        return () => {
            dispatch(apiV6.util.resetApiState());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (course) {
            updateLocData();
            if (attempt.course !== courseId)
                dispatch(
                    startCourseAttempt({
                        id: courseId,
                        currentState: course.currentState,
                        history: course.history,
                    })
                );
            dispatch(startSession(sessions));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course, attempt.course, sessions]);

    // ------------------------------------------
    // Location Status Mapping
    // ------------------------------------------
    function updateLocData() {
        // Set Maximum Locations allowed in a wrapper
        const maxLocations = 12;
        // Get Course Locations with non-empty contentlist
        const nonEmptyTopics = _.filter(course.locations, (location) => {
            return location.contentList.length > 0;
        });
        // Pick the appropriate location array [1,3,5] based on the number of locations with content in the course
        const locationMap = PickedLocations[nonEmptyTopics.length];

        // Create the location array
        let locData = [];
        let currentFound = false;
        for (let i = 0; i < maxLocations; i++) {
            let mapIndex = locationMap.indexOf(i); // The index of this node amongst the highlighted locations
            if (mapIndex !== -1) {
                // If this location is active
                let location = _.cloneDeep(course.locations[mapIndex]);
                location.status = location.currentState?.completion
                    ? "complete"
                    : currentFound
                    ? "incomplete"
                    : "current";
                if (location.status === "current") currentFound = true;
                locData.push(location);
                if (location.contentList.length > 1) setSingleDeckCourse(false); // If the location contains more than one deck, its a multideck course
            } else {
                // If this location is disabled
                locData.push(null);
            }
        }
        setLocData(locData);
        if (activeLoc == null) {
            const currentLoc = locData.indexOf(
                _.find(locData, { _id: course.currentState?.lastPosition })
            );
            const nowActive = currentLoc === -1 ? 0 : currentLoc;
            setActiveLoc(nowActive);
            dispatch(
                startTopicAttempt({
                    id: locData[nowActive]._id,
                    currentState: locData[nowActive].currentState,
                })
            );
        }
    }

    // ------------------------------------------
    // On location click
    // ------------------------------------------
    function openLocation(index) {
        const topic = locData[index];
        // When Topic is opened, add topic to the attempt stack
        if (attempt.topic !== topic?._id)
            dispatch(
                startTopicAttempt({
                    id: topic?._id,
                    currentState: topic?.currentState,
                })
            );
        // If the course only has single-deck topics, open deck else open menu
        //    setActiveLoc(index);
        if (singleDeckCourse && isPortrait)
            openDeckView(
                topic?.contentList[0]?._id,
                topic?.contentList[0]?.readerType
            );
        else {
            setActiveLoc(index);
            setLocMenuOpen(true);
        }
    }

    // ------------------------------------------
    // Show and hide deck view
    // ------------------------------------------
    function openDeckView(id, readerType) {
        // setActiveLoc(null);
        setActiveDeck({
            id: id,
            readerType: readerType,
            inPlayer: deckFormats[readerType]?.inPlayer,
        });
    }
    function closeDeckView() {
        setActiveDeck(null);
    }
    // ------------------------------------------
    // Select Main Panel Component
    // ------------------------------------------
    function mainPanel() {
        // If showing deck
        if (activeDeck)
            return (
                <Div
                    fluid
                    rounded
                    style={isPortrait ? {} : darkStyle("#666666")}
                >
                    <Deck
                        deckHash={activeDeck}
                        colors={course?.colors}
                        closeDeck={closeDeckView}
                        openDeckView={openDeckView}
                        inCourse={true}
                    />
                </Div>
            );
        // If showing course
        else {
            const hasWrapper = WrapperList[course?.wrapper];
            // If course has a formal wrapper
            if (!hasWrapper || hasWrapper?.template)
                if (activeLoc != null || isPortrait) {
                    return (
                        <FormalWrapper
                            courseHeader={false}
                            course={course}
                            locData={locData}
                            activeLoc={activeLoc}
                            showMenu={locMenuOpen || !isPortrait}
                            closeMenu={() => setLocMenuOpen(false)}
                            openDeck={openDeckView}
                            setActiveLoc={openLocation}
                        />
                    );
                } else
                    return (
                        <Dimmer active>
                            <Loader>Updating Data...</Loader>
                        </Dimmer>
                    );
            // If course has a construct weapper
            if (hasWrapper?.constructWrapper) return <div />;
            // If course has a visual weapper
            return (
                <VisualGame
                    courseHeader={false}
                    course={course}
                    locData={locData}
                    activeLoc={activeLoc}
                    showMenu={locMenuOpen}
                    closeMenu={() => setLocMenuOpen(false)}
                    openDeck={openDeckView}
                    setActiveLoc={openLocation}
                />
            );
        }
    }
    // ------------------------------------------
    // Setup Right Panel Component
    // ------------------------------------------
    const rightPanel = {
        primary: {
            component: <CourseLeaderboard course={course} />,
            btnText: t("sidebar.mobile.leaderboard"),
            icon: "list ol",
        },
        secondary: {
            component: <CourseRewards course={course} />,
            btnText: t("sidebar.mobile.rewards"),
            icon: "gift",
        },
    };

    // ========================= Render Function =================================

    if (isLoading) return <Loader active />;
    if (isError) return <div>{error.error}</div>;
    if (isSuccess)
        return (
            <MasterLayout
                course={course}
                title={course.name}
                navName={course.name}
                openDeckView={openDeckView}
                mainPanel={mainPanel()}
                isolated={true}
                rootPage={true}
                rightPanel={rightPanel}
                hidePanelToggle={activeDeck != null}
            />
        );
}
