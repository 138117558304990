import React, { useState, useEffect } from "react";
import _ from "lodash";

import { TemplateList } from "@schemas/templateSchema.react";
import { SlideLayout } from "@layouts/SlideLayout.react";
import "@styles/readers.scss";

import { Div } from "@components/Generics.react";
import DeckToolbar from "@components/DeckToolbar.react";

export default function SurveyReader(props) {
    const { deck, recordAttempt } = props;
    const isPortrait = window.innerHeight > window.innerWidth;

    //---------------------------------------------------------------------------
    // Slide Navigation
    //---------------------------------------------------------------------------
    const [pageNumber, setPageNumber] = useState(0);
    const [numPages, setNumPages] = useState(1);
    const [maxRead, setMaxRead] = useState(0);
    const [slideData, setSlideData] = useState(null);

    useEffect(() => {
        if (numPages !== deck.content.length) setNumPages(deck.content.length);
        const currentSlide = _.find(deck.content, {
            slideSeq: pageNumber,
        });
        setSlideData(currentSlide);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, deck, numPages]);

    //---------------------------------------------------------------------------
    // Slide Content
    //---------------------------------------------------------------------------
    const templateSchema = TemplateList[slideData?.template];
    const SlideComponent = templateSchema?.template;

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        if (maxRead <= pageNumber + 1) setMaxRead(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
        if (numPages > 0) {
            recordAttempt({
                viewedPercentage: Math.min(
                    100,
                    parseInt(((maxRead + 1) * 100) / numPages, 0)
                ),
                completion: maxRead >= numPages - 1,
                pointsDelta: 0,
                score: null,
                showEnding: maxRead >= numPages,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numPages, maxRead]);

    // ========================= Render Function =================================
    return (
        <Div
            fluid
            ht={`calc(100vh - ${
                isPortrait
                    ? /iPad|iPhone|iPod/.test(navigator.userAgent)
                        ? 195
                        : 155
                    : 125
            }px)`}
            centered
            center-align
            className="fullscreen-target"
        >
            <SlideLayout
                className="slide-container"
                title={templateSchema?.showHeader ? slideData.data.title : ""}
                subtitle={
                    templateSchema?.showHeader ? slideData.data.subtitle : ""
                }
                image={slideData?.data?.image}
                sequence={slideData?.slideSeq}
                colors={deck?.colors}
            >
                {SlideComponent && (
                    <SlideComponent
                        {...slideData}
                        deckId={deck._id}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        noFeedback={true}
                    />
                )}
            </SlideLayout>
            <DeckToolbar
                {...{
                    pageNumber,
                    setPageNumber,
                    numPages,
                }}
            />
        </Div>
    );
}
