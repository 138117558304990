import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "@styles/readers.scss";
import * as DOMPurify from "dompurify";

import { Div, Button } from "@components/Generics.react";

export default function NewsReader(props) {
    const { deck, currentState, recordAttempt } = props;
    const { t } = useTranslation("common");

    //---------------------------------------------------------------------------
    // Completion Tracking
    //---------------------------------------------------------------------------
    useEffect(() => {
        recordAttempt({
            viewedPercentage: 100,
            completion: true,
            pointsDelta: 0,
            score: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    // ========================= Render Function =================================
    return (
        <Div rounded fluid fullht>
            <div
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(deck.description),
                }}
            />
            <br />
            {deck.link && (
                <a href={deck.link} target="_blank" rel="noreferrer">
                    <Button content={t("article.news")} fluid primary />
                </a>
            )}
        </Div>
    );
}
