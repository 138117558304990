import React from "react";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    EmailShareButton,
    RedditShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
    RedditIcon,
} from "react-share";
import { Div } from "@components/Generics.react";

const ShareButtons = ({ url, subject }) => {
    const title = `Share: ${subject}`;
    return (
        <Div>
            {/* Email */}
            <EmailShareButton url={url} subject={subject}>
                <Div snug title={title}>
                    <EmailIcon size={32} round />
                </Div>
            </EmailShareButton>

            {/* WhatsApp */}
            <WhatsappShareButton url={url}>
                <Div snug title={title}>
                    <WhatsappIcon size={32} round />
                </Div>
            </WhatsappShareButton>

            {/* Facebook */}
            <FacebookShareButton url={url}>
                <Div snug title={title}>
                    <FacebookIcon size={32} round />
                </Div>
            </FacebookShareButton>

            {/* Twitter */}
            <TwitterShareButton url={url}>
                <Div snug title={title}>
                    <TwitterIcon size={32} round />
                </Div>
            </TwitterShareButton>

            {/* LinkedIn */}
            <LinkedinShareButton url={url}>
                <Div snug title={title}>
                    <LinkedinIcon size={32} round />
                </Div>
            </LinkedinShareButton>

            {/* Reddit */}
            <RedditShareButton url={url}>
                <Div snug title={title}>
                    <RedditIcon size={32} round />
                </Div>
            </RedditShareButton>
        </Div>
    );
};

export default ShareButtons;
