import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import shortid from "shortid";
import { Div, Icon } from "@components/Generics.react";
import "@styles/components.scss";

export default function Carousel(props) {
    const {
        children,
        initialSlide = 0,
        activeSlide = 0,
        speed = 500,
        autoPlay = false,
        arrows = false,
        dots = false,
        infinite = false,
        asNavFor,
        handleChange,
        handleClick,
        slidesToShow = 1,
        slidesToScroll = 1,
        lazyLoad = false,
        enableButtons = false,
        customPaging,
    } = props;
    const [sliderId] = useState(shortid.generate());
    const [active, setActive] = useState(activeSlide);
    const [settings, setSettings] = useState(null);
    const [sliderRef, setSliderRef] = useState(null);

    useEffect(() => {
        let carouselSettings = {
            speed: speed,
            initialSlide: initialSlide,
            slidesToShow: slidesToShow,
            slidesToScroll: slidesToScroll,
            arrows: arrows,
            dots: dots,
            autoplay: autoPlay,
            asNavFor: asNavFor,
            infinite: infinite,
            lazyLoad: lazyLoad,
            swipe: activeSlide !== children.length - 1,
            beforeChange: (current, next) => {
                handleItmChange(next);
            },
        };
        if (customPaging) {
            carouselSettings.customPaging = function (i) {
                // eslint-disable-next-line
                return <a>{children[i]}</a>;
            };
        }

        setSettings(carouselSettings);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleItmChange(index) {
        if (handleChange != null) handleChange(index);
        setActive(index);
    }
    function handleItmClick(index) {
        if (handleClick) handleClick(index);
        setActive(index);
    }

    // ========================= Render Function =================================
    if (settings != null)
        return (
            <Div
                wd={arrows ? "calc(100% - 30px)" : "100%"}
                center-aligned
                centered
            >
                <Slider
                    key={`slider-${sliderId}`}
                    ref={setSliderRef}
                    {...settings}
                >
                    {children.map((content, index) => {
                        return (
                            <div
                                key={`slideItm-${sliderId}-${index}`}
                                onClick={() => {
                                    handleItmClick(index);
                                }}
                                className={
                                    active === index
                                        ? "slideItm active"
                                        : "slideItm"
                                }
                            >
                                {content}
                            </div>
                        );
                    })}
                </Slider>
                {enableButtons && (
                    <Div centered small clearfix rounded snug relative>
                        <Icon
                            relative
                            float-left
                            circular
                            padded
                            inverted
                            leftRounded
                            layer={2}
                            name="chevron left"
                            clickable={active !== 0}
                            primary={active !== 0}
                            onClick={sliderRef?.slickPrev}
                            disabled={active === 0}
                            className={active === 0 ? "disabled" : ""}
                        />
                        <Div absolute wd={"calc(100% - 20px)"} peat padded spaced />
                        <Icon
                            relative
                            float-right
                            rightRounded
                            circular
                            padded
                            inverted
                            layer={2}
                            name="chevron right"
                            clickable={
                                active <=
                                children.length - parseInt(slidesToShow, 0)
                            }
                            primary={
                                active <=
                                children.length - 1 - parseInt(slidesToShow, 0)
                            }
                            className={
                                active >
                                children.length - 1 - parseInt(slidesToShow, 0)
                                    ? "disabled"
                                    : ""
                            }
                            disabled={
                                active >
                                children.length - 1 - parseInt(slidesToShow, 0)
                            }
                            onClick={sliderRef?.slickNext}
                        />
                    </Div>
                )}
            </Div>
        );
    else return <div />;
}
