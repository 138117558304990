// Import from NPM
// -------------------------------------
import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

// Import from Config
// -------------------------------------
import { GameSchema } from "@schemas/gameSchema.react";

import Carousel from "@components/Carousel.react";
import { primaryStyle, darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";
import "@styles/components.scss";

// Import Components
// -------------------------------------
import { Div, Image, Icon } from "@components/Generics.react";

export default function GameInstructions(props) {
    const { deck, closeScreens } = props;
    const { t } = useTranslation("common");

    const gameHash = _.find(GameSchema, {
        folder: deck.quo_game,
    });

    return (
        <Div
            fluid
            fullht
            maxWd="400px"
            zoom
            centered
            padTop
            relative
            style={darkStyle(settings.colors.main, "to top right")}
        >
            <br />
            <Div
                absolute
                clickable
                huge
                onClick={closeScreens}
                style={{ top: "10px", right: "10px" }}
            >
                <Icon name="close" />
            </Div>
            <Div
                fit-content
                large
                centered
                relaxed
                circled
                clickable
                trench
                uppercase
                style={primaryStyle(deck?.colors)}
            >
                {t("deck.scoreboard.instructions")}
            </Div>
            <Div superpad>
                <Carousel slidesToShow={1} enableButtons>
                    {_.map(gameHash.instructions, (instruction, idx) => {
                        return (
                            <Div
                                key={`list-item-${idx}`}
                                left-aligned
                                superpad
                                smoke
                                rounded
                            >
                                <Div bold big gutter>
                                    {instruction.header}
                                </Div>
                                <Image
                                    src={`/assets/games/instructions/${deck.quo_game}/${instruction.image}`}
                                    alt={`Instruction Page ${idx + 1}`}
                                />
                                <Div padTop>{instruction.text}</Div>
                            </Div>
                        );
                    })}
                </Carousel>
            </Div>
        </Div>
    );
}
