import React, { useState } from "react";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import { Input, Button } from "semantic-ui-react";
import "@styles/components.scss";

export default function FilterField(props) {
    const { srcData, setResult, searchKey } = props;

    const [searchText, setSearchText] = useState("");
    function handleSearch(e) {
        setSearchText(e.target.value);
        if (e.target.value === "") setResult(srcData);
        else
            setResult(
                _.filter(srcData, (elm) =>
                    elm[searchKey]
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
    }
    function clearSearch(e) {
        setSearchText("");
        setResult(srcData);
    }
    // ========================= Render Function =================================
    return (
        <Div className="search-bar">
            <Input
                placeholder="Search..."
                labelPosition="right"
                onChange={handleSearch}
                value={searchText}
                className="search-input"
            />
            <Button
                icon={searchText === "" ? "search" : "close"}
                className="search-action"
                onClick={clearSearch}
            ></Button>
        </Div>
    );
}
