import React, { useState, useRef } from "react";
import _ from "lodash";
import { Modal } from "semantic-ui-react";
import AvatarEditor from "react-avatar-editor";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import shortid from "shortid";
import UploadField from "@components/FileBase64.react";
import { Button } from "@components/Generics.react";

export default function ImageUploader(props) {
    const {
        imageId,
        modalOpen,
        handleModalClose,
        handleModalSave,
        value,
        aspectRatio,
        imageLibrary,
        language,
        updateDeck,
    } = props;

    const [zoom, setZoom] = useState(10);
    const [imageFile, setImageFile] = useState("");
    const [isGif, setIsGif] = useState(false);
    const [isPng, setIsPng] = useState(false);
    const editorRef = useRef(null);

    const isPortrait = window.innerWidth < window.innerHeight;

    const getImage = (img) => {
        setImageFile(img);
        setIsGif(img.type === "image/gif");
        setIsPng(img.type === "image/png");
    };

    const convertGif = () => {
        return imageFile.base64;
    };

    const convertNonGif = () => {
        if (isPng) {
            return editorRef.current
                .getImageScaledToCanvas()
                .toDataURL("image/png");
        } else {
            return editorRef.current
                .getImageScaledToCanvas()
                .toDataURL("image/jpeg", 0.8);
        }
    };

    const resolveImage = () => {
        let editedImage = isGif ? convertGif() : convertNonGif();
        let extension = isGif ? ".gif" : isPng ? ".png" : ".jpeg";
        let imageLibraryCopy = [...imageLibrary];
        let imageIdCopy = imageId;

        if (
            imageIdCopy === "default11" ||
            imageIdCopy === "default43" ||
            imageIdCopy === "default169" ||
            imageIdCopy === "default133" ||
            _.find(imageLibraryCopy, {
                id: imageIdCopy,
                lang: language,
            }) === undefined
        ) {
            imageIdCopy = shortid.generate();
            imageLibraryCopy.push({
                id: imageIdCopy,
                lang: language,
                image: editedImage,
            });
        } else {
            _.find(imageLibraryCopy, {
                id: imageIdCopy,
                lang: language,
            }).image = editedImage;
        }

        return {
            newImageId: imageIdCopy,
            updatedLibrary: imageLibraryCopy,
            extension: extension,
        };
    };

    const saveImage = () => {
        let newData = resolveImage();
        updateDeck({ images: newData.updatedLibrary });
        handleModalSave({
            id: newData.newImageId,
            extension: newData.extension,
        });
    };

    const saveBackground = () => {
        let newImage = isGif ? convertGif() : convertNonGif();
        handleModalSave(newImage);
    };

    const handleZoom = (value) => {
        setZoom(value);
    };

    const previewArea = () => {
        let srcFile = imageFile === "" ? value : imageFile.base64;
        if (isGif) {
            return (
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "auto",
                    }}
                >
                    <img
                        src={srcFile}
                        style={{ width: "100%", height: "auto" }}
                        alt="Animated Gif"
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <div style={{ background: "#eee", width: "100%" }}>
                        <AvatarEditor
                            ref={editorRef}
                            image={srcFile}
                            width={aspectRatio < 1.3 ? 320 : 256}
                            height={
                                aspectRatio < 1.3
                                    ? 320 * aspectRatio
                                    : 256 * aspectRatio
                            }
                            border={0}
                            color={[255, 255, 255, 0.6]}
                            scale={zoom / 10}
                            rotate={0}
                        />
                    </div>
                    <div className="slider" style={{ width: "100%" }}>
                        <Slider
                            min={0}
                            max={100}
                            value={zoom}
                            onChange={handleZoom}
                        />
                    </div>
                </div>
            );
        }
    };

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
            style={{
                width: "fit-content",
                marginTop: "5%",
                transform: isPortrait ? "scale(0.75)" : "",
            }}
        >
            <Modal.Header style={{ border: "none", position: "relative" }}>
                Upload Image
                <Button
                    negative
                    icon="close"
                    style={{
                        position: "absolute",
                        top: "0",
                        right: "-0.25em",
                        padding: "10px",
                        lineHeight: "1",
                        cursor: "pointer",
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                    }}
                    onClick={handleModalClose}
                />
            </Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        background: "#eee",
                        width: "100%",
                        padding: "20px",
                        marginBottom: "20px",
                    }}
                >
                    <UploadField
                        content={"Upload Image"}
                        icon={"image"}
                        multiple={false}
                        onDone={getImage}
                        accept="image/*"
                    />
                </div>
                {previewArea()}
            </Modal.Content>
            <Modal.Actions style={{ height: "64px", background: "#efefef" }}>
                <Button
                    negative
                    onClick={handleModalClose}
                    content="Cancel"
                    icon="delete"
                    labelPosition="left"
                    style={{ float: "left" }}
                />
                <Button
                    content="Save"
                    icon="checkmark"
                    labelPosition="right"
                    onClick={imageId === undefined ? saveBackground : saveImage}
                    style={{ float: "right" }}
                />
            </Modal.Actions>
        </Modal>
    );
}
