// Import from NPM
// -------------------------------------
import React from "react";
import { useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import _ from "lodash";
import { Div } from "@components/Generics.react";
import "@styles/navbars.scss";

import { useTranslation } from "react-i18next";
import { settings } from "@config/settings/app.settings";

export default function QuickLinks(props) {
    const { vertical = false } = props;
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation("common");

    // ========================= Render Function =================================
    return (
        <Div className={`quick-menu ${vertical && "vertical"}`}>
            {_.map(
                _.filter(
                    settings.menu.quick,
                    (tLink) =>
                        tLink.hideInStage == null ||
                        !tLink.hideInStage.includes(user.stage.toString())
                ),
                (quicklink, index) => {
                    return (
                        <NavLink
                            key={`quick-menu-link-${index}`}
                            to={quicklink.link}
                            className="quick-link"
                        >
                            <img
                                src={t(`sidebar.quick.${quicklink.transCode}`)}
                                alt={quicklink.transCode}
                            />
                        </NavLink>
                    );
                }
            )}
        </Div>
    );
}
