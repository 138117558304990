// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
    useNavigate,
    useLocation,
    useSearchParams,
    NavLink,
} from "react-router-dom";
import { Button } from "@components/Generics.react";
import { Input, Loader, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

// Import from Config
// -------------------------------------
import { baseUrl } from "@api/apiV6";
import AuthLayout from "@layouts/AuthLayout.react";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------
import { login } from "@reducers/authSlice";

export default function Login(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const { state } = useLocation();

    const [search] = useSearchParams();
    const [token, setToken] = useState(search.get("token"));
    const omniauthData = settings.omniauth;

    const shouldRenderOmniauthButton =
        omniauthData &&
        omniauthData.length > 0 &&
        omniauthData[0].url &&
        omniauthData[0].text;

    const tryLogin = async () => {
        try {
            let formData = new FormData();
            formData.append("username", username);
            formData.append("password", password);
            const response = await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/sign_in`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            await dispatch(
                login({
                    user: response.data,
                    token: response.headers["access-token"],
                })
            );
            navigate(state?.from?.pathname || "/");
        } catch (error) {
            console.log(error);
            setError(error.response?.data?.message);
        }
    };

    const tokenLogin = async () => {
        try {
            const response = await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/sign_in`,
                headers: { authtoken: token },
            });
            await dispatch(
                login({
                    user: response.data,
                    token: response.headers["access-token"],
                })
            );
            navigate(state?.from?.pathname || "/");
        } catch (error) {
            setError("Invalid Token Provided!");
            setToken(null);
        }
    };

    const handleOmniauthClick = (url) => {
        window.location.href = url;
    };

    useEffect(() => {
        (async () => {
            if (search && search.get("token") != null) await tokenLogin();
            else setToken(null);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    // ========================= Render Function =================================
    if (token != null) return <Loader active>Logging in...</Loader>;
    else
        return (
            <AuthLayout title={`Login to ${t("appName")}`}>
                <Div fluid white fullHt>
                    <Div massive altText fluid center-align gutter>
                        SIGN IN
                    </Div>
                    {error && (
                        <Div padded fluid danger gutter>
                            {error}
                        </Div>
                    )}
                    <Div fluid gutter>
                        <Input
                            fluid
                            label="Username"
                            placeholder="Input Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <br />
                        <Input
                            fluid
                            label="Password"
                            type="password"
                            placeholder="Input Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <br />
                        <Button
                            fluid
                            primary
                            content="SUBMIT"
                            onClick={tryLogin}
                        />
                        <br />
                        {settings.functionality.smtpActive && (
                            <NavLink to="/forgot">{t("auth.forgot")}</NavLink>
                        )}
                        {settings.functionality.registrationActive && (
                            <>
                                <Divider horizontal>OR</Divider>
                                <NavLink to="/register">
                                    <Button
                                        success={+true}
                                        content={t("auth.register")}
                                    />
                                </NavLink>
                            </>
                        )}
                        {shouldRenderOmniauthButton && <Divider />}
                        {shouldRenderOmniauthButton && (
                            <Button
                                fluid
                                icon
                                labelPosition="left"
                                onClick={() =>
                                    handleOmniauthClick(omniauthData[0].url)
                                }
                            >
                                <i
                                    className={`icon ${
                                        omniauthData[0].icon || "users"
                                    }`}
                                />
                                {omniauthData[0].text}
                            </Button>
                        )}
                    </Div>
                </Div>
            </AuthLayout>
        );
}
