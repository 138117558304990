import React from "react";
import { Div, Image } from "@components/Generics.react";
import { clientAssetsPath } from "@api/apiV6";

export default function ImageCaption(props) {
    const { caption, image } = props.data;

    return (
        <Div fluid zoom>
            <Image fluid src={`${clientAssetsPath}/uploads/qdf/slides/${image.id}${image.extension}`} />
            {caption && (
                <Div superpad ash big>
                    {caption}
                </Div>
            )}
        </Div>
    );
}
