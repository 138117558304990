import React from "react";
import { Div, Button } from "@components/Generics.react";
export default function InteralLink(props) {
    const { paragraph, slideLink } = props.data;
    return (
        <Div superpad big>
            {paragraph}
            <br />
            <br />
            <a href={slideLink} target="_blank" rel="noopener noreferrer">
                <Button primary fluid size="huge">
                    Go
                </Button>
            </a>
        </Div>
    );
}
