// import ArrangedCards from "mobile/course/wrappers/formals/ArrangedCards.react";
// import FeedCards from "mobile/course/wrappers/formals/FeedCards.react";
// import SimpleListing from "mobile/course/wrappers/formals/SimpleListing.react";
// import StackedImages from "mobile/course/wrappers/formals/StackedImages.react";
// import TinderCards from "mobile/course/wrappers/formals/TinderCards.react";

export const WrapperList = {
    // none: {
    //     constructWrapper: false,
    //     template: SimpleListing,
    //     positions: [],
    // },
    // arranged_cards: {
    //     constructWrapper: false,
    //     template: ArrangedCards,
    //     positions: [],
    // },
    // tinder_cards: {
    //     constructWrapper: false,
    //     template: TinderCards,
    //     positions: [],
    // },
    // stacked_images: {
    //     constructWrapper: false,
    //     template: StackedImages,
    //     positions: [],
    // },
    // feed_cards: {
    //     constructWrapper: false,
    //     template: FeedCards,
    //     positions: [],
    // },
    world_map: {
        constructWrapper: true,
        positions: [
            {
                sequence: 0,
            },
            {
                sequence: 1,
            },
            {
                sequence: 2,
            },
            {
                sequence: 3,
            },
            {
                sequence: 4,
            },
            {
                sequence: 5,
            },
            {
                sequence: 6,
            },
            {
                sequence: 7,
            },
            {
                sequence: 8,
            },
            {
                sequence: 9,
            },
            {
                sequence: 10,
            },
            {
                sequence: 11,
            },
        ],
    },
    film_studio: {
        constructWrapper: true,
        positions: [
            {
                sequence: 0,
            },
            {
                sequence: 1,
            },
            {
                sequence: 2,
            },
            {
                sequence: 3,
            },
            {
                sequence: 4,
            },
            {
                sequence: 5,
            },
            {
                sequence: 6,
            },
            {
                sequence: 7,
            },
            {
                sequence: 8,
            },
            {
                sequence: 9,
            },
            {
                sequence: 10,
            },
            {
                sequence: 11,
            },
        ],
    },
    europe_map: {
        key: "europe_map",
        constructWrapper: true,
        category: "animated",
        name: "Europe Map",
        inductorsTheme: true,
        positions: [
            {
                sequence: 0,
            },
            {
                sequence: 1,
            },
            {
                sequence: 2,
            },
            {
                sequence: 3,
            },
            {
                sequence: 4,
            },
            {
                sequence: 5,
            },
            {
                sequence: 6,
            },
            {
                sequence: 7,
            },
            {
                sequence: 8,
            },
            {
                sequence: 9,
            },
            {
                sequence: 10,
            },
            {
                sequence: 11,
            },
        ],
        label: "Special offer!",
        tag: "PRO",
        toolTip: true,
        toolTipText:
            "This is a great map to build a country journey with. Ideal for sales, global organizations, tourism or just for a country adeventure...",
    },
    india_map: {
        key: "india_map",
        constructWrapper: true,
        category: "animated",
        name: "India Map",
        inductorsTheme: true,
        positions: [
            {
                sequence: 0,
            },
            {
                sequence: 1,
            },
            {
                sequence: 2,
            },
            {
                sequence: 3,
            },
            {
                sequence: 4,
            },
            {
                sequence: 5,
            },
            {
                sequence: 6,
            },
            {
                sequence: 7,
            },
            {
                sequence: 8,
            },
            {
                sequence: 9,
            },
            {
                sequence: 10,
            },
            {
                sequence: 11,
            },
        ],
        label: "Special offer!",
        tag: "PRO",
        toolTip: true,
        toolTipText:
            "This is a great map to build a country journey with. Ideal for sales, global organizations, tourism or just for a country adeventure...",
    },
    usa_map: {
        key: "usa_map",
        constructWrapper: true,
        category: "animated",
        name: "USA Map",
        inductorsTheme: true,
        positions: [
            {
                sequence: 0,
            },
            {
                sequence: 1,
            },
            {
                sequence: 2,
            },
            {
                sequence: 3,
            },
            {
                sequence: 4,
            },
            {
                sequence: 5,
            },
            {
                sequence: 6,
            },
            {
                sequence: 7,
            },
            {
                sequence: 8,
            },
            {
                sequence: 9,
            },
            {
                sequence: 10,
            },
            {
                sequence: 11,
            },
        ],
        label: "Special offer!",
        tag: "PRO",
        toolTip: true,
        toolTipText:
            "This is a great map to build a country journey with. Ideal for sales, global organizations, tourism or just for a country adeventure...",
    },
    uk_map: {
        key: "uk_map",
        constructWrapper: true,
        category: "animated",
        name: "UK Map",
        inductorsTheme: true,
        positions: [
            {
                sequence: 0,
            },
            {
                sequence: 1,
            },
            {
                sequence: 2,
            },
            {
                sequence: 3,
            },
            {
                sequence: 4,
            },
            {
                sequence: 5,
            },
            {
                sequence: 6,
            },
            {
                sequence: 7,
            },
            {
                sequence: 8,
            },
            {
                sequence: 9,
            },
            {
                sequence: 10,
            },
            {
                sequence: 11,
            },
        ],
        label: "Special offer!",
        tag: "PRO",
        toolTip: true,
        toolTipText:
            "This is a great map to build a country journey with. Ideal for sales, global organizations, tourism or just for a country adeventure...",
    },
    australia_map: {
        key: "australia_map",
        constructWrapper: true,
        category: "animated",
        name: "Australia Map",
        inductorsTheme: true,
        positions: [
            {
                sequence: 0,
            },
            {
                sequence: 1,
            },
            {
                sequence: 2,
            },
            {
                sequence: 3,
            },
            {
                sequence: 4,
            },
            {
                sequence: 5,
            },
            {
                sequence: 6,
            },
            {
                sequence: 7,
            },
            {
                sequence: 8,
            },
            {
                sequence: 9,
            },
            {
                sequence: 10,
            },
            {
                sequence: 11,
            },
        ],
        label: "Special offer!",
        tag: "PRO",
        toolTip: true,
        toolTipText:
            "This is a great map to build a country journey with. Ideal for sales, global organizations, tourism or just for a country adeventure...",
    },
    academy: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: false,
        zoomable: false,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "70",
                top: "71",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "46",
                top: "71",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "20",
                top: "71",
                width: "9.6",
                height: "10",
            },
            {
                sequence: 3,
                left: "22",
                top: "60",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "68",
                top: "60",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "46",
                top: "60",
                width: "9.6",
                height: "10",
            },
            {
                sequence: 6,
                left: "25",
                top: "50",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "46",
                top: "50",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "66",
                top: "50",
                width: "9.6",
                height: "10",
            },
            {
                sequence: 9,
                left: "27",
                top: "30",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "66",
                top: "30",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "47",
                top: "30",
                width: "9.6",
                height: "10",
            },
        ],
    },
    medieval: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "surf",
                top: "82",
                left: "79",
                width: "8",
                opacity: 0.5,
            },
            {
                name: "surf",
                top: "90",
                left: "27",
                width: "6",
                rotate: 45,
            },
            {
                name: "boat",
                top: "79",
                left: "0",
                width: "16",
            },
            {
                name: "smoke",
                top: "-18",
                left: "72.5",
                width: "17",
                opacity: 0.3,
            },
            {
                name: "smoke",
                top: "58",
                left: "55",
                width: "8",
                opacity: 0.5,
            },
            {
                name: "smoke",
                top: "-20",
                left: "11.5",
                width: "17",
                opacity: 0.5,
            },
            {
                name: "horse",
                top: "43",
                left: "82",
                width: "6",
            },
            {
                name: "ripples",
                top: "86",
                left: "83",
                width: "20",
                opacity: 0.3,
                rotate: "-60",
            },
        ],
        positions: [
            { sequence: 0, top: 86, left: 8 },
            { sequence: 1, top: 70, left: 33 },
            { sequence: 2, top: 83, left: 40 },
            { sequence: 3, top: 77, left: 69 },
            { sequence: 4, top: 51, left: 92 },
            { sequence: 5, top: 58, left: 62 },
            { sequence: 6, top: 31, left: 79 },
            { sequence: 7, top: 9, left: 52 },
            { sequence: 8, top: 30, left: 19 },
            { sequence: 9, top: 55, left: 44 },
            { sequence: 10, top: 48, left: 33 },
            { sequence: 11, top: 20, left: 43 },
        ],
    },
    cityscape: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "85",
                top: "90",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "58",
                top: "79",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "7",
                top: "87",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "16",
                top: "62",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "45",
                top: "64",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "70",
                top: "57",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "36",
                top: "36",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "88",
                top: "21",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "4",
                top: "34",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "60",
                top: "33",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "60",
                top: "12",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "34",
                top: "15",
                width: "9.6",
                height: "0",
            },
        ],
    },
    carnival: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            { sequence: 0, top: 60, left: 8 },
            { sequence: 1, top: 70, left: 25 },
            { sequence: 2, top: 68, left: 40 },
            { sequence: 3, top: 71, left: 70 },
            { sequence: 4, top: 56, left: 80 },
            { sequence: 5, top: 41, left: 81 },
            { sequence: 6, top: 18, left: 55 },
            { sequence: 7, top: 14, left: 44 },
            { sequence: 8, top: 36, left: 53 },
            { sequence: 9, top: 43, left: 33 },
            { sequence: 10, top: 41, left: 11 },
            { sequence: 11, top: 19, left: 15 },
        ],
    },
    suburbs: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "78",
                top: "92",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "53",
                top: "81",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "15",
                top: "75",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "87.9",
                top: "57",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "30",
                top: "60",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "58",
                top: "48",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "10",
                top: "45",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "80",
                top: "19",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "46",
                top: "29",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "28",
                top: "36",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "38",
                top: "13",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "7",
                top: "16",
                width: "9.6",
                height: "0",
            },
        ],
    },
    tower: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                left: "0",
                top: "95",
                width: "44",
                height: "0",
                sequence: 0,
            },
            {
                left: "45",
                top: "82",
                width: "44",
                height: "0",
                sequence: 1,
            },
            {
                left: "25",
                top: "75",
                width: "25",
                height: "0",
                sequence: 2,
            },
            {
                left: "59",
                top: "66",
                width: "19",
                height: "0",
                sequence: 3,
            },
            {
                left: "16",
                top: "59",
                width: "44",
                height: "0",
                sequence: 4,
            },
            {
                left: "59",
                top: "51",
                width: "21",
                height: "0",
                sequence: 5,
            },
            {
                left: "27",
                top: "42",
                width: "23",
                height: "0",
                sequence: 6,
            },
            {
                left: "48",
                top: "33",
                width: "43",
                height: "0",
                sequence: 7,
            },
            {
                left: "30",
                top: "26",
                width: "17",
                height: "0",
                sequence: 8,
            },
            {
                left: "57",
                top: "21",
                width: "27",
                height: "0",
                sequence: 9,
            },
            {
                left: "0",
                top: "16",
                width: "43.7",
                height: "0",
                sequence: 10,
            },
            {
                left: "40",
                top: "12",
                width: "21",
                height: "0",
                sequence: 11,
            },
        ],
    },
    office: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                left: "75",
                top: "90",
                width: "23",
                sequence: 0,
            },
            {
                left: "33",
                top: "80",
                width: "21",
                sequence: 1,
            },
            {
                left: "10",
                top: "95",
                width: "30",
                sequence: 2,
            },
            {
                left: "-5",
                top: "74",
                width: "34",
                sequence: 3,
            },
            {
                left: "85",
                top: "65",
                width: "17",
                sequence: 4,
            },
            {
                left: "35",
                top: "55",
                width: "32",
                sequence: 5,
            },
            {
                left: "-12",
                top: "43",
                width: "41",
                sequence: 6,
            },
            {
                left: "78",
                top: "40",
                width: "23",
                sequence: 7,
            },
            {
                left: "61",
                top: "25",
                width: "26",
                sequence: 8,
            },
            {
                left: "15",
                top: "30",
                width: "16",
                sequence: 9,
            },
            {
                left: "31",
                top: "26",
                width: "16",
                sequence: 10,
            },
            {
                left: "53",
                top: "13",
                width: "15",
                height: "100",
                sequence: 11,
            },
        ],
    },
    supermarket: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "83",
                top: "85",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "50",
                top: "90",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "11",
                top: "80",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "80",
                top: "48",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "42",
                top: "52",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "86",
                top: "22",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "14",
                top: "48",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "14",
                top: "28",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "64",
                top: "20",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "55",
                top: "35",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "32",
                top: "18",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "53",
                top: "11",
                width: "9.6",
                height: "0",
            },
        ],
    },
    atlantis: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "92",
                left: "65",
                width: "10",
                sequence: "0",
            },
            {
                top: "97",
                left: "15",
                width: "10",
                sequence: "1",
            },
            {
                top: "84",
                left: "31",
                width: "10",
                sequence: "2",
            },
            {
                top: "70",
                left: "59",
                width: "10",
                sequence: "3",
            },
            {
                top: "58",
                left: "0",
                width: "10",
                sequence: "4",
            },
            {
                top: "65",
                left: "30",
                width: "10",
                sequence: "5",
            },
            {
                top: "50",
                left: "45",
                width: "10",
                sequence: "6",
            },
            {
                top: "30",
                left: "10",
                width: "10",
                sequence: "7",
            },
            {
                top: "31",
                left: "50",
                width: "10",
                sequence: "8",
            },
            {
                top: "12",
                left: "48",
                width: "10",
                sequence: "9",
            },
            {
                top: "15",
                left: "25",
                width: "10",
                sequence: "10",
            },
            {
                top: "12",
                left: "76",
                width: "10",
                height: "100",
                sequence: "11",
            },
        ],
    },
    egypt: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "93",
                left: "67",
                width: "40",
                sequence: 0,
            },
            {
                top: "83",
                left: "22",
                width: "45",
                sequence: 1,
            },
            {
                top: "92",
                left: "12",
                width: "40",
                sequence: 2,
            },
            {
                top: "79",
                left: "-9",
                width: "48",
                sequence: 3,
            },
            {
                top: "75",
                left: "70",
                width: "40",
                sequence: 4,
            },
            {
                top: "65",
                left: "44",
                width: "35",
                sequence: 5,
            },
            {
                top: "60",
                left: "2",
                width: "37",
                sequence: 6,
            },
            {
                top: "38",
                left: "-8",
                width: "36",
                sequence: 7,
            },
            {
                top: "45",
                left: "32",
                width: "35",
                sequence: 8,
            },
            {
                top: "60",
                left: "70",
                width: "40",
                sequence: 9,
            },
            {
                top: "15",
                left: "10",
                width: "50",
                sequence: 10,
            },
            {
                top: "38",
                left: "50",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    island_advanture: {
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "port_of_ithaca1",
                top: "81",
                left: "42",
                width: "15",
                height: "7",
            },
            {
                name: "port_of_ithaca2",
                top: "81",
                left: "2",
                width: "15",
                height: "7",
            },
        ],
        positions: [
            {
                top: "90",
                left: "54",
                width: "40",
                sequence: 0,
            },
            {
                top: "85",
                left: "25",
                width: "45",
                sequence: 1,
            },
            {
                top: "77",
                left: "4",
                width: "40",
                sequence: 2,
            },
            {
                top: "61",
                left: "26",
                width: "48",
                sequence: 3,
            },
            {
                top: "63",
                left: "56",
                width: "40",
                sequence: 4,
            },
            {
                top: "44",
                left: "52",
                width: "35",
                sequence: 5,
            },
            {
                top: "41",
                left: "-4",
                width: "37",
                sequence: 6,
            },
            {
                top: "30",
                left: "26",
                width: "36",
                sequence: 7,
            },
            {
                top: "25",
                left: "4",
                width: "35",
                sequence: 8,
            },
            {
                top: "14",
                left: "9",
                width: "40",
                sequence: 9,
            },
            {
                top: "17",
                left: "32",
                width: "50",
                sequence: 10,
            },
            {
                top: "31",
                left: "46",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    underworld: {
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "underworld2",
                top: "12",
                left: "74",
                width: "29",
                height: "26",
            },
            {
                name: "underworld1",
                top: "6",
                left: "23",
                width: "39",
                height: "26",
            },
            {
                name: "underworld3",
                top: "41",
                left: "13",
                width: "42",
                height: "28",
            },
        ],
        positions: [
            {
                top: "94",
                left: "-8",
                width: "40",
                sequence: 0,
            },
            {
                top: "72",
                left: "-14",
                width: "45",
                sequence: 1,
            },
            {
                top: "56",
                left: "6",
                width: "40",
                sequence: 2,
            },
            {
                top: "60",
                left: "38",
                width: "48",
                sequence: 3,
            },
            {
                top: "44",
                left: "42",
                width: "40",
                sequence: 4,
            },
            {
                top: "32",
                left: "9",
                width: "40",
                sequence: 5,
            },
            {
                top: "37",
                left: "51",
                width: "37",
                sequence: 6,
            },
            {
                top: "42",
                left: "73",
                width: "36",
                sequence: 7,
            },
            {
                top: "25",
                left: "68",
                width: "35",
                sequence: 8,
            },
            {
                top: "15",
                left: "26",
                width: "40",
                sequence: 9,
            },
            {
                top: "12",
                left: "-7",
                width: "50",
                sequence: 10,
            },
            {
                top: "21",
                left: "-22",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    treasure_hunt: {
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "warrior_task1",
                top: "-1",
                left: "20",
                width: "23",
            },
            {
                name: "warrior_task2",
                top: "44",
                left: "-1",
                width: "30",
            },
            {
                name: "warrior_task3",
                top: "51",
                left: "56",
                width: "16",
            },
        ],
        positions: [
            {
                top: "95",
                left: "-12",
                width: "40",
                sequence: 0,
            },
            {
                top: "64",
                left: "-10",
                width: "45",
                sequence: 1,
            },
            {
                top: "49",
                left: "2",
                width: "40",
                sequence: 2,
            },
            {
                top: "25",
                left: "0",
                width: "48",
                sequence: 3,
            },
            {
                top: "14",
                left: "-10",
                width: "40",
                sequence: 4,
            },
            {
                top: "10",
                left: "31",
                width: "35",
                sequence: 5,
            },
            {
                top: "15",
                left: "75",
                width: "37",
                sequence: 6,
            },
            {
                top: "25",
                left: "59",
                width: "36",
                sequence: 7,
            },
            {
                top: "36",
                left: "33",
                width: "35",
                sequence: 8,
            },
            {
                top: "53",
                left: "61",
                width: "40",
                sequence: 9,
            },
            {
                top: "74",
                left: "17",
                width: "50",
                sequence: 10,
            },
            {
                top: "90",
                left: "52",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    mountain_adventure: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "97",
                left: "54",
                width: "18",
                sequence: 0,
            },
            {
                top: "93",
                left: "30",
                width: "13",
                sequence: 1,
            },
            {
                top: "86",
                left: "8",
                width: "13",
                sequence: 2,
            },
            {
                top: "82",
                left: "32",
                width: "13",
                sequence: 3,
            },
            {
                top: "72",
                left: "44",
                width: "12",
                sequence: 4,
            },
            {
                top: "65",
                left: "20",
                width: "13",
                sequence: 5,
            },
            {
                top: "64",
                left: "65",
                width: "13",
                sequence: 6,
            },
            {
                top: "57",
                left: "54",
                width: "13",
                sequence: 7,
            },
            {
                top: "48",
                left: "36",
                width: "13",
                sequence: 8,
            },
            {
                top: "42",
                left: "70",
                width: "13",
                sequence: 9,
            },
            {
                top: "40",
                left: "56",
                width: "13",
                sequence: 10,
            },
            {
                top: "22",
                left: "55",
                width: "7",
                height: "100",
                sequence: 11,
            },
        ],
    },
    arena: {
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [
            {
                name: "arena-1",
                top: "60",
                left: "42",
                width: "21",
                height: "13",
            },
            {
                name: "arena-2",
                top: "0",
                left: "57",
                width: "16",
                height: "10",
            },
            {
                name: "arena-3",
                top: "28",
                left: "29",
                width: "16",
                height: "10",
            },
        ],
        positions: [
            {
                top: "78",
                left: "58",
                width: "40",
                sequence: 0,
            },
            {
                top: "68",
                left: "40",
                width: "45",
                sequence: 1,
            },
            {
                top: "70",
                left: "9",
                width: "40",
                sequence: 2,
            },
            {
                top: "42",
                left: "27",
                width: "48",
                sequence: 3,
            },
            {
                top: "35",
                left: "63",
                width: "40",
                sequence: 4,
            },
            {
                top: "14",
                left: "60",
                width: "35",
                sequence: 5,
            },
            {
                top: "11",
                left: "43",
                width: "37",
                sequence: 6,
            },
            {
                top: "16",
                left: "24",
                width: "36",
                sequence: 7,
            },
            {
                top: "23",
                left: "39",
                width: "35",
                sequence: 8,
            },
            {
                top: "30",
                left: "17",
                width: "40",
                sequence: 9,
            },
            {
                top: "46",
                left: "-6",
                width: "50",
                sequence: 10,
            },
            {
                top: "53",
                left: "1",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    startup: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                left: "58",
                top: "82",
                width: "23",
                sequence: 0,
            },
            {
                left: "33",
                top: "80",
                width: "21",
                sequence: 1,
            },
            {
                left: "10",
                top: "65",
                width: "30",
                sequence: 2,
            },
            {
                left: "39",
                top: "62",
                width: "34",
                sequence: 3,
            },
            {
                left: "82",
                top: "68",
                width: "17",
                sequence: 4,
            },
            {
                left: "59",
                top: "54",
                width: "32",
                sequence: 5,
            },
            {
                left: "30",
                top: "44",
                width: "41",
                sequence: 6,
            },
            {
                left: "6",
                top: "55",
                width: "23",
                sequence: 7,
            },
            {
                left: "18",
                top: "42",
                width: "26",
                sequence: 8,
            },
            {
                left: "79",
                top: "40",
                width: "16",
                sequence: 9,
            },
            {
                left: "60",
                top: "30",
                width: "16",
                sequence: 10,
            },
            {
                left: "15",
                top: "14",
                width: "15",
                height: "100",
                sequence: 11,
            },
        ],
    },
    apartments: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "94",
                left: "72",
                width: "40",
                sequence: 0,
            },
            {
                top: "79",
                left: "70",
                width: "45",
                sequence: 1,
            },
            {
                top: "88",
                left: "41",
                width: "40",
                sequence: 2,
            },
            {
                top: "56",
                left: "65",
                width: "48",
                sequence: 3,
            },
            {
                top: "69",
                left: "46",
                width: "40",
                sequence: 4,
            },
            {
                top: "69",
                left: "16",
                width: "35",
                sequence: 5,
            },
            {
                top: "78",
                right: "69",
                width: "37",
                sequence: 6,
            },
            {
                top: "29",
                left: "54",
                width: "36",
                sequence: 7,
            },
            {
                top: "39",
                left: "25",
                width: "35",
                sequence: 8,
            },
            {
                top: "58",
                right: "68",
                width: "40",
                sequence: 9,
            },
            {
                top: "13",
                left: "29",
                width: "50",
                sequence: 10,
            },
            {
                top: "30",
                right: "59",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    aquarium: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "85",
                left: "68",
                width: "40",
                sequence: 0,
            },
            {
                top: "86",
                left: "43",
                width: "45",
                sequence: 1,
            },
            {
                top: "44",
                left: "33",
                width: "40",
                sequence: 2,
            },
            {
                top: "34",
                left: "46",
                width: "48",
                sequence: 3,
            },
            {
                top: "35",
                left: "19",
                width: "40",
                sequence: 4,
            },
            {
                top: "27",
                left: "55",
                width: "35",
                sequence: 5,
            },
            {
                top: "10",
                left: "42",
                width: "37",
                sequence: 6,
            },
            {
                top: "14",
                left: "66",
                width: "36",
                sequence: 7,
            },
            {
                top: "18",
                left: "2",
                width: "35",
                sequence: 8,
            },
            {
                top: "60",
                left: "65",
                width: "40",
                sequence: 9,
            },
            {
                top: "67",
                left: "59",
                width: "50",
                sequence: 10,
            },
            {
                top: "95",
                right: "52",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    azteckingdom: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "83",
                left: "7",
                width: "40",
                sequence: 0,
            },
            {
                top: "78",
                left: "66",
                width: "45",
                sequence: 1,
            },
            {
                top: "69",
                left: "42",
                width: "40",
                sequence: 2,
            },
            {
                top: "49",
                left: "67",
                width: "48",
                sequence: 3,
            },
            {
                top: "57",
                right: "60",
                width: "40",
                sequence: 4,
            },
            {
                top: "31",
                left: "34",
                width: "35",
                sequence: 5,
            },
            {
                top: "33",
                right: "70",
                width: "37",
                sequence: 6,
            },
            {
                top: "17",
                left: "12",
                width: "36",
                sequence: 7,
            },
            {
                top: "11",
                left: "40",
                width: "35",
                sequence: 8,
            },
            {
                top: "13",
                left: "69",
                width: "40",
                sequence: 9,
            },
            {
                top: "48",
                left: "30",
                width: "50",
                sequence: 10,
            },
            {
                top: "25",
                right: "63",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    backallye: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "89",
                left: "23",
                width: "40",
                sequence: 0,
            },
            {
                top: "91",
                left: "6",
                width: "45",
                sequence: 1,
            },
            {
                top: "80",
                right: "69",
                width: "40",
                sequence: 2,
            },
            {
                top: "75",
                left: "58",
                width: "48",
                sequence: 3,
            },
            {
                top: "63",
                left: "70",
                width: "40",
                sequence: 4,
            },
            {
                top: "72",
                left: "32",
                width: "35",
                sequence: 5,
            },
            {
                top: "66",
                left: "25",
                width: "37",
                sequence: 6,
            },
            {
                top: "57",
                left: "38",
                width: "36",
                sequence: 7,
            },
            {
                top: "91",
                left: "52",
                width: "35",
                sequence: 8,
            },
            {
                top: "33",
                right: "64",
                width: "40",
                sequence: 9,
            },
            {
                top: "29",
                left: "26",
                width: "50",
                sequence: 10,
            },
            {
                top: "14",
                right: "46",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    bathhouse: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "83",
                left: "56",
                width: "40",
                sequence: 0,
            },
            {
                top: "71",
                left: "44",
                width: "45",
                sequence: 1,
            },
            {
                top: "59",
                left: "19",
                width: "40",
                sequence: 2,
            },
            {
                top: "46",
                left: "23",
                width: "48",
                sequence: 3,
            },
            {
                top: "36",
                left: "1",
                width: "40",
                sequence: 4,
            },
            {
                top: "53",
                left: "61",
                width: "35",
                sequence: 5,
            },
            {
                top: "42",
                left: "73",
                width: "37",
                sequence: 6,
            },
            {
                top: "87",
                left: "17",
                width: "36",
                sequence: 7,
            },
            {
                top: "74",
                left: "70",
                width: "35",
                sequence: 8,
            },
            {
                top: "22",
                left: "70",
                width: "40",
                sequence: 9,
            },
            {
                top: "11",
                left: "44",
                width: "50",
                sequence: 10,
            },
            {
                top: "9",
                left: "24",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    boathouse: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "95",
                left: "68",
                width: "40",
                sequence: 0,
            },
            {
                top: "72",
                left: "69",
                width: "45",
                sequence: 1,
            },
            {
                top: "53",
                left: "63",
                width: "40",
                sequence: 2,
            },
            {
                top: "75",
                left: "6",
                width: "48",
                sequence: 3,
            },
            {
                top: "49",
                left: "14",
                width: "40",
                sequence: 4,
            },
            {
                top: "43",
                right: "67",
                width: "35",
                sequence: 5,
            },
            {
                top: "35",
                left: "48",
                width: "37",
                sequence: 6,
            },
            {
                top: "29",
                left: "30",
                width: "36",
                sequence: 7,
            },
            {
                top: "19",
                left: "14",
                width: "35",
                sequence: 8,
            },
            {
                top: "24",
                left: "54",
                width: "40",
                sequence: 9,
            },
            {
                top: "11",
                left: "19",
                width: "50",
                sequence: 10,
            },
            {
                top: "11",
                left: "56",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    bookstore: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "85",
                left: "66",
                width: "40",
                sequence: 0,
            },
            {
                top: "87",
                right: "57",
                width: "45",
                sequence: 1,
            },
            {
                top: "74",
                left: "23",
                width: "40",
                sequence: 2,
            },
            {
                top: "66",
                left: "38",
                width: "48",
                sequence: 3,
            },
            {
                top: "57",
                left: "7",
                width: "40",
                sequence: 4,
            },
            {
                top: "45",
                left: "78",
                width: "35",
                sequence: 5,
            },
            {
                top: "43",
                right: "68",
                width: "37",
                sequence: 6,
            },
            {
                top: "28",
                right: "71",
                width: "36",
                sequence: 7,
            },
            {
                top: "24",
                left: "41",
                width: "35",
                sequence: 8,
            },
            {
                top: "23",
                left: "83",
                width: "40",
                sequence: 9,
            },
            {
                top: "11",
                left: "25",
                width: "50",
                sequence: 10,
            },
            {
                top: "57",
                left: "64",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    campsite: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "94",
                left: "32",
                width: "40",
                sequence: 0,
            },
            {
                top: "60",
                right: "67",
                width: "45",
                sequence: 1,
            },
            {
                top: "81",
                left: "52",
                width: "40",
                sequence: 2,
            },
            {
                top: "68",
                left: "30",
                width: "48",
                sequence: 3,
            },
            {
                top: "69",
                left: "70",
                width: "40",
                sequence: 4,
            },
            {
                top: "42",
                left: "59",
                width: "35",
                sequence: 5,
            },
            {
                top: "51",
                left: "73",
                width: "37",
                sequence: 6,
            },
            {
                top: "13",
                left: "73",
                width: "36",
                sequence: 7,
            },
            {
                top: "20",
                left: "43",
                width: "35",
                sequence: 8,
            },
            {
                top: "22",
                left: "22",
                width: "40",
                sequence: 9,
            },
            {
                top: "31",
                left: "8",
                width: "50",
                sequence: 10,
            },
            {
                top: "11",
                left: "21",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    asylum: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "90",
                left: "61",
                width: "40",
                sequence: 0,
            },
            {
                top: "78",
                left: "57",
                width: "45",
                sequence: 1,
            },
            {
                top: "81",
                left: "42",
                width: "40",
                sequence: 2,
            },
            {
                top: "93",
                left: "2",
                width: "48",
                sequence: 3,
            },
            {
                top: "83",
                right: "66",
                width: "40",
                sequence: 4,
            },
            {
                top: "63",
                right: "68",
                width: "35",
                sequence: 5,
            },
            {
                top: "48",
                left: "21",
                width: "37",
                sequence: 6,
            },
            {
                top: "31",
                right: "92",
                width: "36",
                sequence: 7,
            },
            {
                top: "22",
                left: "54",
                width: "35",
                sequence: 8,
            },
            {
                top: "22",
                left: "22",
                width: "40",
                sequence: 9,
            },
            {
                top: "38",
                left: "66",
                width: "50",
                sequence: 10,
            },
            {
                top: "70",
                left: "63",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    busstation: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "83",
                left: "68",
                width: "40",
                sequence: 0,
            },
            {
                top: "92",
                left: "38",
                width: "45",
                sequence: 1,
            },
            {
                top: "71",
                left: "49",
                width: "40",
                sequence: 2,
            },
            {
                top: "69",
                left: "67",
                width: "48",
                sequence: 3,
            },
            {
                top: "95",
                left: "12",
                width: "40",
                sequence: 4,
            },
            {
                top: "97",
                right: "73",
                width: "35",
                sequence: 5,
            },
            {
                top: "71",
                right: "77",
                width: "37",
                sequence: 6,
            },
            {
                top: "23",
                left: "19",
                width: "36",
                sequence: 7,
            },
            {
                top: "12",
                left: "1",
                width: "35",
                sequence: 8,
            },
            {
                top: "9",
                left: "37",
                width: "40",
                sequence: 9,
            },
            {
                top: "9",
                left: "47",
                width: "50",
                sequence: 10,
            },
            {
                top: "17",
                left: "63",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    cathedral: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "93",
                left: "45",
                width: "40",
                sequence: 0,
            },
            {
                top: "75",
                left: "63",
                width: "45",
                sequence: 1,
            },
            {
                top: "51",
                left: "60",
                width: "40",
                sequence: 2,
            },
            {
                top: "52",
                left: "22",
                width: "48",
                sequence: 3,
            },
            {
                top: "46",
                left: "18",
                width: "40",
                sequence: 4,
            },
            {
                top: "37",
                left: "37",
                width: "35",
                sequence: 5,
            },
            {
                top: "26",
                left: "33",
                width: "37",
                sequence: 6,
            },
            {
                top: "36",
                left: "11",
                width: "36",
                sequence: 7,
            },
            {
                top: "18",
                left: "3",
                width: "35",
                sequence: 8,
            },
            {
                top: "37",
                left: "71",
                width: "40",
                sequence: 9,
            },
            {
                top: "53",
                right: "70",
                width: "50",
                sequence: 10,
            },
            {
                top: "87",
                left: "56",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    club: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "84",
                left: "60",
                width: "40",
                sequence: 0,
            },
            {
                top: "83",
                left: "30",
                width: "45",
                sequence: 1,
            },
            {
                top: "69",
                left: "68",
                width: "40",
                sequence: 2,
            },
            {
                top: "69",
                left: "14",
                width: "48",
                sequence: 3,
            },
            {
                top: "58",
                left: "18",
                width: "40",
                sequence: 4,
            },
            {
                top: "37",
                left: "74",
                width: "35",
                sequence: 5,
            },
            {
                top: "21",
                left: "33",
                width: "37",
                sequence: 6,
            },
            {
                top: "31",
                right: "63",
                width: "36",
                sequence: 7,
            },
            {
                top: "14",
                left: "63",
                width: "35",
                sequence: 8,
            },
            {
                top: "45",
                left: "1",
                width: "40",
                sequence: 9,
            },
            {
                top: "86",
                right: "64",
                width: "50",
                sequence: 10,
            },
            {
                top: "15",
                left: "15",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    // theater: {
    //     customMarker: false,
    //     overlaidIcons: false,
    //     sequenceInset: true,
    // zoomable: true,

    //     positions: [
    //         {
    //            top: 73,
    //            left: 50,
    //             width: "40",
    //             sequence: 0
    //         },
    //         {
    //            top: 41,
    //            left: 60,
    //             width: "45",
    //             sequence: 1
    //         },
    //         {
    //             top: "45",
    //             left: "55",
    //             width: "40",
    //             sequence: 2
    //         },
    //         {
    //             top: "67",
    //             left: "5",
    //             width: "48",
    //             sequence: 3
    //         },
    //         {
    //             top: "73",
    //             left: "65",
    //             width: "40",
    //             sequence: 4
    //         },
    //         {
    //             top: "42",
    //             left: "10",
    //             width: "35",
    //             sequence: 5
    //         },
    //         {
    //             top: "20",
    //             left: "42",
    //             width: "37",
    //             sequence: 6
    //         },
    //         {
    //             top: "10",
    //             left: "76",
    //             width: "36",
    //             sequence: 7
    //         },
    //         {
    //             top: "28",
    //             left: "73",
    //             width: "35",
    //             sequence: 8
    //         },
    //         {
    //             top: "20",
    //             left: "19",
    //             width: "40",
    //             sequence: 9
    //         },
    //         {
    //             top: "14",
    //             left: "67",
    //             width: "50",
    //             sequence: 10
    //         },
    //         {
    //             top: "10",
    //             left: "47",
    //             width: "57",
    //             height: "100",
    //             sequence: 11
    //         }
    //     ]
    // },
    ranch: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: 76,
                left: 42,
                width: "40",
                sequence: 0,
            },
            {
                top: 91,
                left: 65,
                width: "45",
                sequence: 1,
            },
            {
                top: 79,
                right: 73,
                width: "40",
                sequence: 2,
            },
            {
                top: "72",
                left: "17",
                width: "48",
                sequence: 3,
            },
            {
                top: "62",
                left: "50",
                width: "40",
                sequence: 4,
            },
            {
                top: "40",
                left: "73",
                width: "35",
                sequence: 5,
            },
            {
                top: "53",
                left: "13",
                width: "37",
                sequence: 6,
            },
            {
                top: "33",
                right: "70",
                width: "36",
                sequence: 7,
            },
            {
                top: "10",
                left: "10",
                width: "35",
                sequence: 8,
            },
            {
                top: "22",
                left: "24",
                width: "40",
                sequence: 9,
            },
            {
                top: "11",
                left: "36",
                width: "50",
                sequence: 10,
            },
            {
                top: "19",
                left: "55",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    parking: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: 78,
                right: 65,
                width: "40",
                sequence: 0,
            },
            {
                top: "68",
                left: "7",
                width: "45",
                sequence: 1,
            },
            {
                top: "73",
                left: "23",
                width: "40",
                sequence: 2,
            },
            {
                top: "84",
                left: "67",
                width: "48",
                sequence: 3,
            },
            {
                top: "48",
                left: "34",
                width: "40",
                sequence: 4,
            },
            {
                top: "50",
                left: "78",
                width: "35",
                sequence: 5,
            },
            {
                top: "21",
                left: "73",
                width: "37",
                sequence: 6,
            },
            {
                top: "22",
                left: "52",
                width: "36",
                sequence: 7,
            },
            {
                top: "27",
                left: "7",
                width: "35",
                sequence: 8,
            },
            {
                top: "14",
                left: "28",
                width: "40",
                sequence: 9,
            },
            {
                top: "38",
                right: "70",
                width: "50",
                sequence: 10,
            },
            {
                top: "10",
                left: "53",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    military: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "91",
                left: "22",
                width: "40",
                sequence: 0,
            },
            {
                top: "76",
                left: "58",
                width: "45",
                sequence: 1,
            },
            {
                top: "80",
                left: "0",
                width: "40",
                sequence: 2,
            },
            {
                top: "69",
                left: "35",
                width: "48",
                sequence: 3,
            },
            {
                top: "57",
                left: "49",
                width: "40",
                sequence: 4,
            },
            {
                top: "46",
                left: "68",
                width: "35",
                sequence: 5,
            },
            {
                top: "26",
                left: "65",
                width: "37",
                sequence: 6,
            },
            {
                top: "34",
                left: "43",
                width: "36",
                sequence: 7,
            },
            {
                top: "42",
                left: "28",
                width: "35",
                sequence: 8,
            },
            {
                top: "59",
                right: "70",
                width: "40",
                sequence: 9,
            },
            {
                top: "23",
                right: "58",
                width: "50",
                sequence: 10,
            },
            {
                top: "10",
                left: "6",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    mansion: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "60",
                left: "30",
                width: "40",
                sequence: 0,
            },
            {
                top: "66",
                left: "4",
                width: "45",
                sequence: 1,
            },
            {
                top: "53",
                left: "0",
                width: "40",
                sequence: 2,
            },
            {
                top: "43",
                left: "16",
                width: "48",
                sequence: 3,
            },
            {
                top: "39",
                left: "48",
                width: "40",
                sequence: 4,
            },
            {
                top: "31",
                left: "79",
                width: "35",
                sequence: 5,
            },
            {
                top: "52",
                left: "76",
                width: "37",
                sequence: 6,
            },
            {
                top: "16",
                left: "60",
                width: "36",
                sequence: 7,
            },
            {
                top: "16",
                left: "46",
                width: "35",
                sequence: 8,
            },
            {
                top: "25",
                right: "0",
                width: "40",
                sequence: 9,
            },
            {
                top: "14",
                right: "0",
                width: "50",
                sequence: 10,
            },
            {
                top: "86",
                left: "60",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    lavalake: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "94",
                left: "60",
                width: "40",
                sequence: 0,
            },
            {
                top: "80",
                left: "50",
                width: "45",
                sequence: 1,
            },
            {
                top: "89",
                left: "24",
                width: "40",
                sequence: 2,
            },
            {
                top: "46",
                left: "62",
                width: "48",
                sequence: 3,
            },
            {
                top: "69",
                left: "24",
                width: "40",
                sequence: 4,
            },
            {
                top: "80",
                left: "0",
                width: "35",
                sequence: 5,
            },
            {
                top: "56",
                right: "70",
                width: "37",
                sequence: 6,
            },
            {
                top: "45",
                left: "13",
                width: "36",
                sequence: 7,
            },
            {
                top: "37",
                left: "33",
                width: "35",
                sequence: 8,
            },
            {
                top: "25",
                left: "54",
                width: "40",
                sequence: 9,
            },
            {
                top: "10",
                left: "45",
                width: "50",
                sequence: 10,
            },
            {
                top: "9",
                right: "48",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    golf: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,

        positions: [
            {
                top: "90",
                left: "66",
                width: "40",
                sequence: 0,
            },
            {
                top: "65",
                left: "63",
                width: "45",
                sequence: 1,
            },
            {
                top: "83",
                left: "43",
                width: "40",
                sequence: 2,
            },
            {
                top: "90",
                left: "13",
                width: "48",
                sequence: 3,
            },
            {
                top: "82",
                right: "61",
                width: "40",
                sequence: 4,
            },
            {
                top: "60",
                left: "0",
                width: "35",
                sequence: 5,
            },
            {
                top: "57",
                left: "43",
                width: "37",
                sequence: 6,
            },
            {
                top: "40",
                left: "34",
                width: "36",
                sequence: 7,
            },
            {
                top: "47",
                left: "6",
                width: "35",
                sequence: 8,
            },
            {
                top: "16",
                left: "35",
                width: "40",
                sequence: 9,
            },
            {
                top: "32",
                left: "62",
                width: "50",
                sequence: 10,
            },
            {
                top: "11",
                left: "50",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    factory: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "94",
                left: "72",
                width: "40",
                sequence: 0,
            },
            {
                top: "77",
                left: "65",
                width: "45",
                sequence: 1,
            },
            {
                top: "61",
                left: "33",
                width: "40",
                sequence: 2,
            },
            {
                top: "53",
                left: "67",
                width: "48",
                sequence: 3,
            },
            {
                top: "55",
                left: "0",
                width: "40",
                sequence: 4,
            },
            {
                top: "32",
                left: "27",
                width: "35",
                sequence: 5,
            },
            {
                top: "34",
                left: "50",
                width: "37",
                sequence: 6,
            },
            {
                top: "18",
                left: "70",
                width: "36",
                sequence: 7,
            },
            {
                top: "10",
                left: "36",
                width: "35",
                sequence: 8,
            },
            {
                top: "10",
                left: "5",
                width: "40",
                sequence: 9,
            },
            {
                top: "26",
                right: "63",
                width: "50",
                sequence: 10,
            },
            {
                top: "98",
                left: "6",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    cyber: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "87",
                left: "45",
                width: "40",
                sequence: 0,
            },
            {
                top: "61",
                left: "70",
                width: "45",
                sequence: 1,
            },
            {
                top: "75",
                left: "32",
                width: "40",
                sequence: 2,
            },
            {
                top: "90",
                left: "0",
                width: "48",
                sequence: 3,
            },
            {
                top: "60",
                left: "0",
                width: "40",
                sequence: 4,
            },
            {
                top: "38",
                left: "2",
                width: "35",
                sequence: 5,
            },
            {
                top: "55",
                left: "33",
                width: "37",
                sequence: 6,
            },
            {
                top: "40",
                left: "74",
                width: "36",
                sequence: 7,
            },
            {
                top: "31",
                left: "40",
                width: "35",
                sequence: 8,
            },
            {
                top: "22",
                left: "48",
                width: "40",
                sequence: 9,
            },
            {
                top: "12",
                left: "57",
                width: "50",
                sequence: 10,
            },
            {
                top: "10",
                right: "50",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    convention: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "92",
                left: "60",
                width: "40",
                sequence: 0,
            },
            {
                top: "77",
                left: "60",
                width: "45",
                sequence: 1,
            },
            {
                top: "71",
                left: "49",
                width: "40",
                sequence: 2,
            },
            {
                top: "57",
                left: "30",
                width: "48",
                sequence: 3,
            },
            {
                top: "45",
                left: "10",
                width: "40",
                sequence: 4,
            },
            {
                top: "85",
                left: "10",
                width: "35",
                sequence: 5,
            },
            {
                top: "69",
                right: "68",
                width: "37",
                sequence: 6,
            },
            {
                top: "38",
                left: "57",
                width: "36",
                sequence: 7,
            },
            {
                top: "38",
                left: "0",
                width: "35",
                sequence: 8,
            },
            {
                top: "24",
                left: "22",
                width: "40",
                sequence: 9,
            },
            {
                top: "15",
                left: "42",
                width: "50",
                sequence: 10,
            },
            {
                top: "15",
                left: "2",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    construction: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "90",
                left: "58",
                width: "40",
                sequence: 0,
            },
            {
                top: "74",
                left: "50",
                width: "45",
                sequence: 1,
            },
            {
                top: "68",
                left: "10",
                width: "40",
                sequence: 2,
            },
            {
                top: "82",
                right: "64",
                width: "48",
                sequence: 3,
            },
            {
                top: "67",
                left: "61",
                width: "40",
                sequence: 4,
            },
            {
                top: "38",
                left: "66",
                width: "35",
                sequence: 5,
            },
            {
                top: "66",
                right: "73",
                width: "37",
                sequence: 6,
            },
            {
                top: "51",
                left: "27",
                width: "36",
                sequence: 7,
            },
            {
                top: "37",
                left: "38",
                width: "35",
                sequence: 8,
            },
            {
                top: "78",
                left: "25",
                width: "40",
                sequence: 9,
            },
            {
                top: "97",
                left: "20",
                width: "50",
                sequence: 10,
            },
            {
                top: "17",
                left: "6",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    bank: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "88",
                left: "62",
                width: "40",
                sequence: 0,
            },
            {
                top: "65",
                left: "60",
                width: "45",
                sequence: 1,
            },
            {
                top: "85",
                left: "70",
                width: "40",
                sequence: 2,
            },
            {
                top: "66",
                left: "10",
                width: "48",
                sequence: 3,
            },
            {
                top: "52",
                right: "70",
                width: "40",
                sequence: 4,
            },
            {
                top: "33",
                right: "70",
                width: "35",
                sequence: 5,
            },
            {
                top: "25",
                left: "55",
                width: "37",
                sequence: 6,
            },
            {
                top: "20",
                left: "68",
                width: "36",
                sequence: 7,
            },
            {
                top: "10",
                left: "52",
                width: "35",
                sequence: 8,
            },
            {
                top: "17",
                left: "18",
                width: "40",
                sequence: 9,
            },
            {
                top: "15",
                right: "70",
                width: "50",
                sequence: 10,
            },
            {
                top: "12",
                left: "0",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    casino: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "79",
                left: "50",
                width: "40",
                sequence: 0,
            },
            {
                top: "80",
                left: "0",
                width: "45",
                sequence: 1,
            },
            {
                top: "52",
                left: "70",
                width: "40",
                sequence: 2,
            },
            {
                top: "65",
                left: "32",
                width: "48",
                sequence: 3,
            },
            {
                top: "67",
                left: "0",
                width: "40",
                sequence: 4,
            },
            {
                top: "39",
                left: "65",
                width: "35",
                sequence: 5,
            },
            {
                top: "24",
                left: "41",
                width: "37",
                sequence: 6,
            },
            {
                top: "13",
                left: "53",
                width: "36",
                sequence: 7,
            },
            {
                top: "45",
                left: "4",
                width: "35",
                sequence: 8,
            },
            {
                top: "13",
                left: "12",
                width: "40",
                sequence: 9,
            },
            {
                top: "10",
                left: "27",
                width: "50",
                sequence: 10,
            },
            {
                top: "92",
                left: "65",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    circus: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "85",
                left: "47",
                width: "40",
                sequence: 0,
            },
            {
                top: "73",
                left: "67",
                width: "45",
                sequence: 1,
            },
            {
                top: "65",
                left: "20",
                width: "40",
                sequence: 2,
            },
            {
                top: "52",
                left: "53",
                width: "48",
                sequence: 3,
            },
            {
                top: "43",
                left: "18",
                width: "40",
                sequence: 4,
            },
            {
                top: "55",
                right: "77",
                width: "35",
                sequence: 5,
            },
            {
                top: "31",
                right: "70",
                width: "37",
                sequence: 6,
            },
            {
                top: "28",
                left: "22",
                width: "36",
                sequence: 7,
            },
            {
                top: "33",
                left: "50",
                width: "35",
                sequence: 8,
            },
            {
                top: "18",
                left: "73",
                width: "40",
                sequence: 9,
            },
            {
                top: "14",
                left: "31",
                width: "50",
                sequence: 10,
            },
            {
                top: "11",
                left: "56",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    departmentstore: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "88",
                left: "65",
                width: "40",
                sequence: 0,
            },
            {
                top: "90",
                left: "2",
                width: "45",
                sequence: 1,
            },
            {
                top: "84",
                left: "30",
                width: "40",
                sequence: 2,
            },
            {
                top: "60",
                left: "25",
                width: "48",
                sequence: 3,
            },
            {
                top: "42",
                left: "70",
                width: "40",
                sequence: 4,
            },
            {
                top: "55",
                left: "0",
                width: "35",
                sequence: 5,
            },
            {
                top: "46",
                right: "74",
                width: "37",
                sequence: 6,
            },
            {
                top: "32",
                left: "7",
                width: "36",
                sequence: 7,
            },
            {
                top: "12",
                left: "22",
                width: "35",
                sequence: 8,
            },
            {
                top: "12",
                left: "43",
                width: "40",
                sequence: 9,
            },
            {
                top: "16",
                left: "60",
                width: "50",
                sequence: 10,
            },
            {
                top: "26",
                left: "62",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    cityhall: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "83",
                left: "3",
                width: "40",
                sequence: 0,
            },
            {
                top: "70%",
                left: "28%",
                width: "45",
                sequence: 1,
            },
            {
                top: "65",
                left: "49",
                width: "40",
                sequence: 2,
            },
            {
                top: "94",
                left: "25",
                width: "48",
                sequence: 3,
            },
            {
                top: "52",
                left: "70",
                width: "40",
                sequence: 4,
            },
            {
                top: "38",
                left: "39",
                width: "35",
                sequence: 5,
            },
            {
                top: "37",
                left: "17",
                width: "42",
                sequence: 6,
            },
            {
                top: "21",
                left: "55",
                width: "36",
                sequence: 7,
            },
            {
                top: "11",
                left: "78",
                width: "35",
                sequence: 8,
            },
            {
                top: "54",
                left: "7",
                width: "40",
                sequence: 9,
            },
            {
                top: "576",
                left: "32",
                width: "48",
                sequence: 10,
            },
            {
                top: "77",
                left: "-21",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    hotel: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "85",
                left: "46",
                width: "40",
                sequence: 0,
            },
            {
                top: "81",
                left: "55",
                width: "45",
                sequence: 1,
            },
            {
                top: "68",
                left: "52",
                width: "40",
                sequence: 2,
            },
            {
                top: "71",
                left: "8",
                width: "59",
                sequence: 3,
            },
            {
                top: "61",
                left: "-16",
                width: "40",
                sequence: 4,
            },
            {
                top: "45",
                left: "8",
                width: "35",
                sequence: 5,
            },
            {
                top: "34",
                left: "-12",
                width: "37",
                sequence: 6,
            },
            {
                top: "48",
                left: "78",
                width: "36",
                sequence: 7,
            },
            {
                top: "35",
                left: "70",
                width: "35",
                sequence: 8,
            },
            {
                top: "11",
                left: "71",
                width: "40",
                sequence: 9,
            },
            {
                top: "23",
                left: "38",
                width: "50",
                sequence: 10,
            },
            {
                top: "15",
                left: "15",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    hospital: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "83",
                left: "56",
                width: "40",
                sequence: 0,
            },
            {
                top: "93",
                left: "-8",
                width: "45",
                sequence: 1,
            },
            {
                top: "78",
                left: "-15",
                width: "40",
                sequence: 2,
            },
            {
                top: "64",
                left: "67",
                width: "49",
                sequence: 3,
            },
            {
                top: "49",
                left: "34",
                width: "40",
                sequence: 4,
            },
            {
                top: "39",
                left: "-7",
                width: "35",
                sequence: 5,
            },
            {
                top: "46",
                left: "77",
                width: "36",
                sequence: 6,
            },
            {
                top: "31",
                left: "71",
                width: "45",
                sequence: 7,
            },
            {
                top: "15",
                left: "-9",
                width: "35",
                sequence: 8,
            },
            {
                top: "10",
                left: "7",
                width: "40",
                sequence: 9,
            },
            {
                top: "22",
                left: "70",
                width: "50",
                sequence: 10,
            },
            {
                top: "9",
                left: "57",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    gym: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "89",
                left: "72",
                width: "40",
                sequence: 0,
            },
            {
                top: "64",
                left: "73",
                width: "45",
                sequence: 1,
            },
            {
                top: "96",
                left: "8",
                width: "40",
                sequence: 2,
            },
            {
                top: "44",
                left: "67",
                width: "48",
                sequence: 3,
            },
            {
                top: "53",
                left: "11",
                width: "40",
                sequence: 4,
            },
            {
                top: "32",
                left: "50",
                width: "35",
                sequence: 5,
            },
            {
                top: "38",
                left: "21",
                width: "37",
                sequence: 6,
            },
            {
                top: "28",
                left: "2",
                width: "36",
                sequence: 7,
            },
            {
                top: "69",
                left: "-9",
                width: "35",
                sequence: 8,
            },
            {
                top: "11",
                left: "66",
                width: "40",
                sequence: 9,
            },
            {
                top: "13",
                left: "27",
                width: "50",
                sequence: 10,
            },
            {
                top: "10",
                left: "7",
                width: "50",
                height: "100",
                sequence: 11,
            },
        ],
    },
    farm: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "10",
                left: "39",
                width: "40",
                sequence: 0,
            },
            {
                top: "17",
                left: "61",
                width: "45",
                sequence: 1,
            },
            {
                top: "27",
                left: "1",
                width: "40",
                sequence: 2,
            },
            {
                top: "53",
                left: "53",
                width: "48",
                sequence: 3,
            },
            {
                top: "56",
                left: "75",
                width: "40",
                sequence: 4,
            },
            {
                top: "33",
                left: "41",
                width: "35",
                sequence: 5,
            },
            {
                top: "90",
                left: "35",
                width: "37",
                sequence: 6,
            },
            {
                top: "35",
                left: "-14",
                width: "36",
                sequence: 7,
            },
            {
                top: "53",
                left: "2",
                width: "35",
                sequence: 8,
            },
            {
                top: "61",
                left: "11",
                width: "40",
                sequence: 9,
            },
            {
                top: "89",
                left: "55",
                width: "50",
                sequence: 10,
            },
            {
                top: "82",
                left: "-13",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    university: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "75",
                top: "85",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "58",
                top: "79",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "7",
                top: "87",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "16",
                top: "62",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "45",
                top: "64",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "70",
                top: "57",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "36",
                top: "36",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "88",
                top: "21",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "4",
                top: "34",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "60",
                top: "33",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "60",
                top: "12",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "10",
                top: "20",
                width: "9.6",
                height: "0",
            },
        ],
    },
    rome: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "85",
                top: "90",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "63",
                top: "66",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "31",
                top: "74",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "3",
                top: "73",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "36",
                top: "59",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "60",
                top: "43",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "89",
                top: "57",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "85",
                top: "29",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "2",
                top: "30",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "75",
                top: "12",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "49",
                top: "13",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "19",
                top: "13",
                width: "9.6",
                height: "0",
            },
        ],
    },
    rollarcoaster: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "77",
                top: "89",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "65",
                top: "66",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "43",
                top: "77",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "14",
                top: "80",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "15",
                top: "50",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "43",
                top: "53",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "80",
                top: "51",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "88",
                top: "32",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "68",
                top: "48",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "65",
                top: "12",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "49",
                top: "21",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "19",
                top: "27",
                width: "9.6",
                height: "0",
            },
        ],
    },
    prison: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "81",
                top: "89",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "71",
                top: "81",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "89",
                top: "59",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "2",
                top: "82",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "30",
                top: "73",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "62",
                top: "57",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "66",
                top: "28",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "31",
                top: "46",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "12",
                top: "58",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "78",
                top: "49",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "4",
                top: "40",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "7",
                top: "22",
                width: "9.6",
                height: "0",
            },
        ],
    },
    norse_village: {
        constructWrapper: false,
        overlaidIcons: false,
        sequenceInset: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "81",
                top: "89",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "59",
                top: "78",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "89",
                top: "59",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "43",
                top: "96",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "7",
                top: "68",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "4",
                top: "92",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "41",
                top: "64",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "86",
                top: "36",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "55",
                top: "37",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "61",
                top: "15",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "12",
                top: "36",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "38",
                top: "20",
                width: "9.6",
                height: "0",
            },
        ],
    },
    musem: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "15",
                top: "85",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "40",
                top: "68",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "4",
                top: "59",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "40",
                top: "47",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "87",
                top: "55",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "74",
                top: "35",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "22",
                top: "21",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "5",
                top: "27",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "6",
                top: "12",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "61",
                top: "15",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "85",
                top: "25",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "73",
                top: "96",
                width: "9.6",
                height: "0",
            },
        ],
    },
    laboratory: {
        constructWrapper: false,
        overlaidIcons: false,
        sequenceInset: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "83",
                top: "92",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "85",
                top: "76",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "57",
                top: "72",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "22",
                top: "89",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "86",
                top: "60",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "55",
                top: "48",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "20",
                top: "61",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "86",
                top: "27",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "25",
                top: "21",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "61",
                top: "15",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "3",
                top: "43",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "5",
                top: "72",
                width: "9.6",
                height: "0",
            },
        ],
    },
    forbiddencity: {
        constructWrapper: false,
        overlaidIcons: false,
        sequenceInset: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "78",
                top: "78",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "64",
                top: "60",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "33",
                top: "39",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "4",
                top: "57",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "7",
                top: "27",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "14",
                top: "14",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "84",
                top: "36",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "68",
                top: "17",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "53",
                top: "13",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "89",
                top: "14",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "45",
                top: "74",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "13",
                top: "81",
                width: "9.6",
                height: "0",
            },
        ],
    },
    mars: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "81",
                left: "10",
                width: "40",
                sequence: 0,
            },
            {
                top: "81",
                left: "59",
                width: "45",
                sequence: 1,
            },
            {
                top: "65",
                left: "72",
                width: "40",
                sequence: 2,
            },
            {
                top: "63",
                left: "48",
                width: "48",
                sequence: 3,
            },
            {
                top: "56",
                left: "32",
                width: "40",
                sequence: 4,
            },
            {
                top: "50",
                left: "68",
                width: "35",
                sequence: 5,
            },
            {
                top: "40",
                left: "58",
                width: "37",
                sequence: 6,
            },
            {
                top: "29",
                left: "65",
                width: "36",
                sequence: 7,
            },
            {
                top: "41",
                left: "38",
                width: "35",
                sequence: 8,
            },
            {
                top: "45",
                left: "-13",
                width: "40",
                sequence: 9,
            },
            {
                top: "26",
                left: "-9",
                width: "50",
                sequence: 10,
            },
            {
                top: "12",
                left: "-11",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    career_growth: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "97",
                left: "40",
                width: "40",
                sequence: 0,
            },
            {
                top: "84",
                left: "21",
                width: "40",
                sequence: 1,
            },
            {
                top: "94",
                left: "0",
                width: "40",
                sequence: 2,
            },
            {
                top: "72",
                left: "2",
                width: "40",
                sequence: 3,
            },
            {
                top: "71",
                left: "50",
                width: "40",
                sequence: 4,
            },
            {
                top: "67",
                left: "73",
                width: "40",
                sequence: 5,
            },
            {
                top: "60",
                left: "17",
                width: "40",
                sequence: 6,
            },
            {
                top: "47",
                left: "6",
                width: "40",
                sequence: 7,
            },
            {
                top: "47",
                left: "50",
                width: "40",
                sequence: 8,
            },
            {
                top: "28",
                left: "64",
                width: "40",
                sequence: 9,
            },
            {
                top: "21",
                left: "6",
                width: "40",
                sequence: 10,
            },
            {
                top: "21",
                left: "25",
                width: "40",
                height: "100",
                sequence: 11,
            },
        ],
    },
    road_to_success: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "93",
                left: "25",
                width: "40",
                sequence: 0,
            },
            {
                top: "90",
                left: "48",
                width: "40",
                sequence: 1,
            },
            {
                top: "72",
                left: "63",
                width: "40",
                sequence: 2,
            },
            {
                top: "60",
                left: "38",
                width: "40",
                sequence: 3,
            },
            {
                top: "76",
                left: "3",
                width: "40",
                sequence: 4,
            },
            {
                top: "60",
                left: "-2",
                width: "40",
                sequence: 5,
            },
            {
                top: "44",
                left: "46",
                width: "40",
                sequence: 6,
            },
            {
                top: "50",
                left: "66",
                width: "40",
                sequence: 7,
            },
            {
                top: "33",
                left: "36",
                width: "40",
                sequence: 8,
            },
            {
                top: "38",
                left: "6",
                width: "40",
                sequence: 9,
            },
            {
                top: "21",
                left: "6",
                width: "40",
                sequence: 10,
            },
            {
                top: "12",
                left: "37",
                width: "40",
                height: "100",
                sequence: 11,
            },
        ],
    },
    path: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        positions: [
            {
                top: "93",
                left: "36",
                width: "40",
                sequence: 0,
            },
            {
                top: "83",
                left: "16",
                width: "40",
                sequence: 1,
            },
            {
                top: "67",
                left: "-9",
                width: "40",
                sequence: 2,
            },
            {
                top: "53",
                left: "-1",
                width: "40",
                sequence: 3,
            },
            {
                top: "53",
                left: "29",
                width: "40",
                sequence: 4,
            },
            {
                top: "69",
                left: "71",
                width: "40",
                sequence: 5,
            },
            {
                top: "69",
                left: "44",
                width: "40",
                sequence: 6,
            },
            {
                top: "33",
                left: "50",
                width: "40",
                sequence: 7,
            },
            {
                top: "17",
                left: "0",
                width: "40",
                sequence: 8,
            },
            {
                top: "39",
                left: "9",
                width: "40",
                sequence: 9,
            },
            {
                top: "43",
                left: "71",
                width: "40",
                sequence: 10,
            },
            {
                top: "10",
                left: "71",
                width: "40",
                height: "100",
                sequence: 11,
            },
        ],
    },
    supermarketnew: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "83",
                left: "3",
                width: "40",
                sequence: 0,
            },
            {
                top: "67",
                left: "4",
                width: "45",
                sequence: 1,
            },
            {
                top: "55",
                left: "11",
                width: "40",
                sequence: 2,
            },
            {
                top: "74",
                left: "36",
                width: "48",
                sequence: 3,
            },
            {
                top: "52",
                left: "46",
                width: "40",
                sequence: 4,
            },
            {
                top: "43",
                left: "16",
                width: "35",
                sequence: 5,
            },
            {
                top: "40",
                left: "-11",
                width: "37",
                sequence: 6,
            },
            {
                top: "28",
                left: "68",
                width: "36",
                sequence: 7,
            },
            {
                top: "19",
                left: "40",
                width: "35",
                sequence: 8,
            },
            {
                top: "17",
                left: "17",
                width: "40",
                sequence: 9,
            },
            {
                top: "10",
                left: "1",
                width: "50",
                sequence: 10,
            },
            {
                top: "8",
                left: "45",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    warehousenew: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "84",
                left: "47",
                width: "40",
                sequence: 0,
            },
            {
                top: "69",
                left: "54",
                width: "45",
                sequence: 1,
            },
            {
                top: "78",
                left: "1",
                width: "40",
                sequence: 2,
            },
            {
                top: "53",
                left: "10",
                width: "48",
                sequence: 3,
            },
            {
                top: "40",
                left: "46",
                width: "40",
                sequence: 4,
            },
            {
                top: "35",
                left: "26",
                width: "35",
                sequence: 5,
            },
            {
                top: "34",
                left: "3",
                width: "37",
                sequence: 6,
            },
            {
                top: "23",
                left: "22",
                width: "36",
                sequence: 7,
            },
            {
                top: "22",
                left: "69",
                width: "35",
                sequence: 8,
            },
            {
                top: "9",
                left: "54",
                width: "40",
                sequence: 9,
            },
            {
                top: "9",
                left: "8",
                width: "50",
                sequence: 10,
            },
            {
                top: "8",
                left: "-15",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    mobile: {
        constructWrapper: false,
        overlaidIcons: false,
        sequenceInset: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "31",
                top: "86",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "12",
                top: "51",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "36",
                top: "63",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "60",
                top: "82",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "85",
                top: "53",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "72",
                top: "52",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "50",
                top: "45",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "6",
                top: "27",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "53",
                top: "33",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "85",
                top: "27",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "85",
                top: "12",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "31",
                top: "8",
                width: "9.6",
                height: "0",
            },
        ],
    },
    stadium: {
        constructWrapper: false,
        customMarker: false,
        overlaidIcons: false,
        sequenceInset: true,
        zoomable: true,
        gifMap: [],
        positions: [
            {
                top: "90",
                left: "65",
                width: "40",
                sequence: 0,
            },
            {
                top: "88",
                left: "-7",
                width: "45",
                sequence: 1,
            },
            {
                top: "65",
                left: "63",
                width: "40",
                sequence: 2,
            },
            {
                top: "65",
                left: "10",
                width: "48",
                sequence: 3,
            },
            {
                top: "62",
                left: "-13",
                width: "40",
                sequence: 4,
            },
            {
                top: "45",
                left: "45",
                width: "35",
                sequence: 5,
            },
            {
                top: "44",
                left: "18",
                width: "37",
                sequence: 6,
            },
            {
                top: "39",
                left: "-08",
                width: "36",
                sequence: 7,
            },
            {
                top: "17",
                left: "-10",
                width: "35",
                sequence: 8,
            },
            {
                top: "20",
                left: "9",
                width: "40",
                sequence: 9,
            },
            {
                top: "15",
                left: "31",
                width: "50",
                sequence: 10,
            },
            {
                top: "29",
                left: "57",
                width: "57",
                height: "100",
                sequence: 11,
            },
        ],
    },
    channels: {
        constructWrapper: false,
        overlaidIcons: false,
        sequenceInset: true,
        gifMap: [],
        positions: [
            {
                sequence: 0,
                left: "21",
                top: "91",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 1,
                left: "56",
                top: "82",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 2,
                left: "43",
                top: "66",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 3,
                left: "15",
                top: "60",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 4,
                left: "1",
                top: "40",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 5,
                left: "84",
                top: "52",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 6,
                left: "63",
                top: "41",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 7,
                left: "31",
                top: "34",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 8,
                left: "5",
                top: "26",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 9,
                left: "8",
                top: "9",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 10,
                left: "85",
                top: "10",
                width: "9.6",
                height: "0",
            },
            {
                sequence: 11,
                left: "65",
                top: "8",
                width: "9.6",
                height: "0",
            },
        ],
    },
};

export const PickedLocations = {
    1: [0],
    2: [0, 11],
    3: [0, 5, 11],
    4: [0, 4, 8, 11],
    5: [0, 3, 6, 9, 11],
    6: [0, 2, 4, 6, 9, 11],
    7: [0, 2, 4, 6, 8, 10, 11],
    8: [0, 1, 2, 4, 6, 8, 10, 11],
    9: [0, 1, 2, 5, 6, 7, 9, 10, 11],
    10: [0, 1, 2, 4, 5, 6, 7, 9, 10, 11],
    11: [0, 1, 2, 3, 4, 6, 7, 8, 9, 10, 11],
    12: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
};
