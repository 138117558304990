import _ from "lodash";
import styled, { css } from "styled-components";
import { Button } from "semantic-ui-react";
import { motion } from "framer-motion";
import { settings } from "../settings/app.settings";

export const styleConfig = {
    colors: {
        midnight: "#121212",
        charcoal: "#454545",
        half: "#666666",
        grey: "#AAAAAA",
        peat: "#BDBDBD",
        ash: "#DDDDDD",
        smoke: "#E8E8E8",
        vapor: "#EFEFEF",
        ivory: "#F5F5F5",
        white: "#FFFFFF",
        main: settings.colors.main,
        primary: settings.colors.primary,
        secondary: "#ed6e6e",
        danger: "#ed6e6e",
        success: "#C1DC9E",
        failure: "#7D3F3F",
        disabled: "#dddddd",
        overlaid: "#121212dd",
    },
    txtColors: {
        midnight: "#ffc900",
        charcoal: "#ffffff",
        half: "#ffffff",
        grey: "#000000",
        peat: "#000000",
        ash: "#000000",
        smoke: "#000000",
        vapor: "#000000",
        ivory: "#000000",
        white: "#000000",
        primary: settings.colors.primaryText,
        secondary: "#ffffff",
        danger: "#ffffff",
        failure: "#ffffff",
        success: "#000000",
        disabled: "#cccccc",
        overlaid: "#ed6e6e",
    },
    font: {
        primary: "Roboto",
        secondary: "Oswald",
    },
};

const clearfixStyles = css`
    &:after {
        content: "";
        display: table;
        clear: both;
    }
`;
export const StyledDiv = styled(motion.div)`
    &&& {
        position: ${(props) =>
            (props.absolute && "absolute") ||
            (props.relative && "relative") ||
            (props.fixed && "fixed")};
        width: ${(props) =>
            props.wd ||
            (props["fit-content"] && "fit-content") ||
            (props.fluid && "100%")};
        height: ${(props) =>
            props.ht || (props.fullht && "100%") || (props.pageht && "100vh")};
        max-width: ${(props) => props.maxWd};
        max-height: ${(props) => props.maxHt};
        min-width: ${(props) => props.minWd};
        min-height: ${(props) => props.minHt};
        display: ${(props) =>
            (props.flex && "flex") ||
            (props.inline && "inline") ||
            (props.inlineBlock && "inline-block")};
        flex-wrap: ${(props) => props.flex && "wrap"};
        flex-direction: ${(props) => props.column && "column"};
        align-content: ${(props) => props.flex && "center"};
        align-items: ${(props) =>
            props.flex &&
            (props["top-aligned"] || props["top-align"]
                ? "flex-start"
                : props["bottom-aligned"] || props["bottom-align"]
                ? "flex-end"
                : "center")};
        justify-content: ${(props) =>
            props.flex &&
            (props["left-aligned"] || props["left-align"]
                ? "flex-start"
                : props["right-aligned"] || props["right-align"]
                ? "flex-end"
                : "center")};
        background-color: ${(props) => {
            for (const color in styleConfig.colors) {
                if (props[color]) return styleConfig.colors[color];
            }
            return props.bgColor || props.color;
        }};
        color: ${(props) => {
            for (const color in styleConfig.txtColors) {
                if (props[color]) return styleConfig.txtColors[color];
                if (props[`txt${_.capitalize(color)}`])
                    return styleConfig.colors[color];
            }
            return props.txtColor;
        }};

        text-align: ${(props) => {
            if (props["center-aligned"] || props["center-align"])
                return "center";
            else if (props["right-aligned"] || props["right-align"])
                return "right";
            else if (props["left-aligned"] || props["left-align"])
                return "left";
        }};
        float: ${(props) =>
            (props.float && props.float) ||
            (props["float-left"] && "left") ||
            (props["float-right"] && "right")};
        clear: ${(props) => props.clear && "both"};
        text-transform: ${(props) => props.uppercase && "uppercase"};
        padding: ${(props) => {
            if (props.fitted) return "2px 4px";
            else if (props.nopad) return "0";
            else if (props.rimmed) return "5px";
            else if (props.padSides) return "0 15px";
            else if (props.padLeft) return "0 0 0 15px";
            else if (props.padRight) return "0 15px 0 0";
            else if (props.compact) return "4px 8px";
            else if (props.padded || props.normal) return "6px 12px";
            else if (props.relaxed) return "15px 30px";
            else if (props.medpad) return "10px";
            else if (props.basepad) return "15px";
            else if (props.superpad) return "30px";
            else if (props.megapad) return "60px";
        }};
        padding-top: ${(props) =>
            (props.padTop && "15px") || (props.gapTop && "7.5px")};
        padding-bottom: ${(props) =>
            (props.padBottom && "15px") || (props.gapBottom && "7.5px")};
        margin: ${(props) =>
            (props.basemargin && "15px") ||
            (props.spaced && "7.5px") ||
            (props.snug && "2.5px") ||
            (props.normal && "0") ||
            (props.centered && "0 auto")};
        margin-bottom: ${(props) =>
            (props.gutter && "15px") || (props.trench && "30px")};
        margin-right: ${(props) =>
            (props.spaceRight && "15px") || (props.nudgeRight && "5px")};
        margin-left: ${(props) =>
            (props.spaceLeft && "15px") || (props.nudgeLeft && "5px")};
        font-family: ${(props) =>
            (props.altText && `"${styleConfig.font.secondary}", sans-serif`) ||
            (props.thinText &&
                `"${styleConfig.font.primary} Light", sans-serif`) ||
            (props.priText && `"${styleConfig.font.primary}", sans-serif`)};
        font-size: ${(props) =>
            (props.giant && "4em") ||
            (props.headline && "2.5em") ||
            (props.massive && "2em") ||
            (props.huge && "1.75em") ||
            (props.large && "1.5em") ||
            (props.big && "1.25em") ||
            (props.normal && "1em") ||
            (props.small && "0.85em") ||
            (props.tiny && "0.7em") ||
            (props.micro && "0.6em")};
        font-weight: ${(props) =>
            (props.bold && "bold") || (props.light && "normal")};
        font-style: ${(props) => props.italics && "italic"};
        border-radius: ${(props) =>
            (props.rounded && "10px") ||
            (props.circled && "50px") ||
            (props.pureCircle && "50%") ||
            (props.snubbed && "5px") ||
            (props.topRounded && "10px 10px 0 0") ||
            (props.rightRounded && "0 10px 10px 0") ||
            (props.bottomRounded && "0 0 10px 10px") ||
            (props.leftRounded && "10px 0 0 10px")};
        line-height: ${(props) => (props.lineht && props.lineht) || 1.2};
        z-index: ${(props) => props.layer};
        overflow: ${(props) =>
            (props.noOverflow && "hidden") ||
            (props.autoOverflow && "auto") ||
            (props.autoOverflowY && "hidden auto") ||
            (props.scrollOverflow && "scroll") ||
            (props.showOverflow && "visible")};
        pointer-events: ${(props) => props.noEvent && "none"};
        cursor: ${(props) => props.clickable && "pointer"};
        opacity: ${(props) => props.opacity || (props.hoverFade && 0.95)};
        box-shadow: ${(props) =>
            (props.dropShadow && "1px 1px 10px 1px #000000") ||
            (props.slightShadow && "1px 1px 5px 1px #999999") ||
            (props.hintShadow && "1px 1px 1px 1px #cccccc") ||
            (props.inset && "0 0 5px 1px #cccccc inset")};
        vertical-align: ${(props) => props.middle && "middle"};
        ${(props) => props.clearfix && clearfixStyles}
        &:hover {
            opacity: ${(props) => props.hoverFade && 1};
        }
    }
`;

export const StyledButton = styled(Button)`
    &&& {
        position: relative;
        padding: ${(props) =>
            props.grouped ? "8px 15px" : "12px 24px"}!important;
        display: ${(props) => props.grouped && "inline-block"};
        overflow: hidden;
        border-radius: ${(props) => props.roundedbuttons && "5px"};
        box-shadow: ${(props) =>
            props.invertedbuttons &&
            "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)"}important!;
        background-color: ${(props) =>
            (props.background && props.background) ||
            (props.primary && styleConfig.colors.primary) ||
            (props.secondary && styleConfig.colors.secondary) ||
            (props.success && styleConfig.colors.success) ||
            (props.disabled && styleConfig.colors.disabled) ||
            (props.midnight && styleConfig.colors.midnight) ||
            (props.danger && styleConfig.colors.danger)}!important;
        color: ${(props) =>
            (props.txtColor && props.txtColor) ||
            (props.primary && styleConfig.txtColors.primary) ||
            (props.secondary && styleConfig.txtColors.secondary) ||
            (props.success && styleConfig.txtColors.success) ||
            (props.disabled && styleConfig.txtColors.disabled) ||
            (props.midnight && styleConfig.txtColors.midnight) ||
            (props.danger && styleConfig.txtColors.danger)}!important;
        margin: ${(props) =>
            (props.spaced && "7.5px 0") ||
            (props.snug && "2.5px 0") ||
            (props.normal && "0") ||
            (props.centered && "0 auto")};
        font-size: ${(props) =>
            (props.giant && "4em") ||
            (props.headline && "2.5em") ||
            (props.massive && "2em") ||
            (props.huge && "1.75em") ||
            (props.large && "1.5em") ||
            (props.big && "1.25em") ||
            (props.normal && "1em") ||
            (props.small && "0.85em") ||
            (props.tiny && "0.7em") ||
            (props.micro && "0.6em")};
        transition: background-color 0.3s;
        &:hover,
        &:focus {
            background-color: ${(props) =>
                (props.background && modColor(props.background, -30)) ||
                (props.primary && modColor(styleConfig.colors.primary, -30)) ||
                (props.secondary &&
                    modColor(styleConfig.colors.secondary, -30)) ||
                (props.success && modColor(styleConfig.colors.success, -30)) ||
                (props.disabled &&
                    modColor(styleConfig.colors.disabled, -30)) ||
                (props.danger && modColor(styleConfig.colors.danger),
                -30)}!important;
            color: ${(props) =>
                (props.txtColor && modColor(props.txtColor, 100)) ||
                (props.primary &&
                    modColor(styleConfig.txtColors.primary, 100)) ||
                (props.secondary &&
                    modColor(styleConfig.txtColors.secondary, 100)) ||
                (props.success &&
                    modColor(styleConfig.txtColors.success, 100)) ||
                (props.disabled &&
                    modColor(styleConfig.txtColors.disabled, 100)) ||
                (props.danger &&
                    modColor(styleConfig.txtColors.danger, 100))}!important;
            box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
                0 1px 10px 0 rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.2);
        }
        & > * {
            position: relative;
        }
        &:before {
            content: "";

            position: absolute;
            top: 50%;
            left: 50%;

            display: block;
            width: 0;
            padding-top: 0;

            border-radius: 100%;

            background-color: rgba(236, 240, 241, 0.3);

            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
        &:active:before {
            width: 120%;
            padding-top: 120%;

            transition: width 0.2s ease-out, padding-top 0.2s ease-out;
        }
    }
`;

const modColor = (color, percent) => {
    var num = parseInt(color.replace("#", ""), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = ((num >> 8) & 0x00ff) + amt,
        G = (num & 0x0000ff) + amt;

    return (
        "#" +
        (
            0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
            (G < 255 ? (G < 1 ? 0 : G) : 255)
        )
            .toString(16)
            .slice(1)
    );
};
